<template>
  <div>
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <!-- <WpTopRight class="top-right"></WpTopRight> -->
      <WpMenuMain
        ref="wpMenuMain"
        class="top-left"
        :obj="obj"
        @settingClick="settingClick"
        @switchPageDrawing="switchPageDrawing"
        @switchPageReview="switchPageReview"
        @switchPagePrint="switchPagePrint"
      ></WpMenuMain>

      <WpBoard
        :obj="obj"
        :drawingMeta="drawingMeta"
        ref="wpBoardRef"
        v-show="isShowBoard"
      ></WpBoard>
      <WpReview :obj="obj" :roomData="roomData" ref="wpReviewRef" v-show="isShowReview"></WpReview>
      <WpPrint :obj="obj" ref="wpReviewRef" v-show="isShowPrint"></WpPrint>
    </div>
    <div>
      <CModal title="Work Setting" :show.sync="workSettingPopup" size="xl">
        <CForm>
          <div class="required-container">
            <CLabel class="required-label">Name</CLabel>
            <CInput class="required-input" v-model="obj.name" />
          </div>
          <div class="required-container">
            <CLabel class="required-label">Description</CLabel>
            <CTextarea
              class="required-input"
              rows="3"
              text="Must be 8-20 words long."
              v-model="obj.description"
            ></CTextarea>
          </div>
          <div class="required-container">
            <CLabel class="required-label">Project Number</CLabel>
            <CInput class="required-input" v-model="obj.projectNumber"/>
          </div>
          <div class="required-container">
            <CLabel class="required-label">Revision</CLabel>
            <CInput class="required-input" v-model="obj.revision"/>
          </div>
          <CInput label="Date" horizontal readonly v-model="projectDate"/>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">OLF - Use Existing?</CCol>
            <CCol sm="9">
              <CSwitch
                class="mr-1"
                color="primary"
                :checked.sync="obj.isExisting"
                label-on="YES"
                label-off="NO"
              />
            </CCol>
          </CRow>
          <CRow form class="form-group" v-if="showIsDiscount">
            <CCol tag="label" sm="3" class="col-form-label"> Exit Discounting </CCol>
            <CCol sm="9">
              <CSwitch
                class="mr-1"
                color="primary"
                :checked.sync="obj.isDiscount"
                label-on="YES"
                label-off="NO"
              />
            </CCol>
          </CRow>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label"> High Rise? </CCol>
            <CCol sm="9">
              <CSwitch
                class="mr-1"
                color="primary"
                :checked.sync="obj.isHighRise"
                label-on="YES"
                label-off="NO"
              />
            </CCol>
          </CRow>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              Sprinklered?
            </CCol>
            <CCol sm="9">
              <CSwitch
                class="mr-1"
                color="primary"
                :checked.sync="obj.isSprinkleredBuilding"
                label-on="YES"
                label-off="NO"
                :disabled="!sprinkleredCondition"
              />
            </CCol>
          </CRow>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">Gross Leasable Area (GLA)</CCol>
            <CCol sm="9">
              <CSwitch
                class="mr-1"
                color="primary"
                :checked.sync="obj.isGLA"
                label-on="YES"
                label-off="NO"
                :disabled="obj.isGLA"
              />
            </CCol>
          </CRow>
          <CSelect
            label="Select Classification Type"
            horizontal
            :options="classificationTypeOptions"
            :value.sync="obj.classificationType"
          ></CSelect>
          <CSelect
            label="Building Code"
            horizontal
            :options="buildingCodeList"
            :value.sync="buildingCodeId"
            required
          >
          </CSelect>
          <CSelect
            label="OLF Main Category"
            horizontal
            :options="olfMainCategoryList"
            :value.sync="olfMainCategoryId"
            :disabled="!buildingCodeId"
          >
          </CSelect>
          <CInput
          label="Minimum Number Exit"
          placeholder="Please select building code"
          horizontal
          :value.sync="minimumNumberOfExitObj.label"
          disabled
          />
          <CInput
            label="Egress Parameter"
            placeholder="Please select building code"
            horizontal
            :value.sync="paramEgressObj.label"
            disabled
          >
          </CInput>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label"> Image </CCol>
            <CCol sm="9">
              <CImg
                :src="floorPlanUrl"
                class="mb-2"
                width="100%"
                style="max-width: 500px;"
                v-if="floorPlanUrl"
              />
            </CCol>
          </CRow>
        </CForm>
        <CModal
          title="Change Building Code Confirmation"
          :show.sync="changeBuildingCodeConfirmationModal"
          :closeOnBackdrop="false"
          :showClose="false"
          class="analysis-modal"
          centered    
        >
          <p>
            Changing the building code may affect the compliance assessment for the assigned rooms.
            Are you sure you want to proceed?
          </p>
          <div slot="footer" class="w-100">
            <CButton
              class="ml-1 mr-1 float-right"
              color="primary"
              @click="onSubmit"
            >
              OK
            </CButton>
            <CButton
              class="ml-1 mr-1 float-right"
              color="secondary"
              @click="() => changeBuildingCodeConfirmationModal = false"
            >
              Cancel
            </CButton>
          </div>
        </CModal>

        <div slot="footer" class="w-100">
          <CButton
            class="ml-1 mr-1 float-right"
            color="primary"
            @click="onFloorPlanConfirmation"
          >
            Save
          </CButton>
          <CButton
            class="ml-1 mr-1 float-right"
            color="danger"
            @click="showDeleteConfirmation"
          >
            Delete
          </CButton>
          <CButton
            class="ml-1 mr-1 float-right"
            color="secondary"
            @click="workSettingPopup = false"
          >
            Cancel
          </CButton>
        </div>
      </CModal>
      <CModal
        title="Confirm Delete"
        color="warning"
        :show.sync="warningModal"
        @update:show="onDeleteConfirmation"
      >
        Are you sure you want to delete this {{ itemToDelete.code }} ?
      </CModal>
    </div>    
    <CModal
      title="Running Analysis"
      :show.sync="analysisModal"
      size="lg"
      :closeOnBackdrop="false"
      :showClose="false"
      class="analysis-modal"
      centered    
    >
      <CProgress 
        :value="progress" 
        show-percentage 
        animated>
      </CProgress>
      <div slot="footer" class="w-100">
        <CButton
            v-if="progress >= 100"
            class="ml-1 mr-1 float-right"
            color="primary"
            @click="onCloseAnalysisModal"
          >
            OK
          </CButton>
          <div v-if="progress >= 100" class="mt-2 text-center text-success">
            Analysis completed successfully!
          </div>
      </div>
    </CModal>
  </div>
</template>

<script>
import WpBoard from "@/components/WpBoard.vue";
import WpReview from "@/components/WpReview.vue";
import WpPrint from "@/components/WpPrint.vue";
import WpMenuMain from "@/components/WpMenuMain.vue";
import FloorPlanApi from "@/lib/floorPlanApi";
import ObstacleApi from "@/lib/obstacleApi";
import TravelDistanceApi from "@/lib/travelDistanceApi";
import BuildingCodeApi from "@/lib/buildingCodeApi";
import OlfMainCategoryApi from "@/lib/olfMainCategoryApi";
import MinimumNumberOfExitApi from "@/lib/minimumNumberOfExitApi";
import roomApi from "@/lib/roomApi";
import ParamEgressApi from "@/lib/paramEgressApi";
import WidgetsUploadImage from "./widgets/WidgetsUploadImage";
import LeftSwingDoorImg from "./../assets/images/new-door/swing_left_door.png";
import RightSwingDoorImg from "./../assets/images/new-door/swing_right_door.png";
import FoldingDoorImg from "./../assets/images/new-door/new_folding_door.png";
import OpenPathDoorImg from "./../assets/images/doors/open_path.png";
import SlidingDoorImg from "./../assets/images/new-door/new_sliding_door.png";
import DoubleDoorImg from "./../assets/images/new-door/new_double_door.png";
import OneHalfDoorImg from "./../assets/images/new-door/one-half-door.png";
import FireRatingApi from "@/lib/fireRatingApi";
import moment from 'moment';

// Fire Rated Door Img
import Fr30Door from "./../assets/images/fire-rated-door/fd_30.png"
import Fr60Door from "./../assets/images/fire-rated-door/fd_60.png"
import Fr90Door from "./../assets/images/fire-rated-door/fd_90.png"
import Fr120Door from "./../assets/images/fire-rated-door/fd_120.png"
import Fr180Door from "./../assets/images/fire-rated-door/fd_180.png"
import Fr240Door from "./../assets/images/fire-rated-door/fd_240.png"

export default {
  name: "Workspace",
  components: {
    WpMenuMain,
    WpBoard,
    WpReview,
    WpPrint,
    WidgetsUploadImage,
  },
  data() {
    return {
      progress: 0,
      analysisModal: false,
      changeBuildingCodeConfirmationModal: false,
      swingDoor: null,
      leftSwingDoor: null,
      rightSwingDoor: null,
      foldingDoor: null,
      openPathDoor: null,
      slidingDoor: null,
      doubleDoor: null,
      oneHalfDoor: null,
      // Fire rating door
      fireRatingDoor: null,

      fr30Door: null,
      fr60Door: null,
      fr90Door: null,
      fr120Door: null,
      fr180Door: null,
      fr240Door: null,
      projectDate: null,
      fireRatings: null,
      warningModal: false,
      itemToDelete: {},
      fireRatingApi: new FireRatingApi(),
      currentPage: "board",
      showIsDiscount: false,
      infoList: [],
      obj: {
        id: null,
      },
      workSettingPopup: false,
      olfMainCategoryList: [
        {
          label: 'Please select building code',
          value: null
        }
      ],
      olfMainCategoryId: "",
      buildingCodeList: [],
      buildingCodeId: "",

      minimumNumberOfExitList: [
        {
          label: 'Please select building code',
          value: null
        }
      ],
      minimumNumberOfExitObj: {
        label: '',
        value: null,
      },
      minimumNumberOfExitId: null,

      paramEgressList: [
        {
          label: 'Please select building code',
          value: null
        }
      ],
      paramEgressObj: {
        label: '',
        value: null,
      },
      paramEgressId: null,
      classificationTypeOptions: [
        {
          label: 'Please select classfication type',
          value: null
        },
        {
          label: 'Individual',
          value: 'Main'
        },
        {
          label: 'Main/Dominant',
          value: 'Dominant'
        },
        {
          label: 'Stringent',
          value: 'Stringent'
        }
      ],

      uploadedFiles: [],
      olfMainCategoryApi: new OlfMainCategoryApi(),

      api: new FloorPlanApi(),
      obstacleApi: new ObstacleApi(),
      buildingCodeApi: new BuildingCodeApi(),
      travelDistanceApi: new TravelDistanceApi(),
      roomApi: new roomApi(),
      roomData: null,
      minimumNumberOfExitApi: new MinimumNumberOfExitApi(),
      paramEgressApi: new ParamEgressApi(),
      drawingMeta: {
        tool: "",
        shape: "",
        size: 10,
        width: 1000,
        depth: 200,
        strokeWidth: 1,
        strokeColor: "#563d7c",
        fillColor: "#563d7c",
        fillOpacity: 50,
        autoMethod: "",
        analysisColorCode: "",
        analysisMethod: "",
        analysisCategory: "",
        gridSize: 10,
        showGrid: false,
      },
      sprinkleredCondition: false,
    };
  },
  mounted() {
    this.resetObj();
    this.getFireRatings();
  },
  computed: {
    isShowBoard() {
      if (this.currentPage == "board") return true;
      return false;
    },
    isShowReview() {
      if (this.currentPage == "review") return true;
      return false;
    },
    isShowPrint() {
      if (this.currentPage == "print") return true;
      return false;
    },

    floorPlanUrl() {
      var self = this;
      return self.obj.documentUrl
    },
  },
  watch: {
    floorPlanUrl(newVal, oldVal) {
      var self = this;
      self.buildingCodeApi
        .getList()
        .then((response) => {
          self.buildingCodes = response.results;
          self.buildingCodeList = [{
            label: 'Please select building code',
            value: null
          }];
          for (let i = 0; i < response.results.length; i++) {
            self.buildingCodeList.push({
              value: response.results[i].id,
              label: response.results[i].name,
            });
          }
        })
        .catch(({data}) => {
          self.toast("Error", data, "danger");
        });
    },
    buildingCodeId(buildingCode) {
      var self = this;
      buildingCode = this.buildingCodeId

      if (buildingCode) {
        self.olfMainCategoryApi
      .getOlfByBuildingCodeId(buildingCode)
      .then((response) => {
        self.olfMainCategoryList = []
        if (response.occupantLoadFactors.length === 0) {
          self.olfMainCategoryId = null
          self.olfMainCategoryList = [
            { value: null, label: 'OLF Main Category is not available' }
          ]
        } else {
          self.olfMainCategoryList = [
            { value: null, label: 'Please select OLF Main Category' },
            ...response.occupantLoadFactors.map((classification) => ({
              value: classification.olfMainCategoryId,
              label: classification.olfMainCategory.name,
            })),
          ];
          self.olfMainCategoryList = this.removeDuplicates(self.olfMainCategoryList);
        }
      })
      .catch(({data}) => {
        self.toast("Error", data.message, "danger");
        console.log(data.message)
      });

      self.minimumNumberOfExitApi
        .getMinimumNumberOfExitByBuildingCode(buildingCode)
        .then((response) => {
          var mnes = response.minimumNumberOfExit;
          if (mnes.length === 0) {
            self.minimumNumberOfExitObj.label = 'N/A'
            self.minimumNumberOfExitObj.value = null
            self.minimumNumberOfExitId = null
          } else {
            self.minimumNumberOfExitObj.label = mnes[0].buildingCode.name
            self.minimumNumberOfExitObj.value = mnes[0]._id
            self.minimumNumberOfExitId = self.minimumNumberOfExitObj.value
          }

        })
        .catch(({data}) => {
          console.log(data.message)
          self.toast("Error", data.message, "danger");
        });

      self.paramEgressApi
        .getParamEgressesByBuildingCode(buildingCode)
        .then((response) => {
          var paramEgress = response.paramEgresses
          if (paramEgress.length === 0) {
            self.paramEgressObj.label = 'N/A'
            self.paramEgressObj.value = null
            self.paramEgressId = null
            self.sprinkleredCondition = false;
            self.obj.isSprinkleredBuilding = false;
          } else {
            self.paramEgressObj.label = paramEgress[0].buildingCode.name
            self.paramEgressObj.value = paramEgress[0]._id
            self.paramEgressId = self.paramEgressObj.value
            self.sprinkleredCondition = paramEgress[0].sprinkleredCondition
          }

          if (!self.sprinkleredCondition) {
            self.obj.isSprinkleredBuilding = false;
          }
        })
        .catch(({data}) => {
          console.log(data.message)
          self.toast("Error", data.message, "danger");
        });
      } else {
        self.olfMainCategoryId = null;
        self.minimumNumberOfExitObj.label = 'N/A'
        self.minimumNumberOfExitObj.value = null
        self.minimumNumberOfExitId = null
        self.paramEgressObj.label = 'N/A'
        self.paramEgressObj.value = null
        self.paramEgressId = null
      }
      
      if (buildingCode === '64c8a858310ecf8dae3e7b53' || buildingCode === '64c8a859310ecf8dae3e7b56') {
        this.showIsDiscount = true;
      } else {
        this.showIsDiscount = false;
      }
    }
  },

  methods: {
    onRunAnalysis() {
      if (this.obj.buildingCode && this.obj.classificationType && this.obj.olfMainCategoryId) {
        this.resetObj();
        this.analysisModal = true;
        const minDuration = 3000; // 3 seconds in milliseconds
        const maxDuration = 5000; // 5 seconds in milliseconds
        const duration = Math.floor(Math.random() * (maxDuration - minDuration + 1)) + minDuration;
        const interval = 10; // Interval for the update (milliseconds)
        const steps = duration / interval;
        const stepSize = 100 / steps;
  
        this.roomApi
        .getAllByFloorPlan(this.$route.params.id)
        .then((response) => {
          this.roomData = response;
        }).catch((err) => {
          this.toast("Error", err, "danger");
        });
  
        let currentProgress = 0;
  
        const progressInterval = setInterval(() => {
          if (currentProgress >= 100) {
            clearInterval(progressInterval);
            this.$refs.wpMenuMain.topBtnIsEnabled = true;
            this.$refs.wpBoardRef.changeRect();
          } else {
            currentProgress += stepSize;
            this.progress = currentProgress;
          }
        }, interval);
      } else {

        let message = "Please select ";
        if (!this.obj.buildingCode && !this.obj.classificationType) {
          message += "the building code and classification type";
        } else if (!this.obj.buildingCode) {
          message += "the building code";
        } else if (!this.obj.classificationType) {
          message += "the classification type";
        } else if (!this.obj.olfMainCategoryId) {
          message += "OLF Main Category"
        }

        this.toast("Error", message, "danger");
        this.workSettingPopup = true;
      }
    },    
    onCloseAnalysisModal() {
      this.analysisModal = false;
      setTimeout(() => {
        this.progress = 0;
      }, 500);
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    onFloorPlanConfirmation() {
      if (this.obj.buildingCode && (this.obj.buildingCode._id === this.buildingCodeId)) {
        this.onSubmit();
      } else {
        this.changeBuildingCodeConfirmationModal = true;
      }
    },
    onDeleteConfirmation(status, evt, accept) {
      var self = this;
      if (accept) {
        this.api
          .delete(self.obj._id)
          .then(() => {
            window.history.back();
          })
          .catch(({ data }) => {
            self.toast("Error", data.message, "danger");
          });
      }
      self.itemToDelete = {};
    },
    showDeleteConfirmation() {
      var self = this;
      self.itemToDelete = this.obj;
      self.warningModal = true;
    },

    onSubmit() {
      var self = this;
      let floorPlanId = self.obj._id

      self.obj.buildingCodeId = self.buildingCodeId;
      self.obj.olfMainCategoryId = self.olfMainCategoryId;
      self.obj.minimumNumberOfExitId = self.minimumNumberOfExitId;
      self.obj.paramEgressId = self.paramEgressId;

      for (let i = 0; i < self.obj.rooms.length; i++) {
        if (self.obj.rooms[i].minimumNumberOfExitId == "")
          self.obj.rooms[i].minimumNumberOfExitId = null;

        if (self.obj.rooms[i].occupantLoadFactorId == "")
          self.obj.rooms[i].occupantLoadFactorId = null;
      }

      function checkPropertiesLength(obj, propertyNames) {
        for (const propertyName of propertyNames) {
          if (obj.hasOwnProperty(propertyName)) {
            const propertyValue = obj[propertyName];
            if (propertyValue === null || propertyValue.trim().length < 1) {
              return false;
            }
          }
        }
        return true;
      }
      
      try {
        const requiredProperties = ['name', 'description', 'projectNumber', 'revision'];
        if (!checkPropertiesLength(self.obj, requiredProperties)) {
          this.workSettingPopup = true
          self.toast("Error", "Not All Required Field Are Filled In", "danger")
          return
        }

        this.api
        .update(self.obj, floorPlanId)
        .then(() => {
          self.workSettingPopup = false
          self.changeBuildingCodeConfirmationModal = false;
          self.toast("Success", "Update Success", "success");
          self.resetObj();
          })
          .catch(({ data }) => {
            self.toast("Error", data.message, "danger");
          });
        
      } catch (error) {
        console.log(error)
      }

    },
    uploaded(data) {
      this.uploadedFiles = data.uploadedFiles;
    },
    switchPageDrawing() {
      // this.$refs.wpBoardRef.handleSaveSilent();
      this.resetObj();
      this.currentPage = "board";
    },
    switchPageReview() {
      // this.$refs.wpBoardRef.handleSaveSilent();
      this.resetObj();
      this.currentPage = "review";
    },
    switchPagePrint() {
      // this.$refs.wpBoardRef.handleSaveSilent();
      this.resetObj();
      this.currentPage = "print";
    },
    settingClick() {
      // this.refreshOclFactorList();
      // this.refreshMneList();
      // this.refreshParamEgressList();
      this.olfMainCategoryList = this.removeDuplicates(this.olfMainCategoryList)
      this.workSettingPopup = true;
    },
    refreshOclFactorList() {
      var self = this;
      self.olfMainCategoryApi
        .getList()
        .then((response) => {
          var mainClassifications = response.results;
          for (let i = 0; i < mainClassifications.length; i++) {
            self.olfMainCategoryList.push({
              value: mainClassifications[i].id,
              label: mainClassifications[i].name,
            });
          }
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },

    refreshMneList() {
      var self = this;
      self.minimumNumberOfExitApi
        .getList()
        .then((response) => {
          var mnes = response.minimumNumberOfExit;
          for (let i = 0; i < mnes.length; i++) {
            self.minimumNumberOfExitList.push({
              value: mnes[i]._id,
              label: mnes[i].buildingCode.name,
            });
          }
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },

    refreshParamEgressList() {
      var self = this;
      self.paramEgressApi
        .getList()
        .then((response) => {
          var mnes = response.paramEgresses;
          for (let i = 0; i < mnes.length; i++) {
            self.paramEgressList.push({
              value: mnes[i]._id,
              label: mnes[i].buildingCode.name,
            });
          }
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },

    roomClick() {
      this.$refs.wpBoardRef.toolRoomClick();
    },
    scaleClick() {
      this.$refs.wpBoardRef.toolScaleClick();
    },
    resetObj() {
      var self = this;
      if (self.$route.params.id) {
        self.api
          .get(self.$route.params.id)
          .then((response) => {
            var resultObj = response;
            self.obj = resultObj;
            self.buildingCodeId = self.obj.buildingCodeId
            self.olfMainCategoryId = self.obj.olfMainCategoryId
            self.projectDate = self.formatUpdatedAt(self.obj.updatedAt)

            // Add the "image" property to each object in the "doors" array
            self.obj.doors.forEach(door => {
              if (door.doorType === 'swingIn' || door.doorType === 'swingOut' || door.doorType === 'swingBoth') {
                if (door.doorDirection === "swingLeft") {
                  this.swingDoor = this.leftSwingDoor;
                } else {
                  this.swingDoor = this.rightSwingDoor;
                }
              } else if (door.doorType === "folding") {
                this.swingDoor = this.foldingDoor;
              } else if (door.doorType === "openPath") {
                this.swingDoor = this.openPathDoor
              } else if (door.doorType === 'sliding') {
                this.swingDoor = this.slidingDoor
              } else if (
                door.doorType === "doubleDoor_swingIn" ||
                door.doorType === "doubleDoor_swingOut" ||
                door.doorType === "doubleDoor_swingBoth"
              ) {
                this.swingDoor = this.doubleDoor;
              } else if (door.doorType === 'oneHalfDoor') {
                this.swingDoor = this.oneHalfDoor;
              }
              
              //  Add fire rating door on load
              if (this.fireRatings && door.fireRatingId) {
                const matchingFireRating = self.fireRatings.find(
                  (fr) => fr.id === door.fireRatingId
                );
                if (matchingFireRating) {
                  const fireRatingMinutes = matchingFireRating.minutes;
                  const fireRatingImageKey = `fr${fireRatingMinutes}Door`;
                  door.fireRatingDoorImg = this[fireRatingImageKey];
                }
              }

              door.image = this.swingDoor;
              door.x = door.posX;
              door.y = door.posY;
            });

            this.$refs.wpBoardRef.kreoScale.x = response.kreoXScale ?? 1.352;
            this.$refs.wpBoardRef.kreoScale.y = response.kreoYScale ?? 1.352;

            this.$refs.wpBoardRef.pageLoading = false;

            // if (resultObj.obstacles == null) {
            //   self.obstacleApi
            //     .getByFloorPlan(self.$route.params.id)
            //     .then((response) => {
            //       resultObj.obstacles = response.result;

            //       if (resultObj.travelDistances == null) {
            //         self.travelDistanceApi
            //           .getListByFloorPlanId(self.$route.params.id)
            //           .then((response) => {
            //             resultObj.travelDistances = response.result;
            //             self.obj = resultObj;
            //             if (
            //               self.obj.scale == null ||
            //               self.obj.scale.distanceInMeterForOnePixel == 0
            //             ) {
            //               self.toast(
            //                 "Warning",
            //                 "Please check your scale setting",
            //                 "warning"
            //               );
            //             }
            //           })
            //           .catch((err) => {
            //             // self.obj = resultObj;
            //             console.log('Error', err)
            //           });
            //       }
            //     })
            //     .catch((err) => {
            //       // self.obj = resultObj;
            //       console.log('Error', err)
            //     });
            // }
            // console.clear();
          })
          .catch(({ data }) => {
            self.toast("Error", data.message, "danger");
            this.$router.push({ path: "/folder" });
          });
      } else {
        self.obj = self.getEmptyObj();
      }
    },
    getFireRatings() {
      this.fireRatingApi
        .getList()
        .then((response) => {
          this.fireRatings = response.results
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },
    getEmptyObj() {
      let obj = {
        id: null,
      };
      return obj;
    },
    loadImage(source, propertyName) {
      const image = new window.Image();
      image.src = source;
      image.onload = () => {
        this[propertyName] = image;
      };
    },
    formatUpdatedAt(date) {
      return moment(date).format('DD MMMM YYYY');
    },
    removeDuplicates(array) {
      return array.reduce((accumulator, current) => {
        const existing = accumulator.find((item) => item.label === current.label);
        if (!existing) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
    }
  },
  created() {
    this.loadImage(LeftSwingDoorImg, 'leftSwingDoor');
    this.loadImage(RightSwingDoorImg, 'rightSwingDoor');
    this.loadImage(FoldingDoorImg, 'foldingDoor');
    this.loadImage(OpenPathDoorImg, 'openPathDoor');
    this.loadImage(SlidingDoorImg, 'slidingDoor');
    this.loadImage(DoubleDoorImg, 'doubleDoor');
    this.loadImage(OneHalfDoorImg, 'oneHalfDoor');

    this.loadImage(Fr30Door, "fr30Door");
    this.loadImage(Fr60Door, "fr60Door");
    this.loadImage(Fr90Door, "fr90Door");
    this.loadImage(Fr120Door, "fr120Door");
    this.loadImage(Fr180Door, "fr180Door");
    this.loadImage(Fr240Door, "fr240Door");

  }
};
</script>

<style>
.top-left {
  /* float: left;
  z-index: 3;
  position: fixed;
  left: 20px;
  top: 0; */
}
.top-right {
  /* float: right;
  */
  position: fixed; 
  right: 0;
  z-index: 3;
  cursor: pointer;
}
.mid-left {
  float: left;
  z-index: 3;
  position: fixed;
  margin-top: 165px;
  top: 0;
  width: 264px;
  padding: 24px;
  background-color: #fff;
  height: calc(100% - 165px);
  overflow: auto;
}

.bottom-left {
  float: left;
  z-index: 3;
  position: fixed;
  bottom: 0;
}
.bottom-mid {
  float: left;
  z-index: 3;
  position: fixed;
  bottom: 5px;
  left: 270px;
}
.bottom-right {
  float: right;
  z-index: 3;
  position: fixed;
  bottom: 0;
  right: 0;
}
.bottom-right-2 {
  float: right;
  z-index: 3;
  position: fixed;
  bottom: 50px;
  right: 0;
}
.required-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding-left: -5px;
  padding-right: -5px;
}
.required-label::after {
  content: '*';
  color: red;
  margin-left: .3em;
}
.required-label {
  padding-left: 5px;
  padding-right: 5px;
  min-width: 25%;
  max-width: 25%;
}
.required-input {
  margin-bottom: 0;
  min-width: 75%;
  max-width: 75%;

}

.run-analysis {
  position: fixed;
  bottom: 0;
  z-index: 4;
  padding: 24px;
  width: 100%;
  max-width: 264px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.analysis-modal .close {
  display: none !important;
}

</style>
