<template>
  <aside class="d-flex">
    <div class="bottom-btns">
        <a  @click="onFullScreen()" class="fullscreen-btn">
          <span class="material-icons" v-if="!isFullScreen">fullscreen</span>
          <span class="material-icons" v-if="isFullScreen">fullscreen_exit</span>

        </a>
      </div>
  </aside>
</template>
<script>
import '@/index.css'
export default {
  name: "WpBottomMid",
  props: {
    scale: null,
  },
  data() {
    return {
      selectedItem: '',
      isFullScreen: false,
    }
  },
  methods: {
    // dashboard(){
    //    // alert('dashboard')
    // }
    scaleBoard() {
      this.$emit('scaleClick')
      this.selectedItem = 'scale';
    },
    aiSuggest() {
      this.$emit('aiClick')
      this.selectedItem = 'ai-suggest';
    },
    onFullScreen() {
      const docElement = document.documentElement;

      if (!document.fullscreenElement) {
        // Enter full-screen mode
        this.isFullScreen = true;
        if (docElement.requestFullscreen) {
          docElement.requestFullscreen();
        } else if (docElement.mozRequestFullScreen) { // Firefox
          docElement.mozRequestFullScreen();
        } else if (docElement.webkitRequestFullscreen) { // Chrome, Safari, and Opera
          docElement.webkitRequestFullscreen();
        } else if (docElement.msRequestFullscreen) { // IE/Edge
          docElement.msRequestFullscreen();
        }
      } else {
        // Exit full-screen mode
        this.isFullScreen = false
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
      }
      
      console.log('isFullScreen', this.isFullScreen)
    }
  },
  computed: {
    scaleText(){
      return "SCALE: " + this.lengthInPixel + " pixel : " +
      this.lengthInMeter + " meter";
    },
    lengthInPixel() {
      try {
        return Math.round(this.scale.lengthInPixel);
      } catch (error) {
        // console.log(error);
      }
      return 0;
    },
    lengthInMeter() {
      try {
        return this.scale.lengthInMeter;
      } catch (error) {
        // console.log(error);
      }
      return 0;
    },
  }
};
</script>

<style>
.bottom-btns {
  display: flex;
  gap: 8px;
}
.fullscreen-btn {
  width: 48px;
  height: 48px;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.fullscreen-btn.selected-- {
  background: #0A1439;
}

.fullscreen-btn.selected-- .scale-icon {
  filter: invert(100%);
}
.scale-icon {
  width: 16px;
  height: 16px;
}

</style>
