var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"d-flex"},[_c('div',{staticClass:"row btn-row"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ 
      content: 'Undo', 
      placement: 'bottom' 
    }),expression:"{ \n      content: 'Undo', \n      placement: 'bottom' \n    }"}],staticClass:"util-btn",attrs:{"color":"secondary","disabled":_vm.undoBtnDisabled},on:{"click":function($event){return _vm.$emit('undo')}}},[_c('span',{staticClass:"material-icons"},[_vm._v("undo")])]),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ 
      content: 'Redo', 
      placement: 'bottom' 
    }),expression:"{ \n      content: 'Redo', \n      placement: 'bottom' \n    }"}],staticClass:"util-btn",attrs:{"color":"secondary","disabled":_vm.redoBtnDisabled},on:{"click":function($event){return _vm.$emit('redo')}}},[_c('span',{staticClass:"material-icons"},[_vm._v("redo")])]),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ 
      content: 'Clear', 
      placement: 'bottom' 
    }),expression:"{ \n      content: 'Clear', \n      placement: 'bottom' \n    }"}],staticClass:"util-btn reset--",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('clear')}}},[_c('img',{attrs:{"width":"20px","src":require('@/assets/images/icons/reset.png'),"alt":""}})]),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ 
      content: 'Save', 
      placement: 'bottom' 
    }),expression:"{ \n      content: 'Save', \n      placement: 'bottom' \n    }"}],staticClass:"util-btn",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('save')}}},[_c('span',{staticClass:"material-icons"},[_vm._v("save")])]),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ 
      content: 'Run', 
      placement: 'bottom' 
    }),expression:"{ \n      content: 'Run', \n      placement: 'bottom' \n    }"}],staticClass:"util-btn",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('run')}}},[_c('span',{staticClass:"material-icons"},[_vm._v("play_arrow")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }