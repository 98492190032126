let apiUrl = process.env.VUE_APP_API_URL;

class PdfApi {

    getPdf(data) {
      var url = apiUrl + 'pdf'
        return api.call('post', url, data)
          .then(({ data }) => {
            return data;
        });
    }
}

export default PdfApi;