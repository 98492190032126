<template>
  <aside class="d-flex">
    <div class="ml-1 mr-1 float-right percent-component">{{percent}} %</div>
    <CButton @click="$emit('zoomOut')" color="secondary"  class="ml-1 mr-1 float-right" >-</CButton>
    <CButton @click="$emit('zoomReset')" color="secondary"  class="ml-1 mr-1 float-right" >Reset</CButton>
    <CButton @click="$emit('zoomIn')" color="secondary"  class="ml-1 mr-1 float-right" >+</CButton>
  </aside>
</template>
<script>
import '@/index.css'
export default {
  name: "WpZoom",
  methods: {
    dashboard(){
        alert('dashboard')
    }
  },
  props: ['percent']
};
</script>

<style>
.percent-component {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  color: #4f5d73;
  background-color: #ced2d8;
  border-color: #ced2d8;
}
</style>