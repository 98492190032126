<template>
  <div class="review">
    <div class="print">
      <a
        href="#"
        class="btn btn-sm btn-info ml-1"
        onclick="javascript:window.print();"
      >
        <CIcon name="cil-print" class="mr-1" /> Print
      </a>
    </div>
    <div class="review-layout">
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
        <CForm class="form-block">
          <h5>Overview</h5>
          <div class="row">
            <CInput
              label="Name"
              v-model="obj.name" readonly
              class="col-6"
            />
            <CInput
              label="Building Code"
              v-model="buildingCode"
              readonly
              class="col-6"
            />
            <CInput
              label="OLF Main Category"
              description="Classification that had been specified in general setting"
              :value="mainClassification"
              readonly
              class="col-6"
            />
            <CInput
              label="Dominant Classification"
              description="Classification based on most dominant classification on the plan"
              :value="dominantClassification"
              readonly
              class="col-6"
            />
          </div>
          <CRow>
            <CCol sm="6">
              <CRow form class="form-group justify-content-between">
                <div tag="label" class="col-form-label">
                  High Rise?
                </div>
                <div>
                  <CSwitch
                    class="mr-1"
                    color="primary"
                    :checked.sync="obj.isHighRise"
                    label-on="YES"
                    label-off="NO"
                    disabled
                  />
                </div>
              </CRow>
            </CCol>
            <CCol sm="6">
              <CRow form class="form-group justify-content-between">
                <div tag="label" class="col-form-label">
                  Sprinklered?
                </div>
                <div>
                  <CSwitch
                    class="mr-1"
                    color="primary"
                    :checked.sync="obj.isSprinkleredBuilding"
                    label-on="YES"
                    label-off="NO"
                    disabled
                  />
                </div>
              </CRow>
            </CCol>
          </CRow>
        </CForm>
        <!-- <CForm class="form-block">
            <h5> Base Calculation Method </h5>
            <div class="row align-items-end">
              <div class="col-10">
                <CSelect
                  label="Select Classification Type"
                  :options="['Main', 'Dominant', 'Custom']"
                  :value.sync="obj.classificationType"
                >
                </CSelect>
                <CSelect
                  label="Custom Classification"
                  v-if="obj.classificationType === 'Custom'"
                  :options="customClassificationList"
                  :value.sync="customClassificationId"
                >
                </CSelect>
              </div>
              <div class="col-2">
                <CButton
                  class="update-btn"
                  type="submit"
                  size="sm"
                  color="primary"
                  @click="updateClassificationType"
                >
                  <CIcon name="cil-check-circle" /> Update
                </CButton>
              </div>
            </div>
        </CForm> -->
      <div class="form-block">
        <h5>Occupant Load</h5>
        <div>
          <h6>Floor Summary</h6>
          <div>
            <CRow>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Occupant Load (person)"
                    v-model="totalOccupantLoad"
                    readonly
                  />
                  <CInput
                    label="Total Number of Exit Door Required"
                    v-model="totalExitDoorRequired"
                    readonly
                  />
                  <CInput
                    label="Exit Stair Quantity Compliance"
                    v-model="isExitDoorQuantityComplied"
                    readonly
                    class="compliance-assessment"
                    :class="{ 
                      'success': totalExitDoorProvided >= totalExitDoorRequired, 
                      'danger': totalExitDoorProvided < totalExitDoorRequired
                      }"
                  />
                </CForm>
              </CCol>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Effective Occupant Load (person)"
                    v-model="totalEffectiveOccupantLoad"
                    readonly
                  />
                  <CInput
                    label="Total Number of Exit Door Provided"
                    v-model="totalExitDoorProvided"
                    readonly
                  />
                </CForm>
              </CCol>
            </CRow>
          </div>
          <template v-if="computedOccupantLoadItems && computedOccupantLoadItems.length">
            <h6>Rooms</h6>
            <div class="table-responsive-sm">
              <CDataTable
                :items="computedOccupantLoadItems"
                :fields="occupantLoadFieldListWithoutEffectivePercentage"
                striped
                caption="Striped Table"
              >
                <template #subRooms="{ item }">
                  <td
                  :class="{ 'pt-34': item.subRooms && item.subRooms.length }">
                    <template v-if="item.subRooms && item.subRooms.length">
                      <div 
                      v-for="(subRoom, index) in item.subRooms" 
                      :key="index"
                      style="padding: 12px 0;"
                      >
                        {{ subRoom.name }}
                        <br />
                      </div>
                    </template>
                    <template v-else>
                      {{ '-' }}
                    </template>
                  </td>
                </template>
                <template #status="{ item }">
                  <td v-if="item.occupantNumber !== '-'">
                    <CBadge :color="getComplianceColor(item)">
                    {{ getComplianceStatus(item) }}
                    </CBadge>
                  </td>
                  <td v-if="item.occupantNumber === '-'">
                    -
                  </td>
                </template>
                <!-- <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="toggleDetails(item, index)"
                    >
                      {{ Boolean(item._toggled) ? "Hide" : "Show" }}
                    </CButton>
                  </td>
                </template> -->
                <template #details="{ item }">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <p class="text-muted">Name: {{ item.name }}</p>
                    </CCardBody>
                  </CCollapse>
                </template>
                <template #occupantNumber="{ item }">
                  <td style="padding: 0;" class="text-center">
                    <div style="padding: 12px 0;">{{ item.occupantNumber }}</div>
                    <template v-if="item.subRooms && item.subRooms.length">
                      <div 
                      v-for="(subRoom, index) in item.subRooms" 
                      class="oc-number-td"
                      :key="index"
                      >
                        <!-- {{ subRoom.subRoomOccupantNumber }} -->
                        <!-- <br /> -->
                      </div>
                    </template>
                  </td>
                </template>
              </CDataTable>
            </div>
            <br>
            <h6><b>Effective Occupant Load</b></h6>
            <br/>
              <!-- <div>
              <CRow>
                <CCol>
                  <CForm>
                    <CInput
                      label="Total Effective Occupant Load (person)"
                      v-model="totalEffectiveOccupantLoad"
                      readonly
                    />
                    <CInput
                      label="Total Number of Exit Door Required"
                      v-model="totalExitDoorRequired"
                      readonly
                    />
                  </CForm>
                </CCol>
                <CCol>
                  <CForm>
                    <CInput
                      label="Total Number of Exit Door Provided"
                      v-model="totalExitDoorProvided"
                      readonly
                    />
                    <CInput
                      label="Exit Stair Quantity Compliance"
                      v-model="isExitDoorQuantityComplied"
                      readonly
                      class="compliance-assessment"
                      :class="{ 
                        'success': totalExitDoorProvided >= totalExitDoorRequired, 
                        'danger': totalExitDoorProvided < totalExitDoorRequired
                        }"
                    />
                  </CForm>
                </CCol>
              </CRow>
            </div> -->
            <div class="table-responsive-sm">
              <CDataTable
                :items="computedEffectiveOccupantLoadItems"
                :fields="occupantLoadFieldList"
                striped
                caption="Striped Table"
                class="compliance-table"
              >
                <template #subRooms="{ item }">
                  <td
                  :class="{ 'pt-34': item.subRooms && item.subRooms.length }">
                    <template v-if="item.subRooms && item.subRooms.length">
                      <div 
                      v-for="(subRoom, index) in item.subRooms" 
                      :key="index"
                      style="padding: 12px 0;"
                      >
                        {{ subRoom.name }}
                        <br />
                      </div>
                    </template>
                    <template v-else>
                      {{ '-' }}
                    </template>
                  </td>
                </template>
                <template #status="{ item }">
                  <td v-if="item.occupantNumber !== '-'">
                    <CBadge :color="getComplianceColor(item)">
                    {{ getComplianceStatus(item) }}
                    </CBadge>
                  </td>
                  <td v-if="item.occupantNumber === '-'">
                    -
                  </td>
                </template>
                <!-- <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="toggleDetails(item, index)"
                    >
                      {{ Boolean(item._toggled) ? "Hide" : "Show" }}
                    </CButton>
                  </td>
                </template> -->
                <template #details="{ item }">
                  <CCollapse
                    :show="Boolean(item._toggled)"
                    :duration="collapseDuration"
                  >
                    <CCardBody>
                      <p class="text-muted">Name: {{ item.name }}</p>
                    </CCardBody>
                  </CCollapse>
                </template>
                <template #occupantNumber="{ item }">
                  <td style="padding: 0;" class="text-center">
                    <div style="padding: 12px 0;">{{ item.occupantNumber }}</div>
                    <template v-if="item.subRooms && item.subRooms.length">
                      <div 
                      v-for="(subRoom, index) in item.subRooms" 
                      class="oc-number-td"
                      :key="index"
                      >
                        {{ subRoom.subRoomOccupantNumber }}
                        <br />
                      </div>
                    </template>
                  </td>
                </template>
                <template #effectivePercentage="{ item }">
                  <td :class="{ 'pt-34': item.subRooms && item.subRooms.length }">
                    <div v-for="(subRoom, index) in item.subRooms"
                    class="oc-number-td"

                    :key="index"
                    style="padding: 12px 0;"
                    >
                    {{ subRoom.effectivePercentage ? `${subRoom.effectivePercentage}%` : '-' }}
                    <br />
                  </div>
                  </td>
                </template>
              </CDataTable>
            </div>
          </template>
        </div>
      </div>
      <div class="form-block">
        <h5> Egress Capacity (Floor) </h5>
        <div>
          <h6 v-if="obj.exitStairWidths.length > 0">Required Exit Stair Width</h6>
          <div v-if="obj.exitStairWidths.length > 0">
            <CRow>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Occupant Load (person)"
                    v-model="totalOccupantLoad"
                    readonly
                  />
                  <CInput
                    label="Total Exit Stair Width Required (m)"
                    v-model="totalStairExitWidthRequired"
                    readonly
                  />
                </CForm>
              </CCol>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Exit Stair Width Provided (m)"
                    v-model="totalStairExitWidthProvided"
                    readonly
                  />
                  <CInput
                    label="Exit Stair Width Compliance"
                    v-model="isStairExitWidthComplied"
                    readonly
                    class="compliance-assessment"
                    :class="{ 
                      'success': totalStairExitWidthProvided >= totalStairExitWidthRequired, 
                      'danger': totalStairExitWidthProvided < totalStairExitWidthRequired
                      }"
                  />
                </CForm>
              </CCol>
            </CRow>
          </div>
          <template v-if="computedExitStairWidthItems && computedExitStairWidthItems.length">
            <h6>Stairs</h6>
            <div class="table-responsive-sm">
              <CDataTable
                :items="computedExitStairWidthItems"
                :fields="exitStairWidthFields"
                striped
                caption="Striped Table"
              >
              </CDataTable>
            </div>
          </template>
          <h6>Required Exit Door Width</h6>
          <div>
            <CRow>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Occupant Load (person)"
                    v-model="totalOccupantLoad"
                    readonly
                  />
                  <!-- <CInput
                    label="Total Exit Door Width Required Per Person (m)"
                    v-model="exitDoorWidthParameter"
                    readonly
                  /> -->
                  <!-- <CInput
                    label="Exit Door Width Compliance"
                    v-model="isDoorExitWidthComplied"
                    readonly
                  /> -->
                  <CInput
                    label="Total Door Width Required (m)"
                    v-model="totalDoorExitWidthRequired"
                    readonly
                  />
                </CForm>
              </CCol>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Door Width Provided (m)"
                    v-model="totalDoorExitWidthProvided"
                    readonly
                  />
                  <CInput
                    label="Exit Door Width Compliance"
                    v-model="isDoorExitWidthComplied"
                    readonly
                    class="compliance-assessment"
                    :class="{ 
                      'success': totalDoorExitWidthProvided >= totalDoorExitWidthRequired, 
                      'danger': totalDoorExitWidthProvided < totalDoorExitWidthRequired
                      }"
                  />
                </CForm>
              </CCol>
            </CRow>
          </div>
          <template v-if="computedExitDoorWidthItems && computedExitDoorWidthItems.length">
            <h6>Exit Doors</h6>
            <div class="table-responsive-sm">
              <CDataTable
                :items="computedExitDoorWidthItems"
                :fields="exitDoorWidthFields"
                striped
                caption="Striped Table"
              >
              </CDataTable>
            </div>
          </template>
        </div>
      </div>

      <div class="form-block"
      v-if="(obj.buildingCode && 
      (obj.buildingCode.code === 'ADB' 
      || obj.buildingCode.code === 'IBC' 
      || obj.buildingCode.code === 'UBBL') 
      && obj.isDiscount)"
      >
        <h5> Egress Capacity Required When Discounting an Exit/Stair (Floor) </h5>
        <div>
          <h6  v-if="obj.exitStairWidths.length > 0">Required Exit Stair Width</h6>
          <div  v-if="obj.exitStairWidths.length > 0">
            <CRow>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Occupant Load (person)"
                    v-model="totalOccupantLoadDiscount"
                    readonly
                  />
                  <CInput
                    label="Total Exit Stair Width Required (m)"
                    v-model="totalStairExitWidthDiscountRequired"
                    readonly
                  />
                </CForm>
              </CCol>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Exit Stair Width Provided (m)"
                    v-model="totalStairExitWidthProvided"
                    readonly
                  />
                  <CInput
                    label="Exit Stair Width Compliance"
                    v-model="isStairExitWidthComplied"
                    readonly
                  />
                </CForm>
              </CCol>
            </CRow>
          </div>
          <template v-if="computedExitStairWidthWithDiscountItems && computedExitStairWidthWithDiscountItems.length">
            <h6>Stairs</h6>
            <div class="table-responsive-sm">
              <CDataTable
                :items="computedExitStairWidthWithDiscountItems"
                :fields="exitStairWidthFields"
                striped
                caption="Striped Table"
              >
              </CDataTable>
            </div>
          </template>
          <h6>Required Exit Door Width</h6>
          <div>
            <CRow>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Occupant Load (person)"
                    v-model="totalOccupantLoadDiscount"
                    readonly
                  />
                  <!-- <CInput
                    label="Total Exit Door Width Required Per Person (m)"
                    v-model="exitDoorWidthParameter"
                    readonly
                  /> -->
                  <!-- <CInput
                    label="Exit Door Width Compliance"
                    v-model="isDoorExitWidthComplied"
                    readonly
                  /> -->
                  <CInput
                    v-if="obj.buildingCode.code === 'ADB'"
                    label="Number of Occupant Load that can serve by the remaining exit"
                    v-model="totalDoorExitWidthDiscountRequired" 
                    readonly
                  />
                  <CInput
                    v-else
                    label="Total Door Width Provided (m)"
                    v-model="totalDoorExitWidthDiscountRequired" 
                    readonly
                  />
                  <!-- <CInput
                    label="Total Door Width Required (m)"
                    v-model="totalDoorExitWidthDiscountRequired"
                    readonly
                  /> -->
                </CForm>
              </CCol>
              <CCol>
                <CForm>
                  <CInput
                    label="Total Door Width Provided (m)"
                    v-model="totalDoorExitWidthDiscountProvided"
                    readonly
                  />
                  <CInput
                    label="Exit Door Width Compliance"
                    v-model="isDoorExitWidthCompliedDiscountADB"
                    readonly
                    class="compliance-assessment"
                    :class="{ 
                      'success': totalDoorExitWidthRequired <= totalOccupantLoadDiscount, 
                      'danger': totalDoorExitWidthRequired >= totalOccupantLoadDiscount
                      }"
                  />
                </CForm>
              </CCol>
            </CRow>
          </div>
          <template v-if="computedExitDoorWidthItemsDiscounting && computedExitDoorWidthItemsDiscounting.length">
            <h6>Exit Doors</h6>
            <div class="table-responsive-sm">
              <CDataTable
                :items="computedExitDoorWidthItemsDiscounting"
                :fields="exitDoorWidthFields"
                striped
                caption="Striped Table"
              >
              </CDataTable>
            </div>
          </template>
        </div>
      </div>
      <div class="form-block" v-if="computedRoomItems && computedRoomItems.length">
        <h5> Egress Capacity (Room) </h5>
        <div>
          <div class="table-responsive-sm">
            <CDataTable
              :items="computedRoomItems"
              :fields="doorRoomFields"
              striped
              caption="Striped Table"
              class="compliance-table room--"
            >
              <template #subRooms="{ item }">
                <td
                :class="{ 'pt-34': item.subRooms && item.subRooms.length }">
                  <template v-if="item.subRooms && item.subRooms.length">
                    <div 
                    v-for="(subRoom, index) in item.subRooms" 
                    :key="index"
                    style="padding: 12px 0;"
                    >
                      {{ subRoom.name }}
                      <br />
                    </div>
                  </template>
                  <template v-else>
                    {{ '-' }}
                  </template>
                </td>
              </template>
              <template #occupantNumber="{ item }">
                <td style="padding: 0;" class="text-center">
                  <div style="padding: 12px 0;">{{ item.occupantNumber }}</div>
                  <template v-if="item.subRooms && item.subRooms.length">
                    <div 
                    v-for="(subRoom, index) in item.subRooms" 
                    class="oc-number-td"
                    :key="index"
                    >
                      {{ subRoom.subRoomOccupantNumber }}
                      <br />
                    </div>
                  </template>
                </td>
              </template>
              <template #status="{ item }">
                <td v-if="item.occupantNumber !== '-'">
                  <CBadge :color="getDoorWidthComplianceColor(item)">
                    {{ getDoorWidthComplianceStatus(item) }}
                  </CBadge>
                </td>
                <td v-if="item.occupantNumber === '-'">
                  -
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </div>

      <div 
      class="form-block" 
      v-if="computedRoomWithDiscountItems 
      && computedRoomWithDiscountItems.length
      && (obj.buildingCode && obj.buildingCode.code === 'IBC')"
      >
        <h5> Egress Capacity Required When Discounting (Room) </h5>
        <div>
          <div class="table-responsive-sm">
            <CDataTable
              :items="computedRoomWithDiscountItems"
              :fields="doorRoomFields"
              striped
              caption="Striped Table"
              class="compliance-table room--"
            >
              <template #subRooms="{ item }">
                <td
                :class="{ 'pt-34': item.subRooms && item.subRooms.length }">
                  <template v-if="item.subRooms && item.subRooms.length">
                    <div 
                    v-for="(subRoom, index) in item.subRooms" 
                    :key="index"
                    style="padding: 12px 0;"
                    >
                      {{ subRoom.name }}
                      <br />
                    </div>
                  </template>
                  <template v-else>
                    {{ '-' }}
                  </template>
                </td>
              </template>
              <template #occupantNumber="{ item }">
                <td style="padding: 0;" class="text-center">
                  <div style="padding: 12px 0;">{{ item.occupantNumber }}</div>
                  <template v-if="item.subRooms && item.subRooms.length">
                    <div 
                    v-for="(subRoom, index) in item.subRooms" 
                    class="oc-number-td"
                    :key="index"
                    >
                      {{ subRoom.subRoomOccupantNumber }}
                      <br />
                    </div>
                  </template>
                </td>
              </template>
              <template #status="{ item }">
                <td v-if="item.occupantNumber !== '-'">
                  <CBadge :color="getDoorWidthComplianceColor(item)">
                    {{ getDoorWidthComplianceStatus(item) }}
                  </CBadge>
                </td>
                <td v-if="item.occupantNumber === '-'">
                  -
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </div>

      <div class="form-block" v-if="computedDoorSwingItems && computedDoorSwingItems.length">
        <h5> Door Swing Directions </h5>
        <div>
          <div class="table-responsive-sm">
            <CDataTable
              :items="computedDoorSwingItems"
              :fields="doorSwingFields"
              striped
              caption="Striped Table"
              class="door-swing-table"
            >
            <template #subRooms="{ item }">
              <td style="font-style: italic;">
                <template v-if="item.subRooms && item.subRooms.length">
                  <div 
                  v-for="(subRoom, index) in item.subRooms" 
                  :key="index"
                  style="padding: 12px 0;"
                  >
                    {{ subRoom.name }}
                    <br />
                  </div>
                </template>
                <template v-else>
                  {{ '-' }}
                </template>
              </td>
            </template>
            <template #doorSwingDirectionRequired="{ item }">
              <td style="padding: 0;">
                <div style="padding: 12px;" v-for="(door, index) in item.roomDoors" :key="index" v-if="door.doors.doorType === 'swingIn' || door.doors.doorType === 'swingOut' || door.doors.doorType === 'swingBoth'">
                  {{ item.doorSwingDirectionRequired }}                  
                  <br />
                </div>
              </td>
            </template>
            <template #roomDoors="{ item }">
              <td style="padding: 0;">
                <div style="padding: 12px;" v-for="(door, index) in item.roomDoors" :key="index"  v-if="door.doors.doorType === 'swingIn' || door.doors.doorType === 'swingOut' || door.doors.doorType === 'swingBoth'">
                  {{ door.doors.name }}
                  <br />
                </div>
              </td>
            </template>
            <template #swingInDoor="{ item }">
              <td style="padding: 0;">
                <div 
                style="padding: 12px; text-align: center;" 
                v-for="(door, index) in item.roomDoors" 
                :key="index" 
                v-if="door.doors.doorType === 'swingIn' || door.doors.doorType === 'swingOut' || door.doors.doorType === 'swingBoth'"
                >
                  {{ door.doors.doorType === 'swingIn' ? 1 : 0 }}
                  <br />
                </div>
              </td>
            </template>
            <template #swingOutDoor="{ item }">
              <td style="padding: 0;">
                <div style="padding: 12px; text-align: center;" v-for="(door, index) in item.roomDoors" :key="index"  v-if="door.doors.doorType === 'swingIn' || door.doors.doorType === 'swingOut' || door.doors.doorType === 'swingBoth'">
                  {{ door.doors.doorType === 'swingOut' ? 1 : 0 }}
                  <br />
                </div>
              </td>
            </template>
            <template #swingBothDoor="{ item }">
              <td style="padding: 0;">
                <div style="padding: 12px; text-align: center;" v-for="(door, index) in item.roomDoors" :key="index"  v-if="door.doors.doorType === 'swingIn' || door.doors.doorType === 'swingOut' || door.doors.doorType === 'swingBoth'">
                  {{ door.doors.doorType === 'swingBoth' ? 1 : 0 }}
                  <br />
                </div>
              </td>
            </template>
            <template #status="{ item }">
              <td style="padding: 0;">
                <!-- <CBadge :color="getDoorSwingComplianceColor(item)">{{
                  getDoorSwingComplianceStatus(item)
                }}</CBadge> -->
                <div style="padding: 12px;" v-for="(door, index) in item.roomDoors"  v-if="door.doors.doorType === 'swingIn' || door.doors.doorType === 'swingOut' || door.doors.doorType === 'swingBoth'">
                  <CBadge :color='item.occupantNumber !== "-" ? door.doors.doorType === convertToCamelCase(item.doorSwingDirectionRequired) || item.doorSwingDirectionRequired === "Any" ? "success" : "danger" : null'>
                  {{ item.occupantNumber !== "-" ? door.doors.doorType === convertToCamelCase(item.doorSwingDirectionRequired) || item.doorSwingDirectionRequired === 'Any' ? "YES" : "NO" : "" }}
                </CBadge>
                </div>
              </td>
              <br>
            </template>
            </CDataTable>
          </div>
        </div>
      </div>
      <div class="form-block" 
      v-if="(obj.buildingCode && 
      !(obj.buildingCode.code === 'NFPA 101' 
      || obj.buildingCode.code === 'IBC'
      || obj.buildingCode.code === 'ADB')
      && exitSeperationProvided > 1
      )"
      >
        <h5> Separation Of Exit (Floor) </h5>
        <div>
          <div class="table-responsive-sm" v-if="computedExitSeperationItems && computedExitSeperationItems.length">
            <strong>Exit Separation</strong>
            <CDataTable
              :items="computedExitSeperationItems"
              :fields="exitSeperationFields"
              striped
              caption="Striped Table"
            >
            </CDataTable>
          </div>
          <template v-if="computedDiagonalDistanceItems && computedDiagonalDistanceItems.length && (this.obj.buildingCode.code !== 'BCA' && this.obj.buildingCode.code !== 'UBBL')">
            <strong>Diagonal Distance</strong>
            <div class="table-responsive-sm">
              <CDataTable
                :items="computedDiagonalDistanceItems"
                :fields="exitSeperationFields"
                striped
                caption="Striped Table"
              >
              </CDataTable>
            </div>
          </template>
          <div>
            <strong>Summary</strong>
            <CRow>
              <CCol>
                <CForm>
                  <CInput
                    label="Exit Separation Required (m)"
                    v-model="exitSeperationRequired"
                    readonly
                  />
                  <CInput
                    label="Is Exit Separation Comply?"
                    v-model="isExitSeperationComplied"
                    readonly
                    class="compliance-assessment"
                    :class="{ 
                      'success': exitSeperationProvided >= exitSeperationRequired, 
                      'danger': exitSeperationProvided < exitSeperationRequired
                      }"
                  />
                </CForm>
              </CCol>
              <CCol>
                <CForm>
                  <CInput
                    label="Exit Separation Provided (m)"
                    v-model="exitSeperationProvided"
                    readonly
                  />
                </CForm>
              </CCol>
            </CRow>
          </div>
        </div>
      </div>
      <div class="form-block" v-if="computedRoomExitSeperationItems && computedRoomExitSeperationItems.length && obj.buildingCode.code !== 'ADB'">
        <h5> Separation Of Exit (Room) </h5>
        <div>
          <div class="table-responsive-sm">
            <strong>Exit Separation</strong>
            <CDataTable
              :items="computedRoomExitSeperationItems"
              :fields="roomExitSeperationFields"
              striped
              caption="Striped Table"
            >
              <template #status="{ item }">
                <td>
                  <CBadge :color="getRoomExitSeperationComplianceColor(item)">{{
                    getRoomExitSeperationComplianceStatus(item)
                  }}</CBadge>
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </div>
      <div class="form-block" v-if="computedTravelDistanceItems && computedTravelDistanceItems.length">
        <h5> {{computedTravelDistanceTitle}} </h5>
        <div>
          <div class="table-responsive-sm">
            <CDataTable
              :items="computedTravelDistanceItems"
              :fields="travelDistanceFields"
              striped
              caption="Striped Table"
            >
              <template #status="{ item }">
                <td>
                  <CBadge :color="getTravelDistanceComplianceColor(item)">{{
                    getTravelDistanceComplianceStatus(item)
                  }}</CBadge>
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </div>
      <div class="form-block" v-if="computedCommonPathItems && computedCommonPathItems.length">
        <h5> {{ computedCommonPathTitle }} </h5>
        <div>
          <div class="table-responsive-sm">
            <CDataTable
              :items="computedCommonPathItems"
              :fields="commonPathFields"
              striped
              caption="Striped Table"
            >
              <template #status="{ item }">
                <td>
                  <CBadge :color="getCommonPathDistanceComplianceColor(item)">{{
                    getCommonPathDistanceComplianceStatus(item)
                  }}</CBadge>
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </div>
      <div class="form-block" v-if="computedDeadEndItems && computedDeadEndItems.length">
        <h5> Dead End </h5>
        <div>
          <div class="table-responsive-sm">
            <CDataTable
              :items="computedDeadEndItems"
              :fields="deadEndFields"
              striped
              caption="Striped Table"
            >
              <template #status="{ item }">
                <td>
                  <CBadge :color="getDeadEndDistanceComplianceColor(item)">{{
                    getDeadEndDistanceComplianceStatus(item)
                  }}</CBadge>
                </td>
              </template>
            </CDataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OccupantLoadFactorApi from "@/lib/occupantLoadFactorApi";
import MinimumNumberOfExitApi from "@/lib/minimumNumberOfExitApi";
import ParamEgressApi from "@/lib/paramEgressApi";
import OlfMainCategoryApi from "@/lib/olfMainCategoryApi";
import FloorPlanApi from "@/lib/floorPlanApi";
import { parse } from "@babel/core";

const occupantLoadFieldList = [
  // { key: "id", _style: "min-width:200px;" },
  { 
    key: "name", 
    label: "Name",
    _style: "width: 8%" 
  },
  //   _style: "width:1%",
  { 
    key: "subRooms", 
    label: "Sub Room",
    _style: "width: 13%" 
  },
  {
    key: "olfMainCategoryName",

    label: "Main Category",
  },
  { key: "area", label: "Area (m2)" },

  {
    key: "oclValue",

    label: "Occupant Density (m2/person)",
  },
  {
    key: "occupantNumber",

    label: "Occupant Number",
  },
  {
    key: "totalOccupantNumber",
    label: "Total Occupant Number"
  },
  {
    key: "mneCount",

    label: "Number of Exit Door Required",
  },
  {
    key: "totalDoorProvided",

    label: "Number of Exit Door Provided",
  },
  {
    key: "effectivePercentage",
    label: "Effective Percentage",
  },
  {
    key: "status",
    label: "Room Compliance",

    sorter: false,
    filter: false,
  },
  // {
  //   key: "show_details",
  //   label: "",
  //   _style: "width:1%",
  //   sorter: false,
  //   filter: false,
  // },
];

// Fields for the table without effectivePercentage
const occupantLoadFieldListWithoutEffectivePercentage = occupantLoadFieldList
.filter(
  (field) => 
  field.key !== "subRooms" && 
  field.key !== "effectivePercentage" && 
  field.key !== "totalOccupantNumber" && 
  field.key !== "mneCount" && 
  field.key !== "totalDoorProvided" && 
  field.key !== "status"
);

const exitStairWidthFields = [
  { key: "name", label: "Exit Stair Name" },
  { key: "distance", label: "Distance (m)" },
  // {
  //   key: "show_details",
  //   label: "",
  //   _style: "width:1%",
  //   sorter: false,
  //   filter: false,
  // },
];

const exitDoorWidthFields = [
  { key: "name", label: "Exit Door Name" },
  { key: "distance", label: "Width (m)" },
  // {
  //   key: "show_details",
  //   label: "",
  //   _style: "width:1%",
  //   sorter: false,
  //   filter: false,
  // },
];

const doorRoomFields = [
  { 
    key: "name",
    label: "Name",
    _style: "width: 8%" 
  },
  { key: "subRooms", 
    label: "Sub Room", 
    _style: "width: 13%" 
  },
  { key: "area", label: "Area (m2)" },

  {
    key: "oclValue",

    label: "Occupant Density (m2/person)",
  },
  {
    key: "occupantNumber",

    label: "Occupant Number",
  },
  // { key: "totalDoorProvided", label: "Door Provided" },
  { key: "totalDoorWidthRequired", label: "Door Width Required" },
  { key: "totalDoorWidthProvided", label: "Door Width Measured" },
  {
    key: "status",
    label: "Compliance",

    sorter: false,
    filter: false,
  },
];

const doorSwingFields = [
  { key: "name", _style: "width: 8%" },
  {
    key: "subRooms", 
    label: "Sub Room", 
    _style: "width: 8%" 
  },
  { key: "roomDoors", label: "Door",   _style: "width: 8%" },
  { key: "area", label: "Area (m2)" },
  { key: "occupantNumber", label: "Occupant Number" },
  {
    key: "totalOccupantNumber",
    label: "Total Occupant Number"
  },
  {
    key: "doorSwingDirectionRequired",
    label: "Door Swing Direction Required",
    _style: "width:17%",
  },
  {
    key: "swingInDoor", label: "Swing In"
  },
  {
    key: "swingOutDoor", label: "Swing Out"
  },
  {
    key: "swingBothDoor", label: "Swing Both"
  },
  // { key: "totalDoorProvided", label: "Door Provided" },
  // { key: "totalDoorSwingIn", label: "Swing In" },
  // { key: "totalDoorSwingOut", label: "Swing Out" },
  // { key: "totalDoorSwingBoth", label: "Swing Both" },
  {
    key: "status",
    label: "Compliance",

    sorter: false,
    filter: false,
  },
];

const roomExitSeperationFields = [
  { key: "name", label: "Name" },
  { key: "area", label: "Area (m2)" },
  { key: "diagonalDistance", label: "Diagonal (m)",  }, // remove column if BCA
  {
    key: "exitRequiredExitSeperationDistance",
    label: "Exit Separation Required (m)",
  },
  {
    key: "exitSeperationDistanceProvided",
    label: "Exit Separation Provided (m)",
  },
  {
    key: "status",
    label: "Compliance",

    sorter: false,
    filter: false,
  },
];

const exitSeperationFields = [
  { key: "name", label: "Name" },
  { key: "distance", label: "Distance (m)" },
];

const travelDistanceFields = [
  { 
    key: "room", 
    label: "Room", 
  },
  { 
    key: "name", 
    label: "Travel Distance Name",
  },
  {
    key: "roomMainCategory",
    label: "Main Category"
  },
  { 
    key: "requiredDistance", 
    label: "Travel Distance Limit (m)",
  },
  { 
    key: "distance", 
    label: "Travel Distance Measured (m)",
  },
  {
    key: "status",
    label: "Compliance",
    sorter: false,
    filter: false,
  },
];

const commonPathFields = [
  { 
    key: "room", 
    label: "Room", 
  },
  { 
    key: "name", 
    label: "Common Path Name",
  },
  { 
    key: "distanceRequired", 
    label: "Common Path Distance Limit (m)",
  },
  { 
    key: "distance", 
    label: "Common Path Distance Measured (m)",
  },
  {
    key: "status",
    label: "Compliance",
    sorter: false,
    filter: false,
  },
];

const deadEndFields = [
  { 
    key: "corridor", 
    label: "Corridor", 
  },
  { 
    key: "name", 
    label: "Dead End Name",
  },
  { 
    key: "distanceRequired", 
    label: "Dead End Path Distance Limit (m)",
  },
  { 
    key: "distance", 
    label: "Dead End Distance Provided (m)",
  },
  {
    key: "status",
    label: "Compliance",
    sorter: false,
    filter: false,
  },
];

export default {
  name: "WpReview",
  data() {
    return {
      minimumNumberOfExit: null,
      infoList: [],
      loading: true,
      collapseDuration: 0,
      occupantLoadFieldListWithoutEffectivePercentage,
      occupantLoadFieldList,
      exitStairWidthFields,
      exitDoorWidthFields,
      exitSeperationFields,
      roomExitSeperationFields,
      doorRoomFields,
      doorSwingFields,
      travelDistanceFields,
      commonPathFields,
      deadEndFields,
      occupantLoadFactorApi: new OccupantLoadFactorApi(),
      minimumNumberOfExitApi: new MinimumNumberOfExitApi(),
      exitStairWidthApi: new ParamEgressApi(),
      olfMainCategoryApi: new OlfMainCategoryApi(),

      customClassificationList: [],
      customClassificationId: "",

      floorPlanApi: new FloorPlanApi(),
    };
  },

  props: {
    obj: {},
    roomData: []
  },
  watch: {
    obj(newVal, oldVal) {
      // this.rooms = this.obj.rooms;
      var self = this;
      self.olfMainCategoryApi
        .getList()
        .then((response) => {
          var classifications = response.results;
          for (let i = 0; i < classifications.length; i++) {
            self.customClassificationList.push({
              value: classifications[i].id,
              label: classifications[i].name,
            });
          }
          if (this.obj.customClassificationId)
            self.customClassificationId = this.obj.customClassificationId;
          else self.customClassificationId = classifications[0].value;
        })
        .catch(({ data }) => {
          self.toast("Error", data, "danger");
        });

      if (self.obj.buildingCodeId) {
        self.minimumNumberOfExitApi
          .getMinimumNumberOfExitByBuildingCode(self.obj.buildingCodeId)
          .then((response) => {
            self.minimumNumberOfExit = response.results;
          })
          .catch(({ data }) => {
            // self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }

      this.refreshCalculation();
      this.generateFslReport();
    },
  },
  computed: {
    isExitDoorQuantityComplied() {
      if (!this.obj || !this.obj.buildingCode || !this.obj.buildingCode.code) {
        return "";
      }

      if ((this.obj.buildingCode.code === "UBBL" && this.obj.olfMainCategories[0].code !== "Assembly") || (this.obj.buildingCode.code === "BCA" && this.obj.isHighRise === false)) {
        return "-";
      }

      if (this.totalExitDoorProvided >= this.totalExitDoorRequired) {
        return "YES";
      } else {
        return "NO";
      }
    },
    totalExitDoorRequired() {
      if (!this.obj || !this.obj.buildingCode || !this.obj.buildingCode.code) {
        return "N/A";
      }

      const buildingCode = this.obj.buildingCode.code;

      if ((buildingCode === "UBBL" && this.obj.olfMainCategories[0].code !== "Assembly") || (buildingCode === "BCA" && !this.obj.isHighRise)) {
        return "-";
      }

      if (buildingCode === "UBBL" && this.obj.olfMainCategories[0].code === "Assembly") {
        const load = this.totalOccupantLoad;
        if (load > 0 && load < 99) return 1;
        if (load < 599) return 2;
        if (load < 999) return 3;
        if (load >= 999) return 4;
        return 'N/A';
      }

      if (buildingCode === "BCA" && this.obj.isHighRise) {
        return 2;
      }

      var minimumNumberOfExit = this.getMneCountByOcl(this.totalOccupantLoad);
      return minimumNumberOfExit;
    },
    totalExitDoorProvided() {
      const totalExitDoors = this.obj?.doors?.filter((door) => door.isFinalExitDoor === true && door.isAccessible === true);
      const totalExitDoorsProvided = totalExitDoors.length;
      return totalExitDoorsProvided;
      // console.log(totalExitDoorsProvided)
      // const occupantLoadItems = this.computedOccupantLoadItems || [];
      // console.log('occupantLoadItems', occupantLoadItems)
      // if (occupantLoadItems.length > 0) {
      //   return occupantLoadItems.reduce((total, item) => total + (item.totalDoorProvided || 0), 0);
      // } else {
      //   return 0; // Return a default value if the array is empty or undefined
      // }
    },
    buildingCode() {
      if (this.obj.buildingCode == null) return "N/A";
      if (this.obj.buildingCode.name == null) return "N/A";
      return this.obj.buildingCode.name;
    },
    mainClassification() {
      if (this.obj && this.obj.olfMainCategories && this.obj.olfMainCategories.length > 0) {
        return this.obj.olfMainCategories[0].name;
      } else {
        return "N/A";
      }
    },
    dominantClassification() {
      var array = this.findOccurance(
        this.computedOccupantLoadItems,
        "olfMainCategoryName"
      );

      array = array.sort(function (a, b) {
        return b.occurrence - a.occurrence;
      });

      if (array.length == 0) return "";
      return array[0].olfMainCategoryName;

      // var counts = {};
      // your_array.forEach(function (x) {
      //   counts[x] = (counts[x] || 0) + 1;
      // });
    },
    isExitSeperationComplied() {
      if (this.exitSeperationProvided >= this.exitSeperationRequired)
        return "YES";
      else return "NO";

    },

    exitSeperationRequired() {
      try {
        var longestDiagonalDistance = 0;
        for (let i = 0; i < this.computedDiagonalDistanceItems.length; i++) {
          if (
            this.computedDiagonalDistanceItems[i].distance >
            longestDiagonalDistance
          )
            longestDiagonalDistance =
              this.computedDiagonalDistanceItems[i].distance;
        }
        var requiredExitSeperation = business.getRequiredExitSeperation(
          this.obj,
          longestDiagonalDistance
        );
        return requiredExitSeperation;
      } catch (error) {}
      return 0;
    },
    exitSeperationProvided() {
      try {
        var longestExitSeperation = 0;
        for (let i = 0; i < this.computedExitSeperationItems.length; i++) {
          if (
            this.computedExitSeperationItems[i].distance > longestExitSeperation
          )
            longestExitSeperation =
              this.computedExitSeperationItems[i].distance;
        }

        return longestExitSeperation;
      } catch (error) {}
      return 0;
    },
    isStairExitWidthComplied() {
      if (this.totalStairExitWidthProvided >= this.totalStairExitWidthRequired)
        return "YES";
      else return "NO";
    },

    isDoorExitWidthComplied() {
      if (this.totalDoorExitWidthProvided >= this.totalDoorExitWidthRequired)
        return "YES";
      else return "NO";
    },
    isDoorExitWidthCompliedDiscountADB() {
      if (this.totalDoorExitWidthRequired <= this.totalOccupantLoadDiscount)
        return "YES";
      else return "NO";
    },
    totalStairExitWidthRequired() {
      try {
        var stairCalculated = 0;
        const mainCategory = this.obj.olfMainCategories[0].code;

        if (this.obj.buildingCode) {
          if (this.obj.buildingCode.code === "ADB") {
            // Check totalOccupantLoad and set stairCalculated accordingly
  
            if (this.totalOccupantLoad > 0 && this.totalOccupantLoad < 59) {
              stairCalculated = parseFloat(((750 * this.totalOccupantLoad)/ 1000)).toFixed(2);
            }
  
            if (this.totalOccupantLoad > 59 && this.totalOccupantLoad < 109) {
              stairCalculated = parseFloat(((850 * this.totalOccupantLoad)/ 1000)).toFixed(2);
            }
  
            if (this.totalOccupantLoad > 110 && this.totalOccupantLoad < 219) {
              stairCalculated = parseFloat(((1050 * this.totalOccupantLoad)/ 1000)).toFixed(2);
            }
  
            if (this.totalOccupantLoad > 220 && this.totalOccupantLoad < 100000) {
              stairCalculated = parseFloat(((5 * this.totalOccupantLoad)/ 1000)).toFixed(2);
            }
          }
  
          
          if (this.obj.buildingCode.code === "UBBL") {

            if (mainCategory === "Healthcare") {
              stairCalculated = this.calculateEgressCapacityRequiredWidth(this.totalOccupantLoad, 22)
            } else if (mainCategory === "Residential") {
              stairCalculated = this.calculateEgressCapacityRequiredWidth(this.totalOccupantLoad, 45)
            } else if (mainCategory === "Assembly") {
              stairCalculated = this.calculateEgressCapacityRequiredWidth(this.totalOccupantLoad, 75)
            } else { 
              stairCalculated = this.calculateEgressCapacityRequiredWidth(this.totalOccupantLoad, 60)
            }
          }
  
          if (this.obj.buildingCode.code === "NFPA 101") {
            if (mainCategory === "Healthcare" && this.obj.isSprinkleredBuilding === false) {
              stairCalculated = parseFloat((this.totalOccupantLoad * 15) / 1000).toFixed(2)
            }
  
            if (mainCategory === "Healthcare" && this.obj.isSprinkleredBuilding === true) {
              stairCalculated = parseFloat((this.totalOccupantLoad * 7.6) / 1000).toFixed(2)
            }
  
            if (mainCategory === "High Hazard") {
              stairCalculated = parseFloat((this.totalOccupantLoad * 18) / 1000).toFixed(2)
            }
  
            if (mainCategory === "Board And Care") {
              stairCalculated = parseFloat((this.totalOccupantLoad * 10) / 1000).toFixed(2)
            }
  
            stairCalculated = parseFloat((this.totalOccupantLoad * 7.6) / 1000).toFixed(2)
  
          }
  
  
          if (this.obj.buildingCode.code === "IBC") {
  
            if (this.obj.isSprinkleredBuilding === true && mainCategory !== "High Hazard" && mainCategory !== "Factory And Industrial") {
              stairCalculated = parseFloat((this.totalOccupantLoad * 5.1) / 1000).toFixed(2)
            }
  
            stairCalculated = parseFloat((this.totalOccupantLoad * 7.6) / 1000).toFixed(2)
          } 
  
          if (this.obj.buildingCode.code === "BCA") {
            let baseSpace = 1
  
            if (mainCategory === "Healtcare") {
              baseSpace = 1.8
            }
  
            if (this.totalOccupantLoad > 100 && this.totalOccupantLoad < 201) {
              const excessPersons = this.totalOccupantLoad - 100;
              const additionalSpace = Math.ceil(excessPersons / 25) * 0.25;
              const totalSpace = baseSpace + additionalSpace
  
              stairCalculated = totalSpace.toFixed(2)
              
            } else if (this.totalOccupantLoad > 200) {
              baseSpace = 2;
              const excessPersons = this.totalOccupantLoad - 200;
              const additionalSpace = Math.ceil(excessPersons / 75) * 0.5;
              const totalSpace = baseSpace + additionalSpace
  
              stairCalculated = totalSpace.toFixed(2)
            } else { 
              stairCalculated = parseFloat(baseSpace).toFixed(2)
            }
          }
        }

        if (this.paramEgress == null) return stairCalculated;

        var minimumEgressStairWidthInM = this.obj.paramEgress.minimumEgressStairWidthInMM / 1000;
        if (minimumEgressStairWidthInM > stairCalculated){
          stairCalculated = minimumEgressStairWidthInM;
        }
          
        return stairCalculated;

        // if (this.obj && this.obj.paramEgress && Array.isArray(this.obj.paramEgress.paramExitStairWidthItems) && this.obj.paramEgress.paramExitStairWidthItems.length > 0) {
        //   for (let i = 0; i < this.obj.paramEgress.paramExitStairWidthItems.length; i++) {
        //     stairCalculated = parseFloat((this.obj.paramEgress.paramExitStairWidthItems[i].widthPerPersonInMm * this.totalOccupantLoad / 1000).toFixed(2));
        //   }
        // }

        // if (!this.obj || !this.obj.paramEgress) return stairCalculated;

        // var minimumEgressStairWidthInM = this.obj.paramEgress.minimumEgressStairWidthInMM / 1000;
        // if (minimumEgressStairWidthInM > stairCalculated) stairCalculated = minimumEgressStairWidthInM;

        // return stairCalculated;
      } catch (error) {
        console.error(error);
        return "N/A";
      }
    },
    totalStairExitWidthDiscountRequired() {
      var stairCalculated = 0

      if (this.obj.buildingCode.code === "ADB") {

        if (this.totalOccupantLoad >= 60) {
          stairCalculated = parseFloat((750 * this.totalOccupantLoad / 1000).toFixed(2));
        }

        if (this.totalOccupantLoad >= 110) {
          stairCalculated = parseFloat((850 * this.totalOccupantLoad / 1000).toFixed(2));
        }

        if (this.totalOccupantLoad >= 220) {
          stairCalculated = parseFloat((1050 * this.totalOccupantLoad / 1000).toFixed(2));
        }

        if (this.totalOccupantLoad > 220) {
          stairCalculated = parseFloat((5 * this.totalOccupantLoad / 1000).toFixed(2));
        }
      }


      if(this.obj.paramEgress.paramExitStairWidthItems.length < 0) {
        stairCalculated = 0
      } else {
        for (let i = 0; i < this.obj.paramEgress.paramExitStairWidthItems.length; i++) {
          stairCalculated = parseFloat((this.obj.paramEgress.paramExitStairWidthItems[i].widthPerPersonInMm * this.totalOccupantLoad / 1000).toFixed(2));
        }
      }
      // var stairCalculated = parseFloat((this.obj.paramEgress.paramExitStairWidthItems[0].widthPerPersonInMm * this.totalOccupantLoad / 1000).toFixed(2));
      if (this.obj.paramEgress == null) return stairCalculated;
      var minimumEgressStairWidthInM = this.obj.paramEgress.minimumEgressStairWidthInMM / 1000;
      
      if (minimumEgressStairWidthInM > stairCalculated) stairCalculated = minimumEgressStairWidthInM; 

      if(this.obj.buildingCode.code === "IBC") {
        stairCalculated = stairCalculated * 0.5;
      }

      return stairCalculated;
    },
    totalDoorExitWidthRequired() {

      try {
        var doorCalculated = 0

        if (this.obj.buildingCode) {
          if (this.obj.buildingCode.code === "ADB") {
            // Check totalOccupantLoad and set stairCalculated accordingly
            if (this.totalOccupantLoad > 0 && this.totalOccupantLoad < 59) {
              doorCalculated =  parseFloat(((750 * this.totalOccupantLoad)/ 1000)).toFixed(2);
            }
            
            if (this.totalOccupantLoad > 59 && this.totalOccupantLoad < 109) {
              doorCalculated =  parseFloat(((850 * this.totalOccupantLoad)/ 1000)).toFixed(2);
            }
            
            if (this.totalOccupantLoad > 110 && this.totalOccupantLoad < 219) {
              doorCalculated =  parseFloat(((1050 * this.totalOccupantLoad)/ 1000)).toFixed(2);
            }
            
            if (this.totalOccupantLoad > 220 && this.totalOccupantLoad < 100000) {
              doorCalculated =  parseFloat(((5 * this.totalOccupantLoad)/ 1000)).toFixed(2);
            }
          }
  
          if (this.obj.buildingCode.code === "UBBL") {
  
            if (this.obj.olfMainCategories[0].code === "Healthcare") {
              doorCalculated = this.calculateEgressCapacityRequiredWidth(this.totalOccupantLoad, 30)
            } else if (this.obj.olfMainCategories[0].code === "Residential") {
              doorCalculated = this.calculateEgressCapacityRequiredWidth(this.totalOccupantLoad, 100)
            } else if (this.obj.olfMainCategories[0].code === "Assembly") {
              doorCalculated = this.calculateEgressCapacityRequiredWidth(this.totalOccupantLoad, 100)
            } else { 
              doorCalculated = this.calculateEgressCapacityRequiredWidth(this.totalOccupantLoad, 100)
            }
  
          }
  
          if (this.obj.buildingCode.code === "NFPA 101") {
            if (this.obj.olfMainCategories[0].code === "Healthcare" && this.obj.isSprinkleredBuilding === false) {
              doorCalculated =  parseFloat((this.totalOccupantLoad * 13) / 1000).toFixed(2)
            }
  
            if (this.obj.olfMainCategories[0].code === "High Hazard") {
              doorCalculated =  parseFloat((this.totalOccupantLoad * 10) / 1000).toFixed(2)
            }
  
            doorCalculated =  parseFloat((this.totalOccupantLoad * 5) / 1000).toFixed(2)
  
          }
  
          if (this.obj.buildingCode.code === "IBC") {
            if (this.obj.isSprinkleredBuilding === false) {
              doorCalculated =  parseFloat((this.totalOccupantLoad * 5.1) / 1000).toFixed(2)
            }
  
            if (this.obj.isSprinkleredBuilding === true && this.obj.olfMainCategories[0].code !== "High Hazard" && this.obj.olfMainCategories[0].code !== "Factory And Industrial") {
              doorCalculated =  parseFloat((this.totalOccupantLoad * 3.8) / 1000).toFixed(2)
            }
          }
  
  
          if (this.obj.buildingCode.code === "BCA") {
            let baseSpace = 1
  
            if (this.obj.olfMainCategories[0].code === "Healtcare") {
              baseSpace = 1.8
            }
  
            if (this.totalOccupantLoad > 100 && this.totalOccupantLoad < 201) {
              const excessPersons = this.totalOccupantLoad - 100;
              const additionalSpace = Math.ceil(excessPersons / 25) * 0.25;
              const totalSpace = baseSpace + additionalSpace
  
              doorCalculated =  totalSpace.toFixed(2)
              
            } else if (this.totalOccupantLoad > 200) {
              baseSpace = 2;
              const excessPersons = this.totalOccupantLoad - 200;
              const additionalSpace = Math.ceil(excessPersons / 75) * 0.5;
              const totalSpace = baseSpace + additionalSpace
              
              doorCalculated =  totalSpace.toFixed(2)
            } else { 
              doorCalculated =  parseFloat(baseSpace).toFixed(2)
            }
  
          }
        }
        
        // if doorCalculated larger than minimum return doorCalculated
        if (this.obj.paramEgress == null) return doorCalculated;
        var minimumEgressDoorWidthInM = this.obj.paramEgress.minimumEgressDoorWidthInMM / 1000;
        
        if (minimumEgressDoorWidthInM > doorCalculated) {
          doorCalculated = minimumEgressDoorWidthInM;
        }
        
        return doorCalculated;

        // if(this.obj.buildingCode.code !== "ADB"){
        //   var doorCalculated = 0

          // if(this.obj.paramEgress.paramExitDoorWidthItems.length < 0) {
          //   doorCalculated = 0
          // } else {
          //   for (let i = 0; i < this.obj.paramEgress.paramExitDoorWidthItems.length; i++) {
          //     doorCalculated = parseFloat((this.obj.paramEgress.paramExitDoorWidthItems[i].widthPerPersonInMm * this.totalOccupantLoad / 1000).toFixed(2));
          //   }
          // }
          
          // if (this.obj.paramEgress == null) return doorCalculated;
          // var minimumEgressDoorWidthInM = this.obj.paramEgress.minimumEgressDoorWidthInMM / 1000;
          
          // if (minimumEgressDoorWidthInM > doorCalculated) doorCalculated = minimumEgressDoorWidthInM;
          // return doorCalculated;
      } catch (error) {
        console.error(error);
        return "N/A";
      }
    },
    totalDoorExitWidthDiscountRequired() {

      var result = 0

      if (this.obj.buildingCode.code === "ADB") {
          // Check totalOccupantLoad and set stairCalculated accordingly
          var doorWidthProvided = this.totalDoorExitWidthDiscountProvided * 1000

          if (doorWidthProvided <= 750){
            result = "N/A";
          }

          if( doorWidthProvided >= 750 && doorWidthProvided <= 850){
            result = 60;
          }

          if (doorWidthProvided >= 851 && doorWidthProvided <= 1050) {
            result = 110;
          }

          if (doorWidthProvided >= 1050){
            result = doorWidthProvided / 5
          }

          // if (doorWidthProvided >= 1051 && doorWidthProvided <= 5000) {
          //   result = 220;
          // }
          // if (this.totalOccupantLoad > 0 && this.totalOccupantLoad < 59) {
          //   result = parseFloat(((750 * this.totalOccupantLoad)/ 1000)).toFixed(2);
          // }
          
          // if (this.totalOccupantLoad > 59 && this.totalOccupantLoad < 109) {
          //   result = parseFloat(((850 * this.totalOccupantLoad)/ 1000)).toFixed(2);
          // }
          
          // if (this.totalOccupantLoad > 110 && this.totalOccupantLoad < 219) {
          //   result = parseFloat(((1050 * this.totalOccupantLoad)/ 1000)).toFixed(2);
          // }
          
          // if (this.totalOccupantLoad > 220 && this.totalOccupantLoad < 100000) {
          //   result = parseFloat(((5 * this.totalOccupantLoad)/ 1000)).toFixed(2);
          // }
        }

      
        if(this.obj.paramEgress.paramExitDoorWidthItems.length < 0 && this.buildingCode.code !== "ADB") {
          result = 0
        } else {
          for (let i = 0; i < this.obj.paramEgress.paramExitDoorWidthItems.length; i++) {
            result = parseFloat((this.obj.paramEgress.paramExitDoorWidthItems[i].widthPerPersonInMm * this.totalOccupantLoad / 1000).toFixed(2));
          }
        }
      // var doorCalculated = parseFloat((this.obj.paramEgress.paramExitDoorWidthItems[0].widthPerPersonInMm * this.totalOccupantLoad / 1000).toFixed(2));
      if (this.obj.paramEgress == null) return result;
      var minimumEgressDoorWidthInM = this.obj.paramEgress.minimumEgressDoorWidthInMM / 1000;

      if (minimumEgressDoorWidthInM > result) result = minimumEgressDoorWidthInM;
      
      if(this.obj.buildingCode.code === "IBC") {
        result = result * 0.5;
      }

      return result;

    },
    // totalOccupantLoadWD () {
    //   return 'totalOccupantLoadWD';
    // },
    // totalStairExitWidthRequiredWD () {
    //   return 'totalStairExitWidthRequiredWD';
    // },
    // totalStairExitWidthProvidedWD () {
    //   return 'totalStairExitWidthProvidedWD';
    // },
    // isStairExitWidthCompliedWD () {
    //   return 'isStairExitWidthCompliedWD';
    // },
    // totalDoorExitWidthRequiredWD () {
    //   return 'totalDoorExitWidthRequiredWD';
    // },
    // totalDoorExitWidthProvidedWD () {
    //   return 'totalDoorExitWidthProvidedWD';
    // },
    // isDoorExitWidthCompliedWD () {
    //   return 'isDoorExitWidthCompliedWD';
    // },

    exitStairWidthParameter() {
      try {
        var total = this.getParamEgressExitStairWidthPerPersonInMm() / 1000;
        return Math.round(total * 10000) / 10000;
      } catch (error) {
        console.log("exitStairWidthParameter", error);
      }
      return "N/A";
    },

    exitDoorWidthParameter() {
      try {
        var total = this.getParamEgressExitDoorWidthPerPersonInMm() / 1000;

        return Math.round(total * 10000) / 10000;
      } catch (error) {
        console.log("exitStairWidthParameter", error);
      }
      return "N/A";
    },

    exitStairWidthBuildingRule() {
      try {
        return this.obj.paramEgress.buildingCode.name;
      } catch (error) {}
      return "N/A";
    },
    totalOccupantLoad() {
      let total = 0;
      if (this.computedRoomItems === null) return total;
      for (let i = 0; i < this.computedRoomItems.length; i++) {
        try {
          const room = this.computedRoomItems[i];
          if (!Number.isNaN(room.occupantNumber)) {
            if (Number.isInteger(room.occupantNumber)) {
              total += room.occupantNumber;
            }
          }

        } catch (error) {
          // Handle error if needed
        }
      }
      return total;
    },
    totalEffectiveOccupantLoad() {
      let total = 0;
      if (this.computedEffectiveOccupantLoadItems === null) return total;
      for (let i = 0; i < this.computedEffectiveOccupantLoadItems.length; i++) {
        try {
          const room = this.computedEffectiveOccupantLoadItems[i];
          // only room with final exit door, calculate effective occupant load then add to total
          if (room.roomDoors.length > 0) {
            for (const roomDoor of room.roomDoors) {
              const isFinalExitDoor = roomDoor.doors.isFinalExitDoor;
              const isAccessible = roomDoor.doors.isAccessible;
              let effectiveOccupantNumber = isFinalExitDoor && !isAccessible ? this.calcEffectiveOccupantNumber(room) : room.occupantNumber;

              if (!isNaN(effectiveOccupantNumber) && Number.isInteger(effectiveOccupantNumber)) {
                total += effectiveOccupantNumber;
              }
            }
          } else {
            if (!isNaN(room.occupantNumber) && Number.isInteger(room.occupantNumber)) {
              total += room.occupantNumber;
            }
          }
        } catch (error) {
          // Handle error if needed
        }
      }
      return total;
    },
    totalOccupantLoadDiscount() {
      return this.totalOccupantLoad * 0.5;
    },
    totalStairExitWidthProvided() {
      var total = 0;
      if (this.computedExitStairWidthItems == null) return total;

      for (let i = 0; i < this.computedExitStairWidthItems.length; i++) {
        try {
          // if(this.computedExitStairWidthItems[i].distance )
          if (!Number.isNaN(this.computedExitStairWidthItems[i].distance))
            total += this.computedExitStairWidthItems[i].distance;
        } catch (error) {}
      }
      return parseFloat(Math.round(total * 1000) / 1000).toFixed(2);
    },
    totalDoorExitWidthProvided() {
      var total = 0;
      if (this.computedExitDoorWidthItems == null) return total;

      for (let i = 0; i < this.computedExitDoorWidthItems.length; i++) {
        try {
          // if(this.computedExitStairWidthItems[i].distance )
          if (!Number.isNaN(this.computedExitDoorWidthItems[i].distance))
            total += this.computedExitDoorWidthItems[i].distance;
        } catch (error) {}
      }
      return Math.round(total * 1000) / 1000;
    },
    totalDoorExitWidthDiscountProvided() {
      var total = 0;
      var listOfDoorExitWidth = [];
      var longestExitDoorWidth = 0;
      if (this.computedExitDoorWidthItems == null) return total;

      for (let i = 0; i < this.computedExitDoorWidthItems.length; i++) {
        try {
          // if(this.computedExitStairWidthItems[i].distance )
          listOfDoorExitWidth.push(this.computedExitDoorWidthItems[i].distance);
          if (!Number.isNaN(this.computedExitDoorWidthItems[i].distance))
            total += this.computedExitDoorWidthItems[i].distance;
        } catch (error) {}
      }

      longestExitDoorWidth = Math.max(...listOfDoorExitWidth);
      total = total - longestExitDoorWidth;

      if (this.obj.buildingCode.code === "IBC") {
        total = total * 0.5;
      }
      return Math.round(total * 1000) / 1000;
    },
    roomItems() {
      return this.obj.rooms;
    },
    exitStairAreaItems() {
      return this.obj.exitStairAreas;
    },
    exitStairWidthItems() {
      return this.obj.exitStairWidths;
    },
    exitDoorWidthItems() {
      const exitDoor = [];

      if (this.obj && this.obj.doors && Array.isArray(this.obj.doors)) {
        this.obj.doors.forEach((item) => {
          if (item.isFinalExitDoor) {
            exitDoor.push(item);
          }
        });
      }
      return exitDoor;
    },
    exitSeperationItems() {
      return this.obj.exitSeperations;
    },
    travelDistanceItems() {
      return this.obj.travelDistances;
    },
    commonPathItems() {
      return this.obj.commonPaths;
    },
    deadEndItems() {
      return this.obj.deadEnds;
    },
    diagonalDistanceItems() {
      return this.obj.diagonalDistances;
    },
    computedOccupantLoadItems() {
      if (Array.isArray(this.roomItems) && this.roomItems.length > 0) {
        var rooms = this.roomItems.map((item) => {
          return {
            ...item,
            subRoomOccupantNumber: this.getSubRoomsCount(item),
            totalOccupantNumber: this.getTotalOccupantNumber(item),
            olfMainCategoryId: this.getOlfMainCategoryId(item),
            olfMainCategoryName: this.getOlfMainCategoryName(item),
            buildingCodeName: this.getBuildingCodeName(item),
            area: this.getPolygonAreaInMeter(item),
            oclBuildingCodeName: this.getOclBuildingCodeName(item),
            oclValue: this.getOclValue(item),
            occupantNumber: this.calcOccupantNumber(item),
            mneBuildingRuleName: this.getMneBuildingRuleName(item),
            mneCount: this.getTotalMneCount(item),
          };
        });

        if (rooms.length > 0) {
          let sortedRooms = rooms.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
  
          return sortedRooms;
        }
      }
    },
    computedEffectiveOccupantLoadItems() {
      if (Array.isArray(this.roomItems) && this.roomItems.length > 0) {
        var rooms = this.roomItems.map((item) => {
          return {
            ...item,
            subRoomOccupantNumber: this.getSubRoomsCount(item),
            totalOccupantNumber: this.getEffectiveTotalOccupant(item),
            olfMainCategoryId: this.getOlfMainCategoryId(item),
            olfMainCategoryName: this.getOlfMainCategoryName(item),
            buildingCodeName: this.getBuildingCodeName(item),
            area: this.getPolygonAreaInMeter(item),
            oclBuildingCodeName: this.getOclBuildingCodeName(item),
            oclValue: this.getOclValue(item),
            occupantNumber: this.calcOccupantNumber(item),
            mneBuildingRuleName: this.getMneBuildingRuleName(item),
            mneCount: this.getTotalMneCount(item),
          };
        });

        if (rooms.length > 0) {
          let sortedRooms = rooms.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
  
          return sortedRooms;
        }
      }
    },
    computedRoomExitSeperationItems() {
      if (this.computedRoomItems != null)
        var exitSeperations = this.computedRoomItems.map((item) => {
          return {
            ...item,
            diagonalDistance: this.getDiagonalDistance(item),
            exitRequiredExitSeperationDistance: this.getExitSeperationDistanceRequired(item),
            exitSeperationDistanceProvided: this.getExitSeperationDistanceProvided(item),
          };
        });


        // Find the maximum exitSeperationDistanceProvided
        let maxExitSeperationDistance = exitSeperations.reduce((max, current) => {
          return Math.max(max, current.exitSeperationDistanceProvided);
        }, -Infinity);
 
        // Filter the array to include only objects with the highest exitSeperationDistanceProvided
        let result = exitSeperations.filter(item => item.exitSeperationDistanceProvided === maxExitSeperationDistance);
        return result;
    },
    computedDoorSwingItems() {
      if (this.computedRoomItems != null) {
        return this.computedRoomItems.map((item) => {
          const doorSwingDirectionRequired = this.requiredDoorSwingDirection(this.obj, item);
          const isRoomDoorSwingComply = this.isRoomDoorSwingComply(item, doorSwingDirectionRequired);

          // Filter out free doors from roomDoors
          const filteredRoomDoors = item.roomDoors.filter((roomDoor) => {
            const doors = roomDoor.doors;
            return doors.isFinalExitDoor || doors.isRoomDoor || doors.isUnitDoor;
          });

          return {
            ...item,
            roomDoors: filteredRoomDoors,
            totalOccupantNumber: this.getTotalOccupantNumber(item),
            doorSwingDirectionRequired: doorSwingDirectionRequired,
            isRoomDoorSwingComply: isRoomDoorSwingComply,
          };
        });
      } else {
        return []; // Return an empty array if computedRoomItems is null
      }
    },

    computedRoomItems() {
      if (Array.isArray(this.roomItems) && this.roomItems.length > 0) {
        var rooms = this.roomItems.map((item) => {
          return {
            ...item,
            buildingCodeName: this.getBuildingCodeName(item),
            area: this.getPolygonAreaInMeter(item),
            olfMainCategoryName: this.getOlfMainCategoryName(item),
            oclBuildingCodeName: this.getOclBuildingCodeName(item),
            oclValue: this.getOclValue(item),
            occupantNumber: this.calcOccupantNumber(item),
            mneBuildingRuleName: this.getMneBuildingRuleName(item),
            mneCount: this.getTotalMneCount(item),
            totalDoorWidthProvided: this.getTotalDoorWidth(item),
            totalDoorWidthRequired: this.getTotalDoorWidthRequired(item),
            totalDoorSwingIn: this.getTotalDoorSwingIn(item),
            totalDoorSwingOut: this.getTotalDoorSwingOut(item),
            totalDoorSwingBoth: this.getTotalDoorSwingBoth(item),
            subRoomOccupantNumber: this.getSubRoomsCount(item)
          };
        });

        let sortedRooms = rooms.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        return sortedRooms;
      } else {
        return [];
      }
    },

    computedRoomWithDiscountItems() {  
      if (this.roomItems != null)
        var rooms = this.roomItems.map((item) => {
          return {
            ...item,
            buildingCodeName: this.getBuildingCodeName(item),
            area: this.getPolygonAreaInMeter(item),
            olfMainCategoryName: this.getOlfMainCategoryName(item),
            oclBuildingCodeName: this.getOclBuildingCodeName(item),
            oclValue: this.getOclValue(item),
            occupantNumber: this.calcOccupantNumber(item),
            mneBuildingRuleName: this.getMneBuildingRuleName(item),
            mneCount: this.getTotalMneCount(item),
            totalDoorWidthProvided: this.getTotalDoorWidthDiscount(item),
            totalDoorWidthRequired: this.getTotalDoorWidthRequiredDiscount(item),
            totalDoorSwingIn: this.getTotalDoorSwingIn(item),
            totalDoorSwingOut: this.getTotalDoorSwingOut(item),
            totalDoorSwingBoth: this.getTotalDoorSwingBoth(item),
            subRoomOccupantNumber: this.getSubRoomsCount(item)
          };
        });

      let filteredRooms = rooms.filter((item) => {
        var totalDoors = this.getTotalDoorProvidedIncludingSubRooms(item);
        if(totalDoors > 2) {
          return item
        }
      });

      let sortedRooms = filteredRooms.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      return sortedRooms;
    },
    computedExitStairAreaItems() {
      return this.exitStairAreaItems.map((item) => {
        return {
          ...item,
          area: this.getPolygonAreaInMeter(item),
        };
      });
    },
    computedDiagonalDistanceItems() {
      if (this.diagonalDistanceItems != null)
        return this.diagonalDistanceItems.map((item) => {
          return {
            ...item,
            distance: this.getDistanceInMeter(item),
          };
        });
    },
    computedExitDoorWidthItems() {
      if (this.exitDoorWidthItems != null)
        return this.exitDoorWidthItems.map((item) => {
          return {
            ...item,
            distance: this.getDistanceInMeter(item),
          };
        });
    },
    computedExitDoorWidthItemsDiscounting() {
      if (this.exitDoorWidthItems != null) {
        const itemsWithDistance = this.exitDoorWidthItems.map((item) => ({
            name: item.name,
            distance: this.getDistanceInMeter(item),
            item: item,  // Include the original item for reference
          }));
          // Find the item with the maximum distance
          const maxDistanceItem = itemsWithDistance.reduce((maxItem, currentItem) =>
            currentItem.distance > maxItem.distance ? currentItem : maxItem
          );

          // Remove the item with the maximum distance
          const filteredItems = itemsWithDistance.filter((item) => item !== maxDistanceItem);

          return filteredItems;
        };
      },
    computedExitStairWidthWithDiscountItems() {
      if (this.computedExitStairWidthItems) {
        
        var result = ""

        if(this.obj.buildingCode.code === "ADB"){
          const maxDistance = Math.max(...this.computedExitStairWidthItems.map(item => item.distance));
          result = this.computedExitStairWidthItems.filter(item => item.distance === maxDistance);
        }
        
        if (this.obj.buildingCode.code === "UBBL") {
          const minDistance = Math.min(...this.computedExitStairWidthItems.map(item => item.distance));
          result = this.computedExitStairWidthItems.filter(item => item.distance === minDistance);
        }

        return result;
      }
    },
    computedExitStairWidthItems() {
      if (this.exitStairWidthItems != null)
        return this.exitStairWidthItems.map((item) => {
          return {
            ...item,
            distance: this.getDistanceInMeter(item),
          };
        });
    },
    computedExitSeperationItems() {
      if (this.exitSeperationItems != null)
        return this.exitSeperationItems.map((item) => {
          return {
            ...item,
            distance: this.getDistanceInMeter(item),
          };
        });
    },
    computedTravelDistanceItems() {
      const floorPlanSprinkleredCondition = this.obj.isSprinkleredBuilding
      const classificationType = this.obj.classificationType
      const useExisting = this.obj.isExisting
      const buildingCode = this.obj.buildingCode
      return this.travelDistanceItems.map((item) => { 
          return {
            ...item,
            room: item.room?.name ?? 'N/A',
            roomMainCategory: this.getRoomMainCategoryByTravelDistance(item.room),
            distance: this.getDistanceInMeter(item),
            buildingCode: this.obj.buildingCode,
            classificationType: this.obj.classificationType,
            olfMainCategory: this.obj.olfMainCategories,
            requiredDistance: this.getRequiredTravelDistance(item, floorPlanSprinkleredCondition, classificationType, useExisting, buildingCode)
          };
        });
      },
      computedTravelDistanceTitle() {
        switch(this.obj.buildingCode.code) {
          case "ADB":
            return "Travel To More Than One Direction"
          case "UBBL":
            return "Travel Distance"
          case "BCA":
            return "Travel Distance"
          case "NFPA 101":
            return "Travel Distance"
          case "IBC":
            return "Travel Distance"
        }
      },
      computedCommonPathTitle() {
        switch(this.obj.buildingCode.code) {
          case "ADB":
            return "Travel In One Direction"
          case "UBBL":
            return "One Direction Travel"
          case "BCA":
            return "Travel To Point Of Choice"
          case "NFPA 101":
            return "Common Path"
          case "IBC":
            return "Common Path"
        }
      },
    computedCommonPathItems() {
      const floorPlanSprinkleredCondition = this.obj.isSprinkleredBuilding
      const classificationType = this.obj.classificationType
      const useExisting = this.obj.isExisting
      const buildingCode = this.obj.buildingCode
      if (this.commonPathItems) { 
        return this.commonPathItems.map((item) => {
          return {
            ...item,
            room: item.room?.name ?? 'N/A',
            distance: this.getDistanceInMeter(item),
            buildingCode: this.obj.buildingCode,
            classificationType: this.obj.classificationType,
            olfMainCategory: this.obj.olfMainCategories,
            distanceRequired: this.getRequiredCommonPathDistance(item, floorPlanSprinkleredCondition, classificationType, useExisting, buildingCode)
          };
        });
      }
    },
    computedDeadEndItems() {
      const floorPlanSprinkleredCondition = this.obj.isSprinkleredBuilding
      const buildingCode = this.obj.buildingCode
      const useExisting = this.obj.isExisting
      if (this.deadEndItems) { 
        return this.deadEndItems.map((item) => {
          return {
            ...item,
            corridor: item.corridorEnd?.name ?? 'N/A',
            distance: this.getDistanceInMeter(item),
            distanceRequired: this.getRequiredDeadEndDistance(item, floorPlanSprinkleredCondition, useExisting, buildingCode, this.commonPathItems, this.travelDistanceItems)
          };
        });
      }
    },
  },
  methods: {
    getRoomExitSeperation(room) {
      if (room.roomDoors.length <= 1) return null;

      var exitDistanceList = [];

      for (let i = 0; i < room.roomDoors.length; i++) {
        for (let j = 0; j < room.roomDoors.length; j++) {
          if (i == j) continue;

          var isDuplicate = false;
          for (let k = 0; k < exitDistanceList.length; k++) {
            if (
              exitDistanceList[k].code1 == room.roomDoors[i].doors.code &&
              exitDistanceList[k].code2 == room.roomDoors[j].doors.code
            ) {
              isDuplicate = true;
            }
            if (
              exitDistanceList[k].code2 == room.roomDoors[i].doors.code &&
              exitDistanceList[k].code1 == room.roomDoors[j].doors.code
            ) {
              isDuplicate = true;
            }
          }
          if (isDuplicate) continue;
          var center = drawing.getCenterPosition(
            [
              room.roomDoors[i].doors.points[0],
              room.roomDoors[i].doors.points[1],
            ],
            [room.roomDoors[i].doors.points[2], room.roomDoors[i].doors.points[3]]
          );
          var center2 = drawing.getCenterPosition(
            [
              room.roomDoors[j].doors.points[0],
              room.roomDoors[j].doors.points[1],
            ],
            [room.roomDoors[j].doors.points[2], room.roomDoors[j].doors.points[3]]
          );
          exitDistanceList.push({
            code1: room.roomDoors[i].doors.code,
            code2: room.roomDoors[j].doors.code,
            x: center[0],
            y: center[1],
            x2: center2[0],
            y2: center2[1],
          });
        }
      }

      // return exitDistanceList;
      var listOfExits = [];
      for (let k = 0; k < exitDistanceList.length; k++) {
        listOfExits.push({
          points: [
            exitDistanceList[k].x,
            exitDistanceList[k].y,
            exitDistanceList[k].x2,
            exitDistanceList[k].y2,
          ],
        });
      }

      return listOfExits;

      // return exitDistanceList;

      // for (let i = 0; i < this.exitStairDoors.length; i++) {
      //   for (let j = 0; j < this.exitStairDoors.length; j++) {}
      // }
    },

    getExitSeperationDistanceProvided(item) {
      try {
        var roomExitSeperations = this.getRoomExitSeperation(item);

        var longestExitSeperation = 0;
        for (let i = 0; i < roomExitSeperations.length; i++) {
          var distance = this.getDistanceInMeter(roomExitSeperations[i]);

          if (distance > longestExitSeperation)
            longestExitSeperation = distance;
        }

        return longestExitSeperation;
        // var width = 0;
        // for (let i = 0; i < item.roomDoors.length; i++) {
        //   width += this.getDistanceInMeter(item.roomDoors[i].door);
        // }
        // return width;

        // var longestExitSeperation = 0;
        // for (let i = 0; i < this.computedExitSeperationItems.length; i++) {
        //   if (
        //     this.computedExitSeperationItems[i].distance > longestExitSeperation
        //   )
        //     longestExitSeperation =
        //       this.computedExitSeperationItems[i].distance;
        // }

        // return longestExitSeperation;
      } catch (error) {}
      return 0;
    },
    getExitSeperationDistanceRequired(item) {
      if (item.mneCount == 1) return 0;
      if (item.mneCount == "N/A") return 0;

      var diagonalDistance = this.getDiagonalDistance(item);
      var requiredExitSeperation = business.getRequiredExitSeperation(
        this.obj,
        diagonalDistance
      );
      return requiredExitSeperation;
    },
    getDiagonalDistance(item) {
      try {
        var diagonalPoints = drawing.getRoomDiagonalPoints(item);
        var distanceInPixel = drawing.getDistanceInPixel(
          diagonalPoints[0],
          diagonalPoints[1],
          diagonalPoints[2],
          diagonalPoints[3]
        );
        var distainceInMeter = drawing.convertDistancePixelToMeter(
          distanceInPixel,
          this.obj.scale.distanceInMeterForOnePixel
        );
        return parseFloat(distainceInMeter).toFixed(2);
      } catch (error) {}
      return 0;
    },

    getDominantClassificationId() {
      var array = this.findOccurance(
        this.computedOccupantLoadItems,
        "olfMainCategoryId"
      );

      array = array.sort(function (a, b) {
        return b.occurrence - a.occurrence;
      });

      if (array.length == 0) return "";
      return array[0].olfMainCategoryId;
    },

    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    getParamEgressExitStairWidthPerPersonInMm() {
      try {
        if (this.obj.classificationType == "Main") {
          for (
            let i = 0;
            i < this.obj.paramEgress.paramExitStairWidthItems.length;
            i++
          ) {
            if (
              this.obj.paramEgress.paramExitStairWidthItems[i]
                .olfMainCategoryId == this.obj.mainClassificationId
            )
              return this.obj.paramEgress.paramExitStairWidthItems[i]
                .widthPerPersonInMm;
          }
        } else if (this.obj.classificationType == "Dominant") {
          var dominantClassificationId = this.getDominantClassificationId();
          for (
            let i = 0;
            i < this.obj.paramEgress.paramExitStairWidthItems.length;
            i++
          ) {
            if (
              this.obj.paramEgress.paramExitStairWidthItems[i]
                .olfMainCategoryId == dominantClassificationId
            )
              return this.obj.paramEgress.paramExitStairWidthItems[i]
                .widthPerPersonInMm;
          }
        } else if (this.obj.classificationType == "Custom") {
          var dominantClassificationId = this.obj.customClassificationId;
          for (
            let i = 0;
            i < this.obj.paramEgress.paramExitStairWidthItems.length;
            i++
          ) {
            if (
              this.obj.paramEgress.paramExitStairWidthItems[i]
                .olfMainCategoryId == dominantClassificationId
            )
              return this.obj.paramEgress.paramExitStairWidthItems[i]
                .widthPerPersonInMm;
          }
        } else {
          return 0;
        }
      } catch (error) {}
      return 0;
    },
    getParamEgressExitDoorWidthPerPersonInMm() {
      try {
        if (this.obj.classificationType == "Main") {
          for (
            let i = 0;
            i < this.obj.paramEgress.paramExitDoorWidthItems.length;
            i++
          ) {
            if (
              this.obj.paramEgress.paramExitDoorWidthItems[i]
                .olfMainCategoryId == this.obj.mainClassificationId
            )
              return this.obj.paramEgress.paramExitDoorWidthItems[i]
                .widthPerPersonInMm;
          }
        } else if (this.obj.classificationType == "Dominant") {
          var dominantClassificationId = this.getDominantClassificationId();
          for (
            let i = 0;
            i < this.obj.paramEgress.paramExitDoorWidthItems.length;
            i++
          ) {
            if (
              this.obj.paramEgress.paramExitDoorWidthItems[i]
                .olfMainCategoryId == dominantClassificationId
            )
              return this.obj.paramEgress.paramExitDoorWidthItems[i]
                .widthPerPersonInMm;
          }
        } else if (this.obj.classificationType == "Custom") {
          var dominantClassificationId = this.obj.customClassificationId;
          for (
            let i = 0;
            i < this.obj.paramEgress.paramExitDoorWidthItems.length;
            i++
          ) {
            if (
              this.obj.paramEgress.paramExitDoorWidthItems[i]
                .olfMainCategoryId == dominantClassificationId
            )
              return this.obj.paramEgress.paramExitDoorWidthItems[i]
                .widthPerPersonInMm;
          }
        } else {
          return 0;
        }
      } catch (error) {}
      return 0;

    },

    updateClassificationType() {
      var self = this;
      self.obj.customClassificationId = self.customClassificationId;

      this.floorPlanApi
        .update(self.obj)
        .then((response) => {
          self.toast("Error", "Update Success", "success");
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    findOccurance(arr, key) {
      let arr2 = [];
      if (arr == null) return arr2;

      arr.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] == x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["occurrence"]++;
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["occurrence"] = 1;
          arr2.push(a);
        }
      });

      return arr2;
    },
    toggleDetails(item, index) {
      this.$set(item, "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    getTravelDistanceComplianceStatus(item) {
      if(item.requiredDistance == "Not Required") return "YES"
      if(item.distance <= item.requiredDistance) return "YES"
      else return "NO"
    },
    getTravelDistanceComplianceColor(item) {
      if(item.requiredDistance == "Not Required") return "success"
      if(item.distance <= item.requiredDistance) return "success"
      else return "danger"
    },
    getCommonPathDistanceComplianceStatus(item) {
      if(item.distanceRequired == "Not Required") return "YES"
      if(item.distance <= item.distanceRequired) return "YES"
      else return "NO"
    },
    getCommonPathDistanceComplianceColor(item) {
      if(item.distanceRequired == "Not Required") return "success"
      if(item.distance <= item.distanceRequired) return "success"
      else return "danger"
    },
    getDeadEndDistanceComplianceStatus(item) {
      if(item.distanceRequired == "Not Required") return "YES"
      if(item.distance <= item.distanceRequired) return "YES"
      else return "NO"
    },
    getDeadEndDistanceComplianceColor(item){
      if(item.distanceRequired == "Not Required") return "success"
      if(item.distance <= item.distanceRequired) return "success"
      else return "danger"
    },
    getComplianceStatus(item) {
      if (item.occupantNumber === "-") return "-";
      if(item.mneCount === "N/A" || item.mneCount === "-") return "-";
      if (item.totalDoorProvided >= item.mneCount) return "YES";
      else return "NO";
    },
    getComplianceColor(item) {
      if (item.occupantNumber === "-") return "-";
      if(item.mneCount === "N/A" || item.mneCount === "-") return "";
      if (item.totalDoorProvided >= item.mneCount) return "success";
      else return "danger";
    },

    getDoorWidthComplianceStatus(item) {
      if (item.occupantNumber === "-") return "-";
      if (item.totalDoorWidthProvided >= item.totalDoorWidthRequired)
        return "YES";
      else return "NO";
    },
    getDoorWidthComplianceColor(item) {
      if (item.occupantNumber === "-") return "-";
      if (item.totalDoorWidthProvided >= item.totalDoorWidthRequired)
        return "success";
      else return "danger";
    },
    getDoorSwingComplianceStatus(item) {
      if (item.isRoomDoorSwingComply == true) return "YES";
      else return "NO";
    },
    getDoorSwingComplianceColor(item) {
      if (item.isRoomDoorSwingComply == true) return "success";
      else return "danger";
    },
    getRoomExitSeperationComplianceStatus(item) {
      if (item.mneCount === "N/A") return "";
      if (
        item.exitSeperationDistanceProvided >=
        item.exitRequiredExitSeperationDistance
      )
        return "YES";
      else return "NO";
    },
    getRoomExitSeperationComplianceColor(item) {
      if (
        item.exitSeperationDistanceProvided >=
        item.exitRequiredExitSeperationDistance
      )
        return "success";
      else return "danger";
    },
    roomClick(item) {
      this.$emit("roomClick", {
        room: item,
      });
    },
    calcOccupantNumber(item) {
      const area = this.getPolygonAreaInMeter(item);
      const { isGLA, glaValue } = this.obj;
      const { occupantLoadFactor } = item;
      const { olfValue, olfMainCategory } = occupantLoadFactor;

      if (!occupantLoadFactor) {
          return "-";
      }

      if (!olfValue && isGLA && glaValue && olfMainCategory && olfMainCategory.name === 'Mall') {
        const olfGLA = ((0.00007 * glaValue) + 25).toFixed(0);
        return olfGLA;
      } else if (olfValue) {

        const occupantNumber = (area / olfValue).toFixed(1);
        if(occupantNumber % 1 !== 0) {
          let result = Math.floor(occupantNumber) + 1
          return result
        } else {
          return parseInt(occupantNumber)
        }

      } else {
        return "-";
      }
    },
    calcEffectiveOccupantNumber(subRoomItem) {
      let result = this.calcOccupantNumber(subRoomItem);

      if (subRoomItem.effectivePercentage) {
        result *= subRoomItem.effectivePercentage / 100;
        result = Math.ceil(result);
      }

      return parseInt(result);
    },
    getOclValue(item) {
      try {
        if (item.occupantLoadFactor.olfValue) {
          return item.occupantLoadFactor.olfValue;
        } else {
          return "-"
        }
      } catch (error) {
        console.log(error)
      }
    },
    getMneBuildingRuleName(item) {
      try {
        return item.minimumNumberOfExit.buildingRule.name;
      } catch (error) {}
      return "N/A";
    },
    getTotalDoorWidth(item) {
      if (item == null) return 'N/A';
      if (item.roomDoors.length == 0) return 'N/A';
      var width = 0;
      for (let i = 0; i < item.roomDoors.length; i++) {
        width += this.getDistanceInMeter(item.roomDoors[i].doors);
      }
      return parseFloat(width.toFixed(2));
    },
    getTotalDoorWidthDiscount(item) {
      if (item == null) return 'N/A';
      if (item.roomDoors.length == 0) return 'N/A';
      var width = 0;
      for (let i = 0; i < item.roomDoors.length; i++) {
        width += this.getDistanceInMeter(item.roomDoors[i].doors);
      }
      // add up width minus smallest door width
      const minDoorWidth = Math.min(...item.roomDoors.map(item => this.getDistanceInMeter(item.doors)));
      width = parseFloat((width - minDoorWidth).toFixed(2));

      return width;
    },
    getTotalDoorSwingIn(item) {
      if (!item) return 0;
      if (!item.roomDoors) return 0;

      var total = 0;
      for (let i = 0; i < item.roomDoors.length; i++) {
        if (item.roomDoors[i].doors.doorType == "swingIn") total += 1;
      }
      return total;
    },
    getTotalDoorSwingOut(item) {
      if (item == null) return 0;
      if (item.roomDoors == null) return 0;
      var total = 0;
      for (let i = 0; i < item.roomDoors.length; i++) {
        if (item.roomDoors[i].doors.doorType == "swingOut") total += 1;
      }
      return total;
    },
    getTotalDoorSwingBoth(item) {
      if (item == null) return 0;
      if (item.roomDoors == null) return 0;
      var total = 0;
      for (let i = 0; i < item.roomDoors.length; i++) {
        if (item.roomDoors[i].doors.doorType == "swingBoth") total += 1;
      }
      return total;
    },
    requiredDoorSwingDirection(floorPlan, item) {
      const roomArea = item.area;
      const occupantLoad = this.calcOccupantNumber(item);
      if(occupantLoad === "-") return ""
      const mainCategory = floorPlan?.olfMainCategories?.[0]?.code;
      const buildingCode = floorPlan?.buildingCode?.code;
      if(buildingCode == "NFPA 101") {
        if (occupantLoad < 50) {
          return "Any"
        } else if (occupantLoad > 50) {
          return "Swing Out"
        }
      }

      if(buildingCode == "IBC") {
        if(occupantLoad > 50) {
          return "Swing Out"
        } else {
          return "Any"
        }
      }

      if(buildingCode == "UBBL") {
        if(mainCategory === "Assembly"){
          return "Swing Out"
        } else {
          return "Any"
        }
      }

      if(buildingCode == "BCA") {
        if(roomArea >= 200) {
          return "Swing Out"
        } else {
          return "Any"
        }
      }

      if(buildingCode == "ADB") {
        if (occupantLoad >= 60) {
          return "Swing Out"
        } else if (occupantLoad < 60) {
          return "Any"
        } else  {
          return "N/A"
        }
      }

    },
    convertToCamelCase(inputString) {
      if (inputString) {

        const words = inputString.split(' ');
        const camelCaseString = words.map((word, index) => {
          if (index === 0) {
            return word.toLowerCase();
          } else {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
        }).join('');
        
        return camelCaseString;
      } else {
        return ""
      }
      },
    isRoomDoorSwingComply(item, doorSwingDirectionRequired) {
      var isComply = ""
      doorSwingDirectionRequired = this.convertToCamelCase(doorSwingDirectionRequired)
      const roomDoors = item.roomDoors
      roomDoors.forEach((roomDoor) => {
        const doorType = roomDoor.doors.doorType
        if (doorType === doorSwingDirectionRequired) {
          isComply = true
        } else {
          isComply = false
        }
      })

      return isComply
    },
    getTotalDoorWidthRequired(item) {
      var occupantNumber = this.getTotalOccupantNumber(item);
      
      if (item.occupantLoadFactorId === null || item.occupantLoadFactor.olfMainCategoryId === null) {
        return "N/A"
      }

      if (occupantNumber === '-') {
        return '-'
      }
      
      var doorCalculated = 0

      if (this.obj.buildingCode.code === "ADB") {
        // Check totalOccupantLoad and set stairCalculated accordingly
        if (occupantNumber > 0 && occupantNumber < 59) {
          doorCalculated = 750 / 1000;
        }
        if (occupantNumber > 59 && occupantNumber < 109) {
          doorCalculated = 850 / 1000;
        }
        if (occupantNumber > 109 && occupantNumber < 219) {
          doorCalculated = 1050 / 1000;
        }
        if (occupantNumber > 219 && occupantNumber < 100000) {
          doorCalculated =  parseFloat(((5 * occupantNumber)/ 1000)).toFixed(2);
        }
      }

      if (this.obj.buildingCode.code === "UBBL") {

        if (item.occupantLoadFactor.olfMainCategory.code === "Healthcare") {
          doorCalculated =  parseFlot(((occupantNumber / 30 ) * 550) / 1000).toFixed(2)
        }

        if (item.occupantLoadFactor.olfMainCategory.code === "Residential") {
          doorCalculated =  parseFloat(((occupantNumber / 100 ) * 550) / 1000).toFixed(2)
        }

        if (item.occupantLoadFactor.olfMainCategory.code === "Assembly") {
          doorCalculated =  parseFloat(((occupantNumber / 100 ) * 550) / 1000).toFixed(2)
        }

        // All others and Institutional (educational etc)
        doorCalculated =  parseFloat(((occupantNumber / 100 ) * 550) / 1000).toFixed(2)

      }

      if (this.obj.buildingCode.code === "NFPA 101") {
        if (item.occupantLoadFactor.olfMainCategory.code === "Healthcare" && this.obj.isSprinkleredBuilding === false) {
          doorCalculated =  parseFloat((occupantNumber * 13) / 1000).toFixed(2)
        }

        if (item.occupantLoadFactor.olfMainCategory.code === "High Hazard") {
          doorCalculated =  parseFloat((occupantNumber * 10) / 1000).toFixed(2)
        }

        doorCalculated =  parseFloat((occupantNumber * 5) / 1000).toFixed(2)

      }

      if (this.obj.buildingCode.code === "IBC") {
        if (this.obj.isSprinkleredBuilding === false) {
          doorCalculated =  parseFloat((occupantNumber * 5.1) / 1000).toFixed(2)
        }

        if (this.obj.isSprinkleredBuilding === true && item.occupantLoadFactor.olfMainCategory.code !== "High Hazard" && item.occupantLoadFactor.olfMainCategory.code !== "Factory And Industrial") {
          doorCalculated =  parseFloat((occupantNumber * 3.8) / 1000).toFixed(2)
        }
      }


      if (this.obj.buildingCode.code === "BCA") {
        let baseSpace = 1

        if (item.occupantLoadFactor.olfMainCategory.code === "Healtcare") {
          baseSpace = 1.8
        }

      if (occupantNumber > 100 && occupantNumber < 201) {
        const excessPersons = occupantNumber - 100;
        const additionalSpace = Math.ceil(excessPersons / 25) * 0.25;
        const totalSpace = baseSpace + additionalSpace

        doorCalculated =  totalSpace.toFixed(2)
        
      } else if (occupantNumber > 200) {
        baseSpace = 2;
        const excessPersons = occupantNumber - 200;
        const additionalSpace = Math.ceil(excessPersons / 75) * 0.5;
        const totalSpace = baseSpace + additionalSpace
        
        doorCalculated =  totalSpace.toFixed(2)
      } else { 
        doorCalculated =  parseFloat(baseSpace).toFixed(2)
      }

    }

      // var mainClassficationId = item.occupantLoadFactor.olfMainCategoryId;
      // var exitDoorWidthParameter = this.getParamEgressExitDoorWidthPerPersonInMmByClassId(mainClassficationId);
      // var doorCalculated = parseFloat(((occupantNumber * exitDoorWidthParameter) / 1000).toFixed(2));

      if (this.obj.paramEgress != null) {
        var minimumEgressDoorWidthInM = this.obj.paramEgress.minimumEgressDoorWidthInMM / 1000;

        if (minimumEgressDoorWidthInM > doorCalculated)
        doorCalculated = minimumEgressDoorWidthInM;
      }


      return doorCalculated;
    },
    getTotalDoorWidthRequiredDiscount(item) {
      var occupantNumber = this.getTotalOccupantNumber(item);
      var mainClassficationId = item.occupantLoadFactor.olfMainCategoryId;
      var exitDoorWidthParameter = this.getParamEgressExitDoorWidthPerPersonInMmByClassId(mainClassficationId);

        var doorCalculated = parseFloat((((occupantNumber * exitDoorWidthParameter) * 0.5) / 1000).toFixed(2));
      
      if (this.obj.paramEgress != null) {
        var minimumEgressDoorWidthInM =
          this.obj.paramEgress.minimumEgressDoorWidthInMM / 1000;

        if (minimumEgressDoorWidthInM > doorCalculated)
        doorCalculated = minimumEgressDoorWidthInM;
      }

      if (occupantNumber === '-') {
        return '-'
      }

      return doorCalculated;
    },
    getParamEgressExitDoorWidthPerPersonInMmByClassId(classificationId) {
      try {
        if(this.obj.paramEgress.paramExitDoorWidthItems.length < 0) {
          return 0
        } else {
          for ( let i = 0; i < this.obj.paramEgress.paramExitDoorWidthItems.length; i++ ) {
            return this.obj.paramEgress.paramExitDoorWidthItems[i].widthPerPersonInMm;
          }
        }
      } catch (error) {}
      return 0;
    },
    getTotalMneCount(item) {
      var self = this;
      const ocl = this.getTotalOccupantNumber(item);
      var mne = 0;

      if (item.occupantLoadFactorId === null) {
        return "N/A"
      }

      if(this.obj.buildingCode.code === "BCA" && this.obj.isHighRise === true) {
        mne = 2
      }
      
      if(this.obj.buildingCode.code === "BCA"){
        mne = "N/A"
      }

      // This block causes the error
      if (this.obj.buildingCode.code === "UBBL" && (item.occupantLoadFactor && item.occupantLoadFactor.olfMainCategory && item.occupantLoadFactor?.olfMainCategory?.code === "Assembly")) {
        if (ocl > 99 && ocl < 598) {
          mne = 2
        } else if (ocl > 599 && ocl < 998) {
          mne = 3
        } else if (ocl > 999) {
          mne = 4
        }
      }

      // includes subroom
      if (Object.keys(self.obj.minimumNumberOfExit).length !== 0) {
        for ( let i = 0; i < self.obj.minimumNumberOfExit.minimumNumberOfExitItems.length; i++ ) {
          if ( ocl >= self.obj.minimumNumberOfExit.minimumNumberOfExitItems[i].occupantLoadFactorBegin && ocl <= self.obj.minimumNumberOfExit.minimumNumberOfExitItems[i].occupantLoadFactorEnd) {
            mne = self.obj.minimumNumberOfExit.minimumNumberOfExitItems[i].count;
            break;
          }
        }
      }
      return mne;
    },
    getMneCount(item) {
      var self = this;
      var ocl = this.calcOccupantNumber(item);
      var mne = 0;

      if (Object.keys(self.obj.minimumNumberOfExit).length !== 0) {
        for (
          let i = 0;
          i < self.obj.minimumNumberOfExit.minimumNumberOfExitItems.length;
          i++
        ) {
          if (
            ocl >=
              self.obj.minimumNumberOfExit.minimumNumberOfExitItems[i]
                .occupantLoadFactorBegin &&
            ocl <=
              self.obj.minimumNumberOfExit.minimumNumberOfExitItems[i]
                .occupantLoadFactorEnd
          ) {
            mne =
              self.obj.minimumNumberOfExit.minimumNumberOfExitItems[i].count;
            break;
          }
        }
      }
      return mne;

    },
    getSubRoomsCount(item) {
      this.assignOlfToSubRooms(item.subRooms);
      item.subRooms.forEach((el) => {
        const calcSubRoomOccupantNumber = this.calcEffectiveOccupantNumber(el);
        el.subRoomOccupantNumber = calcSubRoomOccupantNumber;
      });
      return item.subRooms;
    },
    getTotalOccupantNumber(item) {
      let totalOccupantNumber = this.calcOccupantNumber(item);
      let subRoomsTotalOccupant = item.subRooms.reduce(
        (total, el) => total + (parseInt(el.subRoomOccupantNumber) || 0),
        0
      );

      totalOccupantNumber = totalOccupantNumber === "-" ? 0 : parseInt(totalOccupantNumber);
      let total = totalOccupantNumber + subRoomsTotalOccupant;
      if (total === 0) {
        return '-';
      } 
      return total;
    },
    getEffectiveTotalOccupant(item){
      let totalOccupantNumber = this.calcEffectiveOccupantNumber(item);
      let subRoomsTotalOccupant = item.subRooms.reduce(
        (total, el) => total + (parseInt(el.subRoomOccupantNumber) || 0),
        0
      );

      totalOccupantNumber = totalOccupantNumber === "-" ? 0 : parseInt(totalOccupantNumber);

      let total = totalOccupantNumber + subRoomsTotalOccupant;
      if (total === 0) {
        return '-';
      } 
      return total;
    },
    getTotalDoorProvidedIncludingSubRooms(item) {
      var total = 0;
      if(item.roomDoors.length > 2){

        for (let i = 0; i < item.roomDoors.length; i++) {
          total += 1;
        }

      }
      return total;
    },
    getRoomMainCategoryByTravelDistance(item) {
      const matchedRoom = this.obj.rooms.find((room) => room.code === item?.code);

      // Use optional chaining to safely access nested properties
      return matchedRoom?.occupantLoadFactor?.olfMainCategory?.name || "N/A";
    },
    assignOlfToSubRooms(subroom) {
      subroom.forEach(subRoom => {
        const matchedRoom = this.obj.rooms.find((room) => room._id === subRoom._id)
        if (matchedRoom) {
          subRoom.occupantLoadFactor = matchedRoom.occupantLoadFactor
        }
      });
      return subroom;
    },
    getMneCountByOcl(ocl) {
      try {
        var self = this;
        if (this.minimumNumberOfExit == null) {
          if (self.obj.buildingCodeId) {
            self.minimumNumberOfExitApi
              .getMinimumNumberOfExitByBuildingCode(self.obj.buildingCodeId)
              .then((response) => {
                self.minimumNumberOfExit = response.minimumNumberOfExit;
                var mneList = self.minimumNumberOfExit[0].minimumNumberOfExitItems;
                for (let i = 0; i < mneList.length; i++) {
                  if (
                    ocl >= mneList[i].occupantLoadFactorBegin &&
                    ocl <= mneList[i].occupantLoadFactorEnd
                  ) {
                    return mneList[i].count;
                  }
                }
              })
              .catch(({ data }) => {
                self.toast("Error", data.message, "danger");
              });
          }
        } else {
          var mneList = this.minimumNumberOfExit[0].minimumNumberOfExitItems;
          for (let i = 0; i < mneList.length; i++) {
            if (
              ocl >= mneList[i].occupantLoadFactorBegin &&
              ocl <= mneList[i].occupantLoadFactorEnd
            ) {
              return mneList[i].count;
            }
          }
        }
        return 0;
      } catch (error) {}
      return 0;
    },

    // getOclBuildingRuleName(item) {
    //   try {
    //     return item.occupantLoadFactor.buildingRule.name;
    //   } catch (error) {}
    //   return "N/A";
    // },
    getOclBuildingCodeName(item) {
      try {
        return item.occupantLoadFactor.buildingCode.name;
      } catch (error) {}
      return "N/A";
    },
    getOlfMainCategoryId(item) {
      try {
        return item.occupantLoadFactor.olfMainCategoryId;
      } catch (error) {}
      return "N/A";
    },
    getOlfMainCategoryName(item) {
      try {
        return item.occupantLoadFactor.olfMainCategory.name;
      } catch (error) {}
      return "N/A";
    },
    getBuildingCodeName(item) {
      try {
        return item.buildingCode.name;
      } catch (error) {
        return "N/A";
      }
    },
    getPolygonAreaInMeter(item) {
      var areaInPixel = this.getPolygonArea(item);

      var areaInMeter =
        areaInPixel *
        this.obj.scale.distanceInMeterForOnePixel *
        this.obj.scale.distanceInMeterForOnePixel;
      areaInMeter = Math.round(areaInMeter * 1000) / 1000;

      return parseFloat(areaInMeter).toFixed(2);
    },
    getPolygonArea(item) {
      var X = [];
      var Y = [];
      for (let i = 0; i < item.points.length; i++) {
        if (i % 2 == 0) X.push(item.points[i]);
        else Y.push(item.points[i]);
      }
      return this.polygonArea(X, Y, X.length);
    },
    //https://www.geeksforgeeks.org/area-of-a-polygon-with-given-n-ordered-vertices/
    polygonArea(X, Y, n) {
      // Initialize area
      let area = 0.0;

      // Calculate value of shoelace formula
      let j = n - 1;
      for (let i = 0; i < n; i++) {
        area += (X[j] + X[i]) * (Y[j] - Y[i]);

        // j is previous vertex to i
        j = i;
      }

      // Return absolute value
      return Math.abs(area / 2.0);
    },
    getDistanceInMeter(item) {
      let distanceInPixel = this.getDistance(item);

      if (item.doorType) {
       distanceInPixel = item.width;
      }
      var distainceInMeter = distanceInPixel * this.obj.scale.distanceInMeterForOnePixel;
      distainceInMeter = Math.round(distainceInMeter * 1000) / 1000;
      return distainceInMeter;
    },
    getDistance(item) {
      try {
          const points = item.points;
          let distance = 0;

          for (let i = 0; i < points.length - 2; i += 2) {
              const xA = points[i];
              const yA = points[i + 1];
              const xB = points[i + 2];
              const yB = points[i + 3];

              const xDiff = xA - xB;
              const yDiff = yA - yB;

              distance += Math.sqrt(xDiff * xDiff + yDiff * yDiff);
          }

          return distance;
      } catch (error) {
          return 0;
      }
    },
    getRequiredTravelDistance(item, floorPlanSprinkleredCondition, classificationType, useExisting, buildingCode) {
      const listOfData = []
      let limitValue = "N/A"

      if (classificationType === "Main" && this.roomData) {
        const getDistance = this.roomData.rooms.find((room) => {
          if(item.roomId == room._id){
            if(room.distance !== null){            
                if(floorPlanSprinkleredCondition) {
                  const sprinkleredCondition = room.distances.find((distance) => distance.sprinkleredCondition === true);
                  if(buildingCode.code == "NFPA 101"){
                    limitValue = useExisting ? sprinkleredCondition?.maximumTravelDistance?.existingMaximumTravelDistance || "Not Required" : sprinkleredCondition?.maximumTravelDistance?.maximumTravelDistance || "Not Required";
                  } else {
                    limitValue = sprinkleredCondition ? sprinkleredCondition.maximumTravelDistance?.maximumTravelDistance || "Not Required" : "Not Required";
                  }
              } else {
                const sprinkleredCondition = room.distances.find((distance) => distance.sprinkleredCondition === false);
                limitValue = sprinkleredCondition ? sprinkleredCondition.maximumTravelDistance?.maximumTravelDistance || "Not Required" : "Not Required";
              }
            }
          }
        })
      }

      if (classificationType === "Dominant" && this.roomData){
        //get all maximum travel distance, get the most used one
        for( let i = 0; i < this.roomData.rooms.length; i++) {
          const room = this.roomData.rooms[i];
          listOfData.push(room.occupantLoadFactorId)
        }

        const countMap = new Map()

        for (const item of listOfData) {
          if (item !== null) {
            if (countMap.has(item)) {
              countMap.set(item, countMap.get(item) + 1);
            } else {
              countMap.set(item, 1);
            }
          }
        }

        let maxCount = 0;
        let mostDuplicateElement;

        countMap.forEach((count, element) => {
          if (count > maxCount) {
            maxCount = count;
            mostDuplicateElement = element;
          }
        });

        const result = this.roomData.rooms.find((room) => room.occupantLoadFactorId === mostDuplicateElement);
        if (result) {
          const sprinkleredCondition = floorPlanSprinkleredCondition ? true : false;
          const distance = result.distances.find((d) => d.sprinkleredCondition === sprinkleredCondition);

          if(buildingCode.code == "NFPA 101"){
            limitValue = useExisting ? distance?.maximumTravelDistance?.existingMaximumTravelDistance || "Not Required" : distance?.maximumTravelDistance?.maximumTravelDistance || "Not Required";
          } else {
            limitValue = distance ? distance.maximumTravelDistance?.maximumTravelDistance || "Not Required"  : "Not Required";
          }
        } else {
          return "Not Required";
        }
      }
      
      if (classificationType === "Stringent" && this.roomData) {
        // get the most less value
        for( let i = 0; i < this.roomData.rooms.length; i++) {
          const sprinkleredCondition = floorPlanSprinkleredCondition ? true : false;
          const distance = this.roomData.rooms[i].distances.find((d) => d.sprinkleredCondition === sprinkleredCondition);

          if(distance){
            if(distance.maximumTravelDistanceId !== null || distance.maximumTravelDistanceId !== undefined){
              if(buildingCode.code == "NFPA 101"){
                listOfData.push(useExisting ? distance?.maximumTravelDistance?.existingMaximumTravelDistance || "Not Required" : distance?.maximumTravelDistance?.maximumTravelDistance || "Not Required")
              } else { 
                listOfData.push(distance.maximumTravelDistance !== null ? distance.maximumTravelDistance.maximumTravelDistance : "Not Required");
              }
            }
          }
        }

        const numericValues = listOfData.filter(value => typeof value === 'number');

        if (numericValues.length > 0) {
          limitValue = Math.min(...numericValues);
        } else {
          limitValue = "Not Required"
        }
      }

      return limitValue

    },
    getRequiredCommonPathDistance(item, floorPlanSprinkleredCondition, classificationType, useExisting, buildingCode) {
      let limitValue = "N/A"
      let occupantLoad = 0
      if (item.roomId !== null) {
        if(!item.room.occupantLoadFactor) {
          return "N/A"
        } else {
          occupantLoad = this.calcOccupantNumber(item.room)
        }
      }

      const listOfData = []

      if (classificationType === "Main") {
        const getDistance = this.roomData.rooms.find((room) => {
          if(item.roomId == room._id){
            if(floorPlanSprinkleredCondition) {
              const sprinkleredCondition = room.distances.find((distance) => distance.sprinkleredCondition === true);

              if ((buildingCode.code === "BCA" || buildingCode.code === "UBBL" || buildingCode.code === "NFPA 101") && item.sou === true) {

                limitValue = sprinkleredCondition ? (sprinkleredCondition.maximumCommonPathDistance?.souDistance || "Not Required") : "Not Required";
              } else if(buildingCode.code === "NFPA 101") {
                limitValue = useExisting ? sprinkleredCondition?.maximumCommonPathDistance?.existingMaximumCommonPathDistance || "Not Required" : sprinkleredCondition?.maximumCommonPathDistance?.existingMaximumCommonPathDistance || "Not Required";
              } else {
                limitValue = sprinkleredCondition ? sprinkleredCondition.maximumCommonPathDistance?.maximumCommonPathDistance || "Not Required" : "Not Required";
              }

            } else {
              const sprinkleredCondition = room.distances.find((distance) => distance.sprinkleredCondition === false);

              if ((buildingCode.code === "BCA" || buildingCode.code === "UBBL" || buildingCode.code === "NFPA 101") && item.sou === true) {

                limitValue = sprinkleredCondition ? (sprinkleredCondition.maximumCommonPathDistance?.souDistance || "Not Required") : "Not Required";
              } else if(buildingCode.code === "NFPA 101") {
                limitValue = useExisting ? sprinkleredCondition?.maximumCommonPathDistance?.existingMaximumCommonPathDistance ?? "Not Required" : sprinkleredCondition?.maximumCommonPathDistance?.maximumCommonPathDistance ?? "Not Required";
              } else if (buildingCode.code === "IBC"){
                if ( sprinkleredCondition.maximumCommonPathDistance?.greaterThanOccupantLoadCondition ){

                  if (occupantLoad > 30) {
                    limitValue = sprinkleredCondition ? sprinkleredCondition.maximumCommonPathDistance?.greaterThanOccupantLoadCondition.thirty || "Not Required" : "Not Required";
                  } else if (occupantLoad <= 30) {
                    // limitValue = sprinkleredCondition ? sprinkleredCondition.maximumCommonPathDistance?.lessThanOccupantLoadCondition.thirtyOne || "Not Required" : "Not Required";
                    limitValue = sprinkleredCondition?.maximumCommonPathDistance?.lessThanOccupantLoadCondition?.thirtyOne || "Not Required";
                  } else {
                    limitValue = "Not Required";
                  }
                } else {
                  limitValue = sprinkleredCondition ? sprinkleredCondition.maximumCommonPathDistance?.maximumCommonPathDistance || "Not Required" : "Not Required";
                }
              } else {
                limitValue = sprinkleredCondition ? sprinkleredCondition.maximumCommonPathDistance?.maximumCommonPathDistance || "Not Required" : "Not Required";;
              }
            }
          }
        })
      }

      if (classificationType === "Dominant"){
        //get all maximum travel distance, get the most used one
        for( let i = 0; i < this.roomData.rooms.length; i++) {
          const room = this.roomData.rooms[i];
          listOfData.push(room.occupantLoadFactorId)
        }

        const countMap = new Map()

        for (const item of listOfData) {
          if (item !== null) {
            if (countMap.has(item)) {
              countMap.set(item, countMap.get(item) + 1);
            } else {
              countMap.set(item, 1);
            }
          }
        }

        let maxCount = 0;
        let mostDuplicateElement;

        countMap.forEach((count, element) => {
          if (count > maxCount) {
            maxCount = count;
            mostDuplicateElement = element;
          }
        });

        const result = this.roomData.rooms.find((room) => room.occupantLoadFactorId === mostDuplicateElement);
        if (result) {
          const sprinkleredCondition = floorPlanSprinkleredCondition ? true : false;
          const distance = result.distances.find((d) => d.sprinkleredCondition === sprinkleredCondition);

          if (buildingCode.code == "NFPA 101") {
            limitValue = useExisting ? distance?.maximumCommonPathDistance?.existingMaximumCommonPathDistance || "Not Required" : distance?.maximumCommonPathDistance?.maximumCommonPathDistance || "Not Required";
          } else if(buildingCode.code == "IBC" && floorPlanSprinkleredCondition == false){
            if(occupantLoad > 30) {
              limitValue = distance ? distance.maximumCommonPathDistance?.greaterThanOccupantLoadCondition.thirty || "Not Required"  : "Not Required";
            }

            if(occupantLoad <= 30) {
              limitValue = distance ? distance.maximumCommonPathDistance?.lessThanOccupantLoadCondition.thirtyOne || "Not Required"  : "Not Required";
            }
          } else {
            requiredDistance = distance ? distance.maximumCommonPathDistance?.maximumCommonPathDistance || "Not Required"  : "Not Required" ;
          }

        } else {
          limitValue = "Not Required";
        }
      }
      
      if (classificationType === "Stringent") {
        // get the most less value
        for( let i = 0; i < this.roomData.rooms.length; i++) {
          const sprinkleredCondition = floorPlanSprinkleredCondition ? true : false;
          const distance = this.roomData.rooms[i].distances.find((d) => d.sprinkleredCondition === sprinkleredCondition);

          if(distance){
            if(buildingCode.code == "IBC") {
              if (distance.maximumCommonPathId !== null){
                if (occupantLoad > 30) {
                  listOfData.push(distance.maximumCommonPathDistance.greaterThanOccupantLoadCondition?.thirty ?? "null");
                }

                if (occupantLoad <= 30) {
                  listOfData.push(distance.maximumCommonPathDistance.lessThanOccupantLoadCondition?.thirtyOne ?? "null");
                }
              }
            } else {
              if(distance.maximumCommonPathId !== null){
                const distanceValue = distance.maximumCommonPathDistance.maximumCommonPathDistance;
                listOfData.push(distanceValue !== null ? distanceValue : "null");
              }
            }
          }
        }

        const numericValues = listOfData.filter(value => typeof value === 'number');

        if (numericValues.length > 0) {
          limitValue = Math.min(...numericValues);
        } else {
          limitValue = "Not Required"
        }
      }
      return limitValue
    },
    getRequiredDeadEndDistance(item, floorPlanSprinkleredCondition, useExisting, buildingCode, commonPathItems, travelDistanceItems) {
      // TODO: if no occupancy assigned, throw error
      const stringentDistanceData = []
      for (let i = 0; i < this.roomData.rooms.length; i++) {
        const sprinkleredCondition = floorPlanSprinkleredCondition ? true : false;
        const distance = this.roomData.rooms[i].distances.find((d) => d.sprinkleredCondition === sprinkleredCondition);

        if (distance) {
          const processDistance = (type, condition) => {
            if (distance[`maximum${type}Id`] !== null) {
              const body = {
                "id": distance._id,
                "value": useExisting ? distance?.[`maximum${type}`]?.[`existingMaximum${type}`] || "Not Required" : distance?.[`maximum${type}`]?.[`maximum${type}`] || "Not Required",
                "distance": distance
              };

              if (condition) {
                if (item.roomId !== null) {
                  let occupantLoad = 0
                  if(!item.occupantLoadFactor) {
                    return "N/A"
                  } else {
                    occupantLoad = this.calcOccupantNumber(item.room)
                  }
                }
                if (occupantLoad > 30) {
                  body.value = distance[`maximum${type}`][`${condition}`]?.thirty ?? "null";
                } else {
                  body.value = distance[`maximum${type}`][`${condition}`]?.thirtyOne ?? "null";
                }
              }

              stringentDistanceData.push(body);
            }
          };

          // travel distance
          if (buildingCode.code === "NFPA 101") {
            processDistance("TravelDistance");
          } else {
            processDistance("TravelDistance");
          }

          // common path
          if (buildingCode.code === "NFPA 101") {
            processDistance("CommonPathDistance");
          } else if (buildingCode.code === "IBC") {
            processDistance("CommonPathDistance", "OccupantLoadCondition");
          } else {
            processDistance("CommonPathDistance");
          }
        }

      }
      if(stringentDistanceData.length === 0) {
        return "Info not available"
      }
        var minData = stringentDistanceData[0].value;
        // Iterate through the array to find the lowest value
        for (let i = 1; i < stringentDistanceData.length; i++) {
          if (stringentDistanceData[i].value < minData) {
            minData = stringentDistanceData[i];
          }
        }

        if (minData === "Not Required" || buildingCode.code === "IBC" || buildingCode.code === "BCA" || buildingCode.code === "ADB") {
          return "Not Required";
        }

        if (typeof minData === "number"){
          return minData;
        } else if (minData.distance.maximumDeadEndDistanceId !== null) {
          return minData.distance.maximumDeadEndDistance.maximumDeadEndDistance;
        }

    },
    refreshCalculation() {
      var self = this;
      for (let i = 0; i < self.roomItems.length; i++) {
        var buildingCodeId = self.roomItems[i].buildingCodeId;
        var buildingRuleId = self.obj.buildingCodeId;

        if (buildingCodeId) {
          self.occupantLoadFactorApi
            .getByBuildingCodeAndBuildingRule(buildingCodeId, buildingRuleId)
            .then((response) => {
              var ocl = response.results;
              if (ocl != null) {
                self.roomItems[i].occupantLoadFactorId = ocl.id;
                self.roomItems[i].occupantLoadFactor = ocl;
              } else {
                self.roomItems[i].occupantLoadFactorId = "";
                self.roomItems[i].occupantLoadFactor = null;
              }
            })
            .catch(({ data }) => {
              // self.toast("Error", helper.getErrorMessage(data), "danger");
            });
        }
      }

      let originalRoomExitSeperationFields = [
        { "key": "name", "label": "Name" },
        { "key": "area", "label": "Area (m2)" },
        { "key": "diagonalDistance", "label": "Diagonal (m)" },
        { "key": "exitRequiredExitSeperationDistance", "label": "Exit Separation Required (m)" },
        { "key": "exitSeperationDistanceProvided", "label": "Exit Separation Provided (m)" },
        { "key": "status", "label": "Compliance", "sorter": false, "filter": false }
      ];

      if (this.obj.buildingCode && this.obj.buildingCode.code === 'ADB') {
        this.roomExitSeperationFields = originalRoomExitSeperationFields.filter(item => item.key !== "diagonalDistance");
      } else {
        this.roomExitSeperationFields = originalRoomExitSeperationFields;
      }

    },
    calculateEgressCapacityRequiredWidth(totalOccupantLoad, divisor) {
      let stairCalculated = parseFloat((totalOccupantLoad / divisor).toFixed(1));
      let decimal = stairCalculated % 1;
      let intNumber = Math.floor(stairCalculated);
      stairCalculated = parseFloat((intNumber * 550)).toFixed(2);

      if (decimal > 0.1 && decimal < 0.5) {
        stairCalculated = (parseFloat(stairCalculated) + 300) / 1000;
      } else {
        stairCalculated = parseFloat(stairCalculated) / 1000;
      }

      return stairCalculated;
    },
    generateFslReport() {
      const occupantLoadRoomsData = this.computedOccupantLoadItems
      ? this.computedOccupantLoadItems.map(item => ({
          name: item.name,
          mainCategory: item.olfMainCategoryName,
          area: item.area,
          occupantDensity: item.oclValue,
          occupantNumber: item.occupantNumber,
          numberOfExitDoorRequired: item.minimumNumberOfExit,
          numberOfExitDoorProvided: item.totalDoorProvided,
          roomCompliance: this.getComplianceStatus(item)
        }))
      : [];

      const egressCapacityStairsData = this.computedExitStairWidthItems
        ? this.computedExitStairWidthItems.map(item => ({
            exitStairName: item.name,
            stairWidth: this.getDistanceInMeter(item),
            distance: item.distance
          }))
        : [];

      const roomEgressCapacity = this.computedRoomItems
        ? this.computedRoomItems.map(item => ({
          room: item.name,
          doorWidthProvided: this.totalDoorWidthRequired ?? 'N/A',
          doorWidthRequired: this.totalDoorWidthProvided ?? 'N/A'
        })) 
        : [];

      const exitDoorsList = this.computedExitDoorWidthItems
      ? this.computedExitDoorWidthItems.map(item => ({
        exitDoor: item.name,
        widthInMeter: this.getDistanceInMeter(item),
      }))
      : [];
      
      this.obj.fslReport = {
        floorPlanId: this.obj._id,
        projectName: this.obj.name,
        description: this.obj.description,
        projectNumber: this.obj.projectNumber,
        date: this.obj.updatedAt,
        revision: this.obj.revision,
        flsDrawingNumber: "1",
          occupantLoad: {
          totalOccupantLoad: this.totalOccupantLoad,
          totalNumberOfExitDoorProvided:this.totalExitDoorProvided,
          totalNumberOfExitDoorRequired:  this.totalExitDoorRequired,
          exitStairQuantityCompliance:  this.isExitDoorQuantityComplied,
          rooms: occupantLoadRoomsData
        },
        egressCapacity:{
          totalOccupantLoad:this.totalOccupantLoad,
          totalExitStairWidthProvided: this.totalStairExitWidthProvided,
          totalExitStairWidthRequired:this.totalStairExitWidthRequired,
          exitStairWidthCompliance: this.isStairExitWidthComplied,
          totalDoorExitWidthRequired: this.totalDoorExitWidthRequired,
          totalDoorExitWidthProvided: this.totalDoorExitWidthProvided,
          isDoorExitWidthComplied: this.isDoorExitWidthComplied,
          stairs: egressCapacityStairsData,
          exitDoorsList: exitDoorsList
        },
        roomEgressCapacity: roomEgressCapacity,
      }
    }
  },
  mounted() {
  }
};
</script>

<style>
h5 {
  font-size: 16px;
  font-weight: 700;
}
.review {
  background-color: #fbfbfb;
}
.review-layout {
  width: 100%;
  max-width: 1048px;
  margin: 0 auto;
}

.print {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: end;
}

.update-btn {
  width: 100%;
  margin-bottom: 1rem;
  height: 35px;
}

.form-block {
  margin-bottom: 32px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.door-swing-table td {
  vertical-align: middle;
  text-align: center;
}

.pt-34 {
  padding-top: 34px !important;
}

.oc-number-td {
  padding: 12px 0;
}

.oc-number-td:nth-child(2) {
  padding-top: 0;
}

.compliance-table td {
  vertical-align: middle;
}

.compliance-table td:nth-child(1),
.compliance-table td:nth-child(3),
.compliance-table td:nth-child(4),
.compliance-table td:nth-child(5),
.compliance-table td:nth-child(6) {
  vertical-align: top;
}

.compliance-table td:nth-child(8),
.compliance-table td:nth-child(9), 
.compliance-table td:nth-child(10) {
  text-align: center;
}

.compliance-assessment.success input {
  color: #2eb85c;
  font-weight: 600;
}
.compliance-assessment.danger input {
  color: #e55353;
  font-weight: 600;
}

</style>
