let apiUrl = process.env.VUE_APP_API_URL;


class DeadEndApi {

    create(data) {
        var url = apiUrl + 'deadEnds';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });
    }

    updateData(id, data) {
        var url = apiUrl + 'deadEnds/';
        return api.call('patch', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    delete(id) {
        var url = apiUrl + 'deadEnds/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

    update(id, data) {
        var url = apiUrl + 'deadEnds/';
        return api.call('patch', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

}

export default DeadEndApi;