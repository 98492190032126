<template>
  <aside class="qla" @keydown="onKeyboardShortcutEvent($event)">
    <!-- <div class="columnnnnnnn">
      <a href="#" v-c-tooltip="'Scale'">
        <span class="material-icons">architecture</span>
      </a>
    </div> -->
    <div class="tools-header">
      <h1 class="tools">Tools</h1>
      <p class="note" v-if="selectedMenu && selectedMenu !== 'cancelClick'">Press E to exit task</p>
    </div>
    <div class="row">
      <div class="menu">
          <CButton class="columnnnnnnn"
            :class="{ 'selected--': selectedMenu === menuItem.name }"
            :disabled="selectedMenu === menuItem.name"
            @click="switchMenu(menuItem.name)"
            v-c-tooltip="{ 
              content: menuItem.tooltip, 
              placement: menuItem.tooltipPlacement 
            }"
            :key="menuItem.name"
            v-for="menuItem in menuItems"
          >
            <img v-if="menuItem.iconSrc" :src="menuItem.iconSrc" alt="" class="column-icon">
            <span v-if="!menuItem.iconSrc" class="material-icons-outlined">{{menuItem.matIcon}}</span>
          </CButton>
      </div>
    </div>
    <div class="is-draggable">
      <CSwitch 
      class="mx-1" 
      color="success" 
      :checked.sync="isDraggable" 
      variant="3d" 
      @update:checked="() => onIsDraggableHandle()" 
      />
      <p>Enable drag</p>
    </div>

    <div class="submenu" v-if="showRoom">
      <CCard class="custom-card">
        <CCardHeader>Room</CCardHeader>
        <CCardBody>
          <CForm sm="12">
            <CRow form class="form-group">
              <CCol>
                <CSelect label="Shape" :options="shapeOptions" :value.sync="drawingMeta.shape" />
              </CCol>
            </CRow>
            <!-- <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
            <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" />
            <CInput type="color" v-model="drawingMeta.fillColor" label="Fill Color" />
            <CInput label="Opacity" v-model="drawingMeta.fillOpacity" /> -->
          </CForm>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showRoomAuto">
      <CCard class="custom-card">
        <CCardHeader>Room Auto</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CRow form class="form-group">
                <CCol>
                  <CSelect label="Shape" :options="autoMethodOptions" v-model="drawingMeta.analysisMethod" />
                </CCol>
              </CRow>
              <CInput type="color" v-model="drawingMeta.analysisColorCode" v-if="drawingMeta.analysisMethod == 'Color'" label="Wall Color" />
              <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
              <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" />
              <CInput type="color" v-model="drawingMeta.fillColor" label="Fill" />
              <CInput label="Opacity" v-model="drawingMeta.fillOpacity" />
            </CForm>
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton @click="$emit('roomAutoClick')" color="primary" class="ml-1 mr-1 float-right">Start</CButton>
        </CCardFooter>
      </CCard>
    </div>

    <div class="submenu" v-if="showRoomDoor">
      <CCard class="custom-card">
        <CCardHeader>Room Door</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CRow form class="form-group">
                <CCol>
                  <CButtonGroup size="sm">
                    <CButton
                      v-for="option in doorOptions"
                      :value.sync="drawingMeta.doorOptions"
                      :key="option.value"
                      :active="buttonStates[option.value]"
                      :color="buttonStates[option.value] ? 'primary' : 'secondary'"
                      @click="toggleButton(option.value)"
                    >
                      {{ option.label }}
                    </CButton>
                  </CButtonGroup>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol>
                  <CSelect label="Sub Door Type"
                  :options="doorTypeOptions"
                  :value.sync="drawingMeta.doorType" 
                  @change="handleDoorTypeChange"
                  />
                </CCol>
              </CRow>
              <CRow 
              form 
              class="form-group"
              v-if="
              !(selectedDoorType === 'sliding' ||
              selectedDoorType === 'folding' ||
              selectedDoorType === 'openPath' ||
              selectedDoorType === 'oneHalfDoor')"
              >
                <CCol>
                  <CSelect label="Direction"
                  :options="doorDirectionOptions"
                  :value.sync="drawingMeta.doorDirection"
                  />
                </CCol>
              </CRow>
              <CRow 
                form 
                class="form-group"
                v-if="!(selectedDoorType === 'openPath')"
              >
                <CCol>
                  <CSelect label="Fire Rating"
                  :options="fireRatingOptions"
                  :value.sync="drawingMeta.fireRating"
                  />
                </CCol>
              </CRow>
              <!-- <CInput label="Rotation" description="In degree" v-model="drawingMeta.doorRotation" type="number" max="360"/> -->
              <CInput ref="rotationInput" label="Rotation" description="In degree" v-model="drawingMeta.doorRotation" type="number" max="360" />

              <CInput 
                label="Door Width (in meters)" 
                v-model="drawingMeta.doorWidthInMeter" 
                type="number" 
                @input="convertMeterToPixel"
                :description="`In px: ${doorWidthInPixel}`"
              />

              <!-- <CRow form class="form-group">
                <CCol tag="label" sm="12" class="col-form-label">Accessible</CCol>
                <CCol sm="12">
                  <CSwitch
                    class="mr-1"
                    color="primary"
                    :checked.sync="drawingMeta.isAccessible"
                    label-on="YES"
                    label-off="NO"
                  />
                </CCol>
              </CRow> -->
              <CRow form class="form-group">
                <CCol tag="label" sm="12" class="col-form-label">Accessible as Room Exit Door</CCol>
                <CCol sm="12">
                  <CSwitch
                    class="mr-1"
                    color="primary"
                    :checked.sync="drawingMeta.isAccessibleAsExitDoor"
                    label-on="YES"
                    label-off="NO"
                  />
                </CCol>
              </CRow>
              <!-- <CInput 
              label="Door Width" 
              ref="doorWidthInput"
              :description="`In pixels ${ doorWidthInMeter } meters`" 
              v-model="drawingMeta.width" type="number"
              @input="getWidthInMeter"
              /> -->
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showStairArea">
      <CCard class="custom-card">
        <CCardHeader>Stair Area</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CRow form class="form-group">
                <CCol>
                  <CSelect label="Shape" :options="shapeOptions" :value.sync="drawingMeta.shape" />
                </CCol>
              </CRow>
              <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
              <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" />
              <CInput type="color" v-model="drawingMeta.fillColor" label="Fill Color" />
              <CInput label="Opacity" v-model="drawingMeta.fillOpacity" />
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showStairWidth">
      <CCard class="custom-card">
        <CCardHeader>Stair Width</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <!-- <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth"/> -->
              <CInput label="Stair Width (in meters)" 
              v-model="drawingMeta.strokeWidthInMeter" 
              @input="onChangeStrokeWidth"
              type="number"
              :description="`In px: ${strokeWidthInPixel}`"
              />
              <CInput type="color" v-model="drawingMeta.strokeColor" label="Stair Color" />
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showStairDoor">
      <CCard class="custom-card">
        <CCardHeader>Stair Door</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <!-- <CRow form class="form-group">
                <CCol>
                  <CSelect label="Shape" :options="shapeDoorOptions" :value.sync="drawingMeta.shape"></CSelect>
                </CCol>
              </CRow> -->
              <CInput ref="rotationInput" label="Rotation" description="In degree" v-model="drawingMeta.doorRotation" type="number" max="360" />
              <CInput label="Door Width" description="In MM" v-model="drawingMeta.width" />
              <!-- <CInput label="Door Depth" description="In MM" v-model="drawingMeta.depth" /> -->
              <!-- <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" /> -->
              <!-- <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" /> -->
              <!-- <CInput type="color" v-model="drawingMeta.fillColor" label="Fill" /> -->
              <!-- <CInput label="Opacity" v-model="drawingMeta.fillOpacity" /> -->
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showEditText">
      <CCard class="custom-card">
        <CCardHeader>Edit Text</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CInput label="Font Size" 
              v-model="drawingMeta.fontSize"
              @change="onFontSizeChange"
              type="number"
               />
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showTravleDistance">
      <CCard class="custom-card">
        <CCardHeader>Travel Distance</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
              <!-- <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" /> -->
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showTravelDistanceAuto">
      <CCard class="custom-card">
        <CCardHeader>Travel Distance / Common Path / Dead End Auto</CCardHeader>
        <CCardBody>
          <div>
            <CButton color="primary" size="sm" block @click="tdAIGuideHandle">How To Use This Feature</CButton>
            <!-- <p class="fst-italic">* Please ensure the wall is included in the drawing.</p> -->
            <CForm sm="12">
              <CInputCheckbox :checked.sync="showGrid" custom label="Show Grid" class="my-2 mt-4" @update:checked="() => showGridChanged()" />
              <CInput
                v-model="drawingMeta.gridSize" 
                label="Grid Size" 
                @input="onChangeGridSize"
                :description="`In Metre: ${gridSizeInMeter}m`"
              >
                <template #append>
                  <CButton color="primary" @click="onGridSizeUpdate()">Submit</CButton>
                </template>
              </CInput>

              <CRow form class="form-group">
                <CCol>
                  <CButtonGroup size="sm">
                    <CButton
                      v-for="option in generatePathOptions"
                      :value.sync="drawingMeta.pathOptions"
                      :key="option.value"
                      :active="pathButtonStates[option.value]"
                      :color="pathButtonStates[option.value] ? 'primary' : 'secondary'"
                      @click="togglePathButton(option.value)"
                      :disabled="option.disabled"
                    >
                      {{ option.label }}
                    </CButton>
                  </CButtonGroup>
                </CCol>
              </CRow>

              <CInput v-model="drawingMeta.strokeWidth" label="Path Thickness">
                <template #append>
                  <CButton color="primary" @click="onStrokeWidthUpdate">Update</CButton>
                </template>
              </CInput>

              <CRow form class="form-group">
                 <CCol>
                   <CSelect label="Travel Distance Looping By" :options="tdLoopByOption" :value.sync="drawingMeta.tdLoopBy" />
                 </CCol>
               </CRow>
              <CRow form class="form-group">
                <CCol>
                  <CSelect label="Travel Distance Point Start Algorithm" :options="travelDistanceAlgorithmOption" :value.sync="drawingMeta.travelDistancePointStartAlgorithm" />
                </CCol>
              </CRow>
              <CRow form class="form-group" v-if="drawingMeta.pathOptions.travelDistance">
                <CCol>
                  <CSelect label="Travel Distance Path Algorithm" 
                  :options="pathFindingOption" 
                  :value.sync="drawingMeta.travelDistanceAlgorithm" 
                  />
                </CCol>
              </CRow>

              <!-- <CRow form class="form-group" v-if="drawingMeta.pathOptions.commonPath">
                <CCol>
                  <CSelect label="Common Path Algorithm" 
                  :options="pathFindingOption" 
                  :value.sync="drawingMeta.commonPathAlgorithm" 
                  />
                </CCol>
              </CRow>

              <CRow form class="form-group" v-if="drawingMeta.pathOptions.deadEnd">
                <CCol>
                  <CSelect label="Dead End Algorithm" 
                  :options="pathFindingOption" 
                  :value.sync="drawingMeta.deadEndAlgorithm" 
                  />
                </CCol>
              </CRow> -->
            </CForm>
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton @click="$emit('travelDistanceClearClick')" color="primary" class="ml-1 mr-1 float-right">Clear</CButton>
          <CButton 
          @click="$emit('travelDistanceAutoClick')" 
          color="primary" 
          class="ml-1 mr-1 float-right"
          :disabled="isButtonDisabled">
            Start
          </CButton>
        </CCardFooter>
      </CCard>
    </div>

    <div class="submenu" v-if="showCommonPath">
      <CCard class="custom-card">
        <CCardHeader>Common Path</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
              <!-- <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" /> -->
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showDeadEnd">
      <CCard class="custom-card">
        <CCardHeader>Dead End</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
              <!-- <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" /> -->
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showObstacle">
      <CCard class="custom-card">
        <CCardHeader>Wall</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CRow form class="form-group">
                <CCol>
                  <CSelect
                  label="Shape"
                  :options="shapeOptions"
                  :value.sync="drawingMeta.shape"
                  ></CSelect>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol>
                  <CSelect
                  label="Fire Rating"
                  :options="fireRatingOptions"
                  :value.sync="drawingMeta.fireRating"
                  @change="handleFireRatingChange"
                  ></CSelect>
                </CCol>
              </CRow>
              <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
              <!-- <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" /> -->
              <!-- <CInput type="color" v-model="drawingMeta.fillColor" label="Fill Color" /> -->
              <!-- <CInput label="Opacity" v-model="drawingMeta.fillOpacity" /> -->
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showObstruction">
      <CCard class="custom-card">
        <CCardHeader>Obstruction</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CRow form class="form-group">
                <CCol>
                  <CSelect
                  label="Shape"
                  :options="shapeOptions"
                  :value.sync="drawingMeta.shape"
                  ></CSelect>
                </CCol>
              </CRow>
              <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>

    <div class="submenu" v-if="showObstacleAuto">
      <CCard class="custom-card">
        <CCardHeader>Wall Auto</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CRow form class="form-group">
                <CCol>
                  <CSelect label="Shape" :options="autoWallMethodOptions" :value.sync="drawingMeta.analysisMethod"></CSelect>
                </CCol>
              </CRow>
              <CInput type="color" v-model="drawingMeta.analysisColorCode" v-if="drawingMeta.analysisMethod == 'Color'" label="Wall Color" />
              <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
              <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" />
              <CInput type="color" v-model="drawingMeta.fillColor" label="Fill Color" />
              <CInput label="Opacity" v-model="drawingMeta.fillOpacity" />
            </CForm>
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton @click="$emit('obstacleClearClick')" color="primary" class="ml-1 mr-1 float-right">Clear</CButton>
          <CButton @click="$emit('obstacleAutoClick')" color="primary" class="ml-1 mr-1 float-right">Start</CButton>
        </CCardFooter>
      </CCard>
    </div>

    <div class="submenu" v-if="showCorridorEnd">
      <CCard class="custom-card">
        <CCardHeader>Corridor End</CCardHeader>
        <CCardBody>
          <div>
            <CForm sm="12">
              <CRow form class="form-group">
                <CCol>
                  <CSelect label="Shape" :options="shapeOptions" :value.sync="drawingMeta.shape"></CSelect>
                </CCol>
              </CRow>
              <CInput label="Stroke Width" v-model="drawingMeta.strokeWidth" />
              <CInput type="color" v-model="drawingMeta.strokeColor" label="Stroke Color" />
              <CInput type="color" v-model="drawingMeta.fillColor" label="Fill Color" />
              <CInput label="Opacity" v-model="drawingMeta.fillOpacity" />
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </div>
    <!-- <div><button @click="dashboard">Dashboard</button></div>
    <div><button>Title</button></div>
    <div><button>Show FLS Drawing</button></div>
    <div><button>Show Review</button></div> -->
  </aside>
</template>
<script>
import "@/index.css";
import vSelect from "vue-select";
import ButtonGroups from "../views/buttons/ButtonGroups.vue";

export default {
  name: "WpMenuLeft",
  components: {
    vSelect,
    ButtonGroups,
  },
  props: {
    drawingMeta: null,
    distanceInMeterForOnePixel: null,
  },
  watch: {
    drawingMeta(newVal, oldVal) {
    },
    drawingShape(newVal, oldVal) {
      if (oldVal != newVal) {
        if (this.drawingMeta.tool == "room") {
          if (newVal == "Polygon") this.$emit("roomClick");
          else if (newVal == "Rectangle") this.$emit("roomRectClick");
        }
        if (this.drawingMeta.tool == "room") {
          if (newVal == "Polygon") this.$emit("roomClick");
          else if (newVal == "Rectangle") this.$emit("roomRectClick");
        }
        if (this.drawingMeta.tool == "obstacle") {
          if (newVal == "Polygon") this.$emit("obstacleClick");
          else if (newVal == "Rectangle") this.$emit("obstacleRectClick");
        }
        if (this.drawingMeta.tool == "obstruction") {
          if (newVal == "Polygon") this.$emit("obstructionClick");
          else if (newVal == "Rectangle") this.$emit("obstructionRectClick");
        }
        if (this.drawingMeta.tool == "corridorEnd") {
          if (newVal == "Polygon") this.$emit("corridorEndClick");
          else if (newVal == "Rectangle") this.$emit("corridorEndRectClick");
        }

        if (this.drawingMeta.tool == "stair") {
          if (newVal == "Polygon") this.$emit("stairClick");
          else if (newVal == "Rectangle") this.$emit("stairRectClick");
        }
        if (this.drawingMeta.tool == "exitDoor") {
          if (newVal == "Vertical") this.$emit("exitDoorVerticalClick");
          else if (newVal == "Horizontal")
            this.$emit("exitDoorHorizontalClick");
          else if (newVal == "Free") this.$emit("exitDoorClick");
        }
        if (this.drawingMeta.tool == "door") {
          if (newVal == "Vertical") this.$emit("doorVerticalClick");
          else if (newVal == "Horizontal") this.$emit("doorHorizontalClick");
          else if (newVal == "Free") this.$emit("doorClick");
        }
      }
    },
  },
  data() {
    return {
      buttonStates: {
        roomDoor: true,
        finalExitDoor: false,
        unitDoor: false,
      },
      pathButtonStates: {
        travelDistance: true,
        commonPath: false,
        deadEnd: false,  
      },
      gridSizeInMeter: 0,
      showGrid: false,
      isDraggable: false,
      showRoom: false,
      showRoomDoor: false,
      showRoomAuto: false,
      showStairArea: false,
      showStairWidth: false,
      showStairDoor: false,
      showEditText: false,
      showTravleDistance: false,
      showTravelDistanceAuto: false,
      showCommonPath: false,
      showDeadEnd: false,
      showObstacle: false,
      showObstruction: false,
      showObstacleAuto: false,
      showCorridorEnd: false,
      doorWidthInMeter: null,
      doorWidthInPixel: null,
      strokeWidthInPixel: null,
      strokeWidthInMeter: null,
      shapeOptionInline: true,
      shapeOptions: ["Rectangle", "Polygon"],
      autoMethodOptions: ["Color", "Thickness", "ML/DL"],
      autoWallMethodOptions: ["Color", "Thickness", "ML/DL", "Room"],
      shapeDoorOptions: ["Vertical", "Horizontal", "Free"],
      doorRotationOption: [0, 90, 180, 270],
      doorRotation: 0,
      generatePathOptions: [
        { value: 'travelDistance', label: 'Travel Distance', disabled: false },
        { value: 'commonPath', label: 'Common Path', disabled: true }, 
        { value: 'deadEnd', label: 'Dead End', disabled: true }
      ],
      doorOptions: [
        { value: "roomDoor", label: "Room Door" },
        { value: "finalExitDoor", label: "Final Exit Door" },
        { value: "unitDoor", label: "Unit Door" },
      ],
      doorTypeOptions: [
        { value: "swingIn", label: "Single Door - Swing In" },
        { value: "swingOut", label: "Single Door - Swing Out" },
        { value: "swingBoth", label: "Single Door - Swing Both" },
        { value: "doubleDoor_swingIn", label: "Double Door - Swing In" },
        { value: "doubleDoor_swingOut", label: "Double Door - Swing Out" },
        { value: "doubleDoor_swingBoth", label: "Double Door - Swing Both" },
        { value: "folding", label: "Folding" },
        { value: "openPath", label: "Open Path" },
        { value: "sliding", label: "Sliding" },
        { value: "oneHalfDoor", label: "One Half Door" },
      ],

      doorDirectionOptions: [
        { value: "swingLeft", label: "Swing Left" },
        { value: "swingRight", label: "Swing Right" },
      ],
      selectedDoorDirection: null,
      fireRatingOptions: [
        "None",
        "30 minutes",
        "60 minutes",
        "90 minutes",
        "120 minutes",
        "180 minutes",
        "240 minutes",
      ],
      selectedFireRating: '',
      selectedDoorType: '',
      selectedMenu: '',
      menuItems: [
        {
          name: 'wallAiClick',
          tooltip: 'Room A.I',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/wall-ai.png')
        },
        {
          name: 'roomRectClick',
          tooltip: 'Room',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/room.svg')
        },
        {
          name: 'doorVerticalClick',
          tooltip: 'Room Door',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/room-door.svg')
        },
        {
          name: 'stairClick',
          tooltip: 'Exit Stair Area',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/exit-stair-area.svg')
        },
        {
          name: 'stairWidthClick',
          tooltip: 'Exit Stair Width',
          tooltipPlacement: 'right',
          iconSrc: require('@/assets/images/icons/exit-stairs-width.svg')
        },
        // {
        //   name: 'exitDoorVerticalClick',
        //   tooltipPlacement: 'right',
        //   tooltip: 'Stair Exit Door / Final Exit Door',
        //   iconSrc: require('@/assets/images/icons/stair-exit-door.svg')
        // },
        {
          name: 'travelDistanceClick',
          tooltip: 'Travel Distance',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/travel-distance.svg')
        },
        {
          name: 'commonPathClick',
          tooltip: 'Common Path',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/common-path.svg')
        },
        {
          name: 'deadEndClick',
          tooltip: 'Dead End',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/dead-end.svg')
        },
        {
          name: 'obstacleRectClick',
          tooltip: 'Wall Fire Rating',
          tooltipPlacement: 'right',
          iconSrc: require('@/assets/images/icons/wall.svg')
        },
        {
          name: 'obstructionRectClick',
          tooltip: 'Obstruction',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/obstruction.svg')
        },
        {
          name: 'corridorEndRectClick',
          tooltip: 'Corridor',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/corridor-end.svg')
        },
        // {
        //   name: 'obstacleAutoClick',
        //   tooltip: 'Wall A.I',
        //   iconSrc: require('@/assets/images/icons/wall-ai.png')
        // },        
        {
          name: 'travelDistanceAutoClick',
          tooltip: 'Travel Distance / Common Path / Dead End A.I.',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/travel-distance-ai.png')
        },
        {
          name: 'scaleFloorPlan',
          tooltip: 'Scale',
          tooltipPlacement: 'top',
          iconSrc: require('@/assets/images/icons/scale.svg')
        },
        {
          name: 'editTextClick',
          tooltip: 'Text',
          tooltipPlacement: 'top',
          matIcon: 'text_fields'
        },
        {
          name: 'cancelClick',
          tooltip: 'End Drawing',
          tooltipPlacement: 'right',
          iconSrc: require('@/assets/images/icons/cancel.png')
        },
      ],
      pathFindingOption: [
        {
          label: 'Start Point - Loop Room Door',
          value: 'aManhattan'
        },
        {
          label: 'Grid By Grid Calculation Loop By Exit Door',
          value: 'aTrace'
        },
        {
          label: 'Method 3',
          value: 'breadthFirstSearch'
        },
      ],
      tdLoopByOption: [
        {
          label: 'Room Door',
          value: 'roomDoor'
        },
        {
          label: 'Exit Door',
          value: 'exitDoor'
        },
      ],
      travelDistanceAlgorithmOption: [
        {
          label: 'Method 1',
          value: 'euclidean'
        },
        {
          label: 'Method 2',
          value: 'aManhattan'
        },
      ]
    };
    
  },
  computed: {
    drawingShape() {
      return this.drawingMeta.shape;
    },
    isButtonDisabled() {
      const { travelDistance, commonPath, deadEnd } = this.pathButtonStates;
      return !travelDistance && !commonPath && !deadEnd;
    },
  },
  methods: {
    showGridChanged() {
      this.drawingMeta.showGrid = this.showGrid;
      this.$emit("showGridUpdateClick");
    },
    onIsDraggableHandle() {
      this.drawingMeta.isDraggable = this.isDraggable;
      this.$emit("onEnableDrag");
    },
    onGridSizeUpdate() {
      this.$emit("gridSizeUpdateClick");
    },
    onStrokeWidthUpdate() {
      this.$emit("strokeWidthUpdateClick");
      // console.log('update', this.drawingMeta.strokeWidth)
    },  
    hideAllMenu() {
      this.showRoom = false;
      this.showRoomDoor = false;
      this.showRoomAuto = false;
      this.showStairArea = false;
      this.showStairWidth = false;
      this.showStairDoor = false;
      this.showTravleDistance = false;
      this.showCommonPath = false;
      this.showDeadEnd = false;
      this.showObstacle = false;
      this.showObstruction = false;
      this.showObstacleAuto = false;
      this.showCorridorEnd = false;
      this.showTravelDistanceAuto = false;
      this.showEditText = false;
    },
    switchMenu(item) {
      this.selectedMenu = item; // Set the selectedMenu to the clicked item
      this.hideAllMenu();
      switch (item) {
        case "roomRectClick":
          this.drawingMeta.tool = "room";
          this.drawingMeta.shape = "Rectangle";
          this.drawingMeta.strokeWidth = 1;
          this.drawingMeta.strokeColor = "#FF4D4F";
          this.drawingMeta.fillColor = "#FF4D4F";

          this.showRoom = true;
          this.$emit(item);
          break;
        case "roomAutoClick":
          this.drawingMeta.analysisCategory = "room";
          this.drawingMeta.tool = "roomAuto";
          this.drawingMeta.analysisMethod = "Color";
          this.showRoomAuto = true;
          // this.$emit(item);
          break;
        case "doorVerticalClick":
          this.drawingMeta.tool = "door";
          this.drawingMeta.doorType = "swingIn";
          
          this.drawingMeta.doorOptions = {
            roomDoor: true,
            finalExitDoor: false,
            unitDoor: false
          };

          this.buttonStates = {
            roomDoor: true,
            finalExitDoor: false,
            unitDoor: false,
          },
          
          this.drawingMeta.fireRating = null;
          this.showRoomDoor = true;
          this.drawingMeta.doorRotation = 0;
          this.drawingMeta.width = 108;
          this.drawingMeta.doorDirection = "swingLeft"
          this.drawingMeta.isAccessible = true;
          this.drawingMeta.isAccessibleAsExitDoor = true;
          this.doorWidthInMeter = drawing.convertDistancePixelToMeter(this.drawingMeta.width, this.distanceInMeterForOnePixel).toFixed(2)
          
          const widthInPixel = drawing.convertDistancePixelToMeter(this.drawingMeta.width, this.distanceInMeterForOnePixel).toFixed(2)
          this.doorWidthInPixel = widthInPixel
          this.drawingMeta.doorWidthInMeter = widthInPixel
          this.$emit(item);
          break;
        case "stairClick":
          this.drawingMeta.tool = "stair";
          this.showStairArea = true;
          this.drawingMeta.shape = "Rectangle";
          this.drawingMeta.strokeWidth = 1;
          this.drawingMeta.strokeColor = "#0000FF";
          this.drawingMeta.fillColor = "#0000FF";
          this.$emit(item);
          break;
        case "stairWidthClick":
          this.drawingMeta.tool = "stairWidth";
          this.showStairWidth = true;

          this.drawingMeta.strokeWidthInMeter = 1;
          this.drawingMeta.strokeColor = "#FFFF00";
          this.drawingMeta.fillColor = "#FFFF00";
          
          const pixelValue = drawing.convertDistanceMeterToPixel(this.drawingMeta.strokeWidthInMeter, this.distanceInMeterForOnePixel).toFixed(2)
          this.strokeWidthInPixel = pixelValue
          this.drawingMeta.strokeWidthInPixel = pixelValue
          this.drawingMeta.strokeWidth = pixelValue;
          this.$emit(item);
          break;
        case "exitDoorVerticalClick":
          this.drawingMeta.tool = "exitDoor";
          this.drawingMeta.shape = "Vertical";
          this.drawingMeta.strokeWidth = 1;
          this.drawingMeta.strokeColor = "#00FFFF";
          this.drawingMeta.fillColor = "#00FFFF";
          this.drawingMeta.width = 100;
          this.drawingMeta.doorRotation = 0;
          console.warn(this.drawingMeta.width)
          this.showStairDoor = true;
          this.$emit(item);
          break;
        case "travelDistanceClick":
          this.drawingMeta.tool = "travelDistance";
          this.drawingMeta.strokeWidth = 2;
          this.drawingMeta.strokeColor = "#289600";
          this.drawingMeta.fillColor = "#289600";

          this.showTravleDistance = true;
          this.$emit(item);
          break;
        case "editTextClick":
          this.drawingMeta.fontSize = 12;
          this.showEditText = true;
          this.$emit(item);
          break;
        case "travelDistanceAutoClick":
          this.drawingMeta.tool = "travelDistanceAuto";
          this.drawingMeta.strokeWidth = 5;
          this.drawingMeta.strokeColor = "#0000FF";
          this.drawingMeta.fillColor = "#0000FF";

          this.drawingMeta.tdLoopBy = 'exitDoor';
          this.drawingMeta.travelDistancePointStartAlgorithm = 'aManhattan'
          this.drawingMeta.travelDistanceAlgorithm = 'aTrace';
          this.drawingMeta.commonPathAlgorithm = 'aTrace';
          this.drawingMeta.deadEndAlgorithm = 'breadthFirstSearch';

          this.drawingMeta.pathOptions = {
            travelDistance: true,
            commonPath: false,
            deadEnd: false,  
          };

          this.pathButtonStates = {
            travelDistance: true,
            commonPath: false,
            deadEnd: false,  
          }

          this.gridSizeInMeter = drawing.convertDistancePixelToMeter(this.drawingMeta.gridSize, this.distanceInMeterForOnePixel).toFixed(2)
          this.showTravelDistanceAuto = true;
          break;

        case "commonPathClick":
          this.drawingMeta.tool = "commonPath";
          this.drawingMeta.strokeWidth = 2;
          this.drawingMeta.strokeColor = "#0ED8D4";
          this.drawingMeta.fillColor = "#0ED8D4";
          this.showCommonPath = true;
          this.$emit(item);
          break;
        case "deadEndClick":
          this.drawingMeta.tool = "deadEnd";
          this.drawingMeta.strokeWidth = 2;
          this.drawingMeta.strokeColor = "#8C1AFF";
          this.drawingMeta.fillColor = "#8C1AFF";
          this.showDeadEnd = true;
          this.$emit(item);
          break;

        case "obstacleRectClick":
          this.drawingMeta.tool = "obstacle";
          this.drawingMeta.shape = "Rectangle";
          this.drawingMeta.strokeWidth = 5;
          this.drawingMeta.fireRating = null;
          this.drawingMeta.strokeColor = "#000000";
          this.drawingMeta.fillColor = "#000000";
          this.showObstacle = true;
          this.$emit(item);
          break;

          case "obstructionRectClick":
          this.drawingMeta.tool = "obstruction";
          this.drawingMeta.shape = "Rectangle";
          this.drawingMeta.strokeWidth = 5;
          this.drawingMeta.strokeColor = null;
          this.drawingMeta.fillColor = "rgba(255, 165, 0, 0.3)";
          this.showObstruction = true;
          this.$emit(item);
          break;
        case "obstacleAutoClick":
          this.drawingMeta.analysisCategory = "wall";
          this.drawingMeta.tool = "obstacleAuto";
          this.drawingMeta.analysisMethod = "Color";
          this.drawingMeta.strokeWidth = 5;
          this.drawingMeta.strokeColor = "#000000";
          this.drawingMeta.fillColor = "#000000";
          this.showObstacleAuto = true;
          // this.$emit(item);
          break;

        case "corridorEndRectClick":
          this.drawingMeta.tool = "corridorEnd";
          this.drawingMeta.shape = "Rectangle";
          this.drawingMeta.strokeWidth = 5;
          this.drawingMeta.strokeColor = "#FFA500";
          this.drawingMeta.fillColor = "#FFA500";
          this.showCorridorEnd = true;
          this.$emit(item);
          break;

        case "wallAiClick":
          this.$emit(item);
          break;

        case "scaleFloorPlan":
          console.log(item)
          this.$emit(item);
          break;

        case "cancelClick":
        this.drawingMeta.tool = "";
        this.drawingMeta.shape = "";
          this.$emit(item);
          break;
        default:
          break;
      }
    },
    dashboard() {
      alert("dashboard");
    },
    onSelect() {
    },
    onClick() {
    },
    toggleDoorDirection() {
      const currentIndex = this.doorDirectionOptions.findIndex(
        (option) => option.value === this.drawingMeta.doorDirection
      );

      const nextIndex = (currentIndex + 1) % this.doorDirectionOptions.length;
      this.drawingMeta.doorDirection = this.doorDirectionOptions[nextIndex].value;
      
    },
    handleFireRatingChange() {
      this.selectedFireRating = this.drawingMeta.fireRating
      console.log('it works!')
      console.log("Selected shape changed:", this.selectedFireRating);
      if (this.selectedFireRating === '30 minutes') {
        this.drawingMeta.strokeColor = "#CBBAED";
        this.drawingMeta.fillColor = "#CBBAED";
      } else if (this.selectedFireRating === '60 minutes') {
        this.drawingMeta.strokeColor = "#2271B3";
        this.drawingMeta.fillColor = "#2271B3";
      } else if (this.selectedFireRating === '90 minutes') {
        this.drawingMeta.strokeColor = "#DEE3C3";
        this.drawingMeta.fillColor = "#DEE3C3";
      } else if (this.selectedFireRating === '120 minutes') {
        this.drawingMeta.strokeColor = "#FE0100";
        this.drawingMeta.fillColor = "#FE0100";
      } else if (this.selectedFireRating === '180 minutes') {
        this.drawingMeta.strokeColor = "#4ECDC4";
        this.drawingMeta.fillColor = "#4ECDC4";
      } else if (this.selectedFireRating === '240 minutes') {
        this.drawingMeta.strokeColor = "#89FC00";
        this.drawingMeta.fillColor = "#89FC00";
      }
    },
    handleDoorTypeChange() {
      this.selectedDoorType = this.drawingMeta.doorType;
    },
    getWidthInMeter() {
      const meterValue = drawing.convertDistancePixelToMeter(this.drawingMeta.width, this.distanceInMeterForOnePixel);
      this.doorWidthInMeter = meterValue.toFixed(2);
    },
    convertMeterToPixel() {
      const pixelValue = drawing.convertDistanceMeterToPixel(this.drawingMeta.doorWidthInMeter, this.distanceInMeterForOnePixel);
      this.drawingMeta.width = pixelValue;
      this.doorWidthInPixel = pixelValue.toFixed(2);
    },
    onChangeStrokeWidth() {
      const pixelValue = drawing.convertDistanceMeterToPixel(this.drawingMeta.strokeWidthInMeter, this.distanceInMeterForOnePixel);
      this.drawingMeta.strokeWidth = pixelValue;
      this.strokeWidthInPixel = pixelValue.toFixed(2);
    },
    onKeyboardShortcutEvent(e) {
      if (e.keyCode === 69) {
        this.switchMenu('cancelClick')
      }
      if (e.keyCode === 189) {
        this.toggleDoorDirection();
      }
      if (e.keyCode === 187) {
        this.toggleDoorDirection();
      }
      if (e.keyCode === 75) {
        this.drawingMeta.width = this.drawingMeta.width + 0.1;
        this.$nextTick(() => {
          this.$refs.doorWidthInput.$el.querySelector('input').value = this.drawingMeta.width;
        });
      }
      if (e.keyCode === 76) {
        this.drawingMeta.width = this.drawingMeta.width - 0.1;
        this.$nextTick(() => {
          this.$refs.doorWidthInput.$el.querySelector('input').value = this.drawingMeta.width;
        });
      }

      if (e.keyCode === 221) {
        this.drawingMeta.doorRotation = this.drawingMeta.doorRotation + 0.1;
        this.$nextTick(() => {
          this.$refs.rotationInput.$el.querySelector('input').value = this.drawingMeta.doorRotation;
        });
      }
      if (e.keyCode === 220) {
        this.drawingMeta.doorRotation = this.drawingMeta.doorRotation - 0.1;
        this.$nextTick(() => {
          this.$refs.rotationInput.$el.querySelector('input').value = this.drawingMeta.doorRotation;
        });
      }
    },
    toggleButton(button) {
      this.buttonStates[button] = !this.buttonStates[button];
      this.buttonStates = { ...this.buttonStates };
      this.drawingMeta.doorOptions = this.buttonStates;
    },   
    togglePathButton(button) {
      this.pathButtonStates[button] = !this.pathButtonStates[button];
      this.pathButtonStates = { ...this.pathButtonStates };
      this.drawingMeta.pathOptions = this.pathButtonStates;
    },   
    tdAIGuideHandle() {
      this.$emit("tdAIGuideHandle")
    },
    onFontSizeChange() {
      this.$parent.vTextSize = this.drawingMeta.fontSize;
    },
    onChangeGridSize() {
      console.log('hey',this.drawingMeta.gridSize)
      this.gridSizeInMeter = drawing.convertDistancePixelToMeter(this.drawingMeta.gridSize, this.distanceInMeterForOnePixel).toFixed(2)
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyboardShortcutEvent);
  }
};
</script>


<style scoped>
.menu {
  flex-grow: 1;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.columnnnnnnn {
  border: 0;
  background: #fafafa;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease;
}

.columnnnnnnn.selected--,
.columnnnnnnn:hover {
  background: #0A1439;
}

.columnnnnnnn.selected-- a {
  color: #fff;
}

.columnnnnnnn.selected-- .column-icon,
.columnnnnnnn:hover .column-icon {
  filter: invert(100%);
}

.column-icon {
  width: 20px;
  height: 20px;
}

.columnnnnnnn a {
  display: block;
  margin: auto;
  color: #fff;
  color: #0A1439;
}

.columnnnnnnn[disabled] {
  cursor: not-allowed;
}
.submenu {
  /* background-color: white; */
  /* border-color: black; */
  /* border-style: solid; */
  border-radius: 10px;
  /* box-shadow: 3px 3px #8888886b; */
  /* padding: 10px 10px; */
  margin-left: 2px;
}

.custom-card {
  background: transparent;
  border: 0;
}

.custom-card .card-header {
  background: transparent;
  border: 0;
  padding-inline: 0;

  color:#434343;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.custom-card .card-body {
  padding: 0 !important;
}

.tools-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tools {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.note {
  color:#8C8C8C;
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 8px;
}

.is-draggable {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px -5px;
}

.is-draggable p {
  margin: 0;
}
</style>
