let apiUrl = process.env.VUE_APP_API_URL;


class CommonPathApi {

    getListByFloorPlanId(id){
        var url = apiUrl + `commonPaths?floorPlanId=${id}&limit=100&page=1`;
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });

    }

    create(data) {
        var url = apiUrl + 'commonPaths';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });
    }

    update(id, data) {
        var url = apiUrl + 'commonPaths/';
        return api.call('patch', url + id, data)
            .then(({ data }) => {
                return data
            });
    }

    updateData(id, data) {
        var url = apiUrl + 'commonPaths/';
        return api.call('patch', url + id, data)
        .then(({ data }) => {
                return data
            });
    }

    delete(id) {
        var url = apiUrl + 'commonPaths/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

}

export default CommonPathApi;