<template>
    <div v-if="isVisible" class="drawing-tool-details">
      <CForm class="drawing-form">
        <h2>{{ title }}</h2>
        <CInput 
          label="Name" 
          v-model="dataObj.name" 
          :readonly="!isEditable"
          v-if="dataObj"
        />
        <CInput
          label="Code"
          v-if="dataObj"
          v-model="dataObj.code"
          readonly
        />
        <slot name="formContent"></slot>
      </CForm>
      <div class="drawing-tool-details-footer">
        <CButton
          v-if="onCancel"
          class="ml-1 mr-1 float-right"
          color="secondary"
          @click="onCancel"
        >
          Cancel
        </CButton>
        <CButton
          v-if="onDelete"
          class="ml-1 mr-1 float-right"
          color="danger"
          @click="onDelete"
        >
          Delete
        </CButton>
        <slot name="buttons"></slot>
      </div>
    </div>
</template>
  
<script>
  export default {
    props: {
      isVisible: Boolean, // Add a prop to control visibility
      isEditable: false,
      title: String,
      dataObj: Object,
      onDelete: Function,
      onCancel: Function,
    },
  };
</script>

<style>

.drawing-tool-details {
  position: fixed;
  left: 0px;
  top: 86px;
  background: rgb(255, 255, 255);
  z-index: 101;
  max-width: 264px;
  width: 100%;
  height: calc(100% - 86px);
  padding: 20px;
}

.drawing-tool-details-footer {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 1rem;

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  background: #fff;
  padding: 20px;
  width: 100%;
  box-shadow: 0px 25px 20px -20px rgba(0,0,0,0.45),
  0px -14px 25px -14px rgba(0,0,0,0.45);
}

.drawing-tool-details h2 {
  font-size: 18px;
}

.drawing-tool-details label,  
.drawing-tool-details input,
.drawing-tool-details select,
.drawing-tool-details table,
.drawing-tool-details-footer button,
.table-headaer {
  font-size: 12px;
}

.drawing-tool-details table td {
  white-space: nowrap;
}

.drawing-form {
  overflow: auto;
  height: 100%;
  padding-bottom: 72px;
}
</style>
  
