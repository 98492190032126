<template>
    <div form class="form-group">
      <div :style="{ marginBottom: '0.5rem' }" class="table-header">
        {{ title }}
      </div>
      <div>
        <CDataTable :items="items" :fields="fields" striped caption="Striped Table" :loading="loading">
          <template #show_remove="{ item }">
            <td class="py-2">
              <CButton color="primary" variant="outline" square size="sm" @click="onRemove(item)">
                Remove
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CButton size="sm" color="primary" class="d-block ml-auto" @click="onAdd()">
          {{ addLabel }}
        </CButton>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      items: Array,
      fields: Array,
      loading: Boolean,
      addLabel: {
        type: String,
        default: "Add",
      },
    },
    methods: {
      onRemove(item) {
        this.$emit("show_remove", item);
      },
      onAdd() {
        this.$emit("add");
      },
    },
  };
  </script>
  