let apiUrl = process.env.VUE_APP_API_URL;

class KreoAiApi {
  autoMeasure(id, kreoScale) {
    const url = `${apiUrl}floorplans/${id}/kreo/ai`;
    return api.call("post", url, kreoScale).then(({ data }) => {
      console.log(data)
      return data;
    });
  }
}

export default KreoAiApi;
