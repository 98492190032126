<template>
  <aside class="top-left-sider">
    <button class="download-btn" @click="exportPdf">
      <img :src="require('@/assets/images/icon-download.svg')" alt="" class="download-icon">
      Download
    </button>
    <div class="resize-input">
      <CInput
        label="Resize Notation Box:"
        min="0"
        v-model.number="data.resizeValue"
        placeholder="Enter resize value. Default value is 100%"
      >
      </CInput>
    </div>
    <CToaster :autohide="3000" class="toaster-legend">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
    
    <div class="legend-notation">
      <table border="1" class="legend-table">
        <thead>
          <tr>
            <th colspan="2">FIRE AND LIFE SAFETY LEGEND</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(legend, index) in legends" :key="index">
            <td><img 
              :src="legend.imageSrc" 
              :alt="legend.legend" 
              class="ln-line"
              :class="legend.cssClass"
              ></td>
            <td class="ln-type">{{legend.legendType}}</td>
          </tr>
        </tbody>
      </table>

      <div class="ln-note">
        <h1 class="ln-heading">Description</h1>
        <ul style="display: flex; flex-direction: column;">
          <CForm style="flex: 1;">
            <CTextarea
              rows="5"
              text="Must be 8-20 words long."
              v-model="editedDescription"
            ></CTextarea>
          </CForm>
          <CButton
            style="align-items: end;"
            class=""
            color="primary"
            @click="onSubmit"
          >
            Save Changes
          </CButton>
        </ul>
      </div>

      <div class="ln-details-ul">
        <ul class="ln-details-ls">
          <li><b>Project Name:</b> {{ this.data.name }}</li>
          <li><b>Project Number:</b> {{ this.data.projectNumber ? this.data.projectNumber : 'N/A' }}</li>
          <li><b>Date:</b> {{ formatUpdatedAt(this.data.createdAt) }}</li>
          <li><b>Revision:</b> {{ this.data.revision ? this.data.revision : 'N/A' }}</li>
        </ul>
        <strong>FLS Drawing Number: 1</strong>
      </div>

    </div>
  </aside>
</template>

<script>
import moment from 'moment';
import FloorPlanApi from "@/lib/floorPlanApi";

export default {
  name: "WpLegends",
  props: {
    data: null
  },
  data() {
    return {
      infoList: [],
      editedDescription: '',
      api: new FloorPlanApi(),
      legends: [
        {
          imageSrc: require('@/assets/images/legends/fr-30.png'),
          legendType: "30 Minutes Fire Rated Wall"
        },
        {
          imageSrc: require('@/assets/images/legends/fr-60.png'),
          legendType: "60 Minutes Fire Rated Wall"
        },
        {
          imageSrc: require('@/assets/images/legends/fr-90.png'),
          legendType: "90 Minutes Fire Rated Wall"
        },
        {
          imageSrc: require('@/assets/images/legends/fr-120.png'),
          legendType: "120 Minutes Fire Rated Wall"
        },
        {
          imageSrc: require('@/assets/images/legends/fr-180.png'),
          legendType: "180 Minutes Fire Rated Wall"
        },
        {
          imageSrc: require('@/assets/images/legends/fr-240.png'),
          legendType: "240 Minutes Fire Rated Wall"
        },
        {
          imageSrc: require('@/assets/images/legends/diagonal_distance_icon.png'),
          legendType: "Diagonal Distance"
        },
        {
          imageSrc: require('@/assets/images/legends/exit_seperation.png'),
          legendType: "Exit Separation"
        },
        {
          imageSrc: require('@/assets/images/legends/travel_distance_icon.png'),
          legendType: "Travel Distance"
        },
        {
          imageSrc: require('@/assets/images/legends/common_path_icon.png'),
          legendType: "Common Path Distance"
        },
        {
          imageSrc: require('@/assets/images/legends/dead_end_icon.png'),
          legendType: "Dead End Distance"
        },
        {
          imageSrc: require('@/assets/images/fire-rated-door/fd_30.png'),
          legendType: "30 Minutes Fire Rated Door",
          cssClass: "mw-30"
        },
        {
          imageSrc: require('@/assets/images/fire-rated-door/fd_60.png'),
          legendType: "60 Minutes Fire Rated Door",
          cssClass: "mw-30"
        },
        {
          imageSrc: require('@/assets/images/fire-rated-door/fd_90.png'),
          legendType: "90 Minutes Fire Rated Door",
          cssClass: "mw-30"
        },
        {
          imageSrc: require('@/assets/images/fire-rated-door/fd_120.png'),
          legendType: "120 Minutes Fire Rated Door",
          cssClass: "mw-30"
        },
        {
          imageSrc: require('@/assets/images/fire-rated-door/fd_180.png'),
          legendType: "180 Minutes Fire Rated Door",
          cssClass: "mw-30"
        },
        {
          imageSrc: require('@/assets/images/fire-rated-door/fd_240.png'),
          legendType: "240 Minutes Fire Rated Door",
          cssClass: "mw-30"
        },
        // Add more legend objects as needed
      ]
    }
  },
  watch: {
    data: {
      handler(newData) {
        this.editedDescription = newData.description
      }
    },
    immediate: true,
  },
  methods: {
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    resizeValue() {
      this.$emit('on-resize')
    },
    exportPdf() {
      this.$emit('export-pdf');
    },
    formatUpdatedAt(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    onSubmit() {
      if (this.editedDescription.length < 1) {
        this.toast("Error", "Description Cannot Be Empty!", "danger")
        return
      }
      this.data.description = this.editedDescription;
      this.api
        .update(this.data, this.data.fslReport.floorPlanId)
        .then((response) => {
          console.log(response)
          this.toast("Success", "Update Success", "success");
        })
        .catch(({ data }) => {
          this.toast("Error", data.message, "danger")
        })
    },
  },
}
</script>
<style>
  .top-left-sider {
    position: fixed;
    width: 100%;
    max-width: 400px;
    height: 100%;
    right: 0;
    z-index: 2;
    /* overflow: auto; */
    background-color: #fff;
  }
  .toaster-legend {
    position: absolute;
    top: 80px;
    z-index: 0
  }
  .legend-notation {
    height: 80%;
    padding: 24px;
    overflow: auto;
  }

  .download-btn {
    border-radius: 40px;
    background: #0A1439;
    color: white;
    width: 100%;
    max-width: 215px;
    padding: 12px 0;
    margin: 16px;
    cursor: pointer;
    transition: 0.4s ease
  }

  .download-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }

  .download-icon {
    margin-right: 8px;
  }

  .ln-heading {
    color: #434343;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
  }

  .ln-legend {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    gap: 20px;
  }

  .ln-legend-type,
  .ln-notation-type {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .ln-legend-type {
    color: #434343;
    margin: 0;
  }

  .ln-notation-type {
    color: #8C8C8C;
  }

  .ln-note ul {
    list-style: upper-alpha;
    text-transform: uppercase;
    padding: 0 24px;
    font-weight: 600;
    font-size: 12px;
  }

  .ln-line {
    width: 100%;
    max-width: 87px;
  }

  .ln-line.mw-30 {
    max-width: 30px;
  }

  .legend-table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 20px;
  }
  .legend-table th,
  .legend-table td {
      border: 1px solid black;
      padding: 8px;
      text-align: center;
  }
  .legend-table td:first-child {
      max-width: 100px; /* Adjust this value as needed */
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }

  .legend-table .ln-type {
    text-align: left;
  }

  .ln-details-ls {
    padding: 0 !important;
  }
  .ln-details-ls li {
    list-style: none;
    font-size: 12px;
    text-transform: capitalize;
  }

  .ln-note strong {
    font-size: 20px;
  }
  .resize-input {
    padding: 0 20px;
  }
</style>
