<template>
  <div class="menu-header">
    <aside class="d-flex">
      <div class="d-flex border-none main">
      <CRow>
        <CCol>
          <div>
            <!-- <a @click="goBack" class="back-link"> leave this first to refer client later --> 
            <a @click="onLeavePage" class="back-link">
              <span class="material-icons">folder</span>
            </a>
          </div>
        </CCol>
        <CCol sm="auto">
          <div>
            <a class="drawing-title" @click="settingClick">
              {{ title }}
            </a>
          </div>
        </CCol>
      </CRow>
    </div>
    </aside>
    <aside class="d-flex">
      <div class="d-flex border-none main">
        <CButton
          class="header-page"
          @click="switchPage('switchPageDrawing')"
          color="link"
          :class="{ 'selected': selectedPage === 'switchPageDrawing' }"
        >
        <div v-if="isLoading" class="overlay">
          <div class="spinner">
            <CSpinner />
          </div>
        </div>
        <div>
          <img :src="require('@/assets/images/icons/drawing-board.svg')" alt="" class="header-page-icon">
          Drawing board
        </div>
        </CButton>
        <CButton
          :disabled="!topBtnIsEnabled"
          class="header-page"
          @click="switchPage('switchPageReview')"
          color="link"
          :class="{ 'selected': selectedPage === 'switchPageReview' }"
        >
        <div>
          <img :src="require('@/assets/images/icons/review.svg')" alt="" class="header-page-icon">
          Review
        </div>
        </CButton>
        <CButton
          :disabled="!topBtnIsEnabled"
          class="header-page"
          @click="switchPage('switchPagePrint')"
          color="link"
          :class="{ 'selected': selectedPage === 'switchPagePrint' }"
        >
        <div>
          <img :src="require('@/assets/images/icons/fire-safety-life.svg')" alt="" class="header-page-icon">
          FLS Drawing
        </div>
        </CButton>
      </div>
      <CModal title="Confirm Navigation" :show.sync="confirmNavigationPopup" :closeOnBackdrop="false"
        :show-close="false" class="scale-modal">
        
        Are you sure you want to leave this page?
    
        <div slot="footer" class="w-100">
          <CButton class="ml-1 mr-1 float-right" color="primary" @click="onLeavePage">
            OK
          </CButton>
          <CButton class="ml-1 mr-1 float-right" color="secondary" @click="() => { confirmNavigationPopup = false }">
            Cancel
          </CButton>
        </div>
      </CModal>
    </aside>
  </div>
</template>
<script>
import "@/index.css";
export default {
  name: "WpMenuMain",
  props: { obj: null },
  data() {
    return {
      isLoading: false,
      topBtnIsEnabled: false,
      selectedPage: 'switchPageDrawing', // Set the initial selected page, e.g., 'drawing'
      confirmNavigationPopup: false,
    };
  },
  methods: {
    settingClick() {
      this.$emit("settingClick");
    },
    switchPage(page) {
      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
        this.selectedPage = page;
        if (page === 'switchPageReview' | page === 'switchPagePrint') {
          document.body.classList.remove('overflow-hidden');
        } else {
          document.body.classList.add('overflow-hidden');
        }
        this.$emit(page);
      }, 200);
    },
    goBack() {
      this.confirmNavigationPopup = true;
    },
    onLeavePage() {
      this.$router.push({
        path: `/dashboard/${this.obj.folderId}`,
      });
    }
  },
  watch: {
    obj(newVal, oldVal) {
    },
  },
  computed: {
    // workspaceUrl() {
    //   return `Workspace/${this.obj.id}`;
    //   // self.$router.push({
    //   //   path: `/Workspace/${item.id}`,
    //   // });
    // },
    title() {

      // if(this.obj.name)
      // return "(no title)";
      return this.obj.name;

      // return "No title";
    },
  },
};
</script>

<style scoped>
.main {
  /* width: auto; */
  /* background-color: white; */
  /* border-color: black; */
  /* border-style: solid; */
  /* border-radius: 10px; */
  /* box-shadow: 3px 3px #8888886b; */
  /* padding: 10px 10px; */
  gap: 32px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.drawing-title {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.1s ease;
}

.drawing-title:hover {
  font-weight: bold;
}

.menu-header {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 101;
}

.header-page {
  cursor: pointer;
  color: #0A1439;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.selected {
  color: #0A1439;
  font-weight: 700;
}

.header-page-icon {
  width: 16px;
  margin-right: 8px;
}

.back-link {
  cursor: pointer;
}

.header-page[disabled] {
  cursor: not-allowed;
  color: #8C8C8C;
  opacity: 0.5;
}

</style>
