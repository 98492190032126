let apiUrl = process.env.VUE_APP_API_URL;


class CorridorEndApi {

    getListByFloorPlanId(id){
        var url = apiUrl + `corridorEnds?floorPlanId=${id}`;
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });

    }

    create(data) {
        var url = apiUrl + 'corridorEnds';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });

    }

    delete(id) {
        var url = apiUrl + 'corridorEnds/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

}

export default CorridorEndApi;