let apiUrl = process.env.VUE_APP_API_URL;


class FireRatingApi {


    getList() {
        var url = apiUrl + 'fireRatings?limit=10&page=1';
        return api.call('get', url)
          .then(({ data }) => {
            return data;
        });
    }
}

export default FireRatingApi;