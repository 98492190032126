let apiUrl = process.env.VUE_APP_API_URL;


class ExitStairAreaApi {

    create(data) {
        var url = apiUrl + 'exitStairAreas';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            })
    }

    delete(id) {
        var url = apiUrl + 'exitStairAreas/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

}

export default ExitStairAreaApi;